<template>
  <router-link to="/inicio"
    ><img
      class="img-fluid for-light"
      :src="logo"
      alt="logo"
  /></router-link>
</template>
<script>
import { database} from "@/firebase/config";
import { ref, onValue} from "firebase/database";

export default {
  data() {
    return {
      logo: ""
    };
  },
   methods: {
    loadData() {
      const companyRef = ref(database, 'company');

      // Escuchar cambios en los datos del residente
      onValue(companyRef, (snapshot) => {
        const companyData = snapshot.val();
        if (companyData) {
          this.logo = companyData.urlImage;

        } else {
          console.log("No se encontraron datos de la compañia.");
        }
      });
    },
  },
  mounted(){
    this.loadData()
  },
}
</script>
<style scoped>
img {
  background-color: rgba(255, 255, 255, 0.381);
  border-radius: 5px;
}
</style>