<template>
     <Breadcrumbs main="Usuarios" title="Administrar Usuarios"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <adminUsers/>
              </div>
            </div>
     </div>
</template>
<script>
import adminUsers from "@/components/users/adminUsers.vue"
export default {
    components:{
        adminUsers
    }
}
</script>