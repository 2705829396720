<template>
     <Breadcrumbs main="Residentes" title="Documentos"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <DocumentsResident/>
              </div>
            </div>
     </div>
</template>
<script>
import DocumentsResident from "@/components/resident/documentsResident.vue"
export default {
    components:{
        DocumentsResident
    }
}
</script>