<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Información de los residentes</h4>
      </div>
      <div class="card-header">
        <div class="mb-3 row justify-content-start">
          <h6 for="search-type" class="col-xs-3 col-sm-auto col-form-label">
            Tipo de Búsqueda:
          </h6>
          <div class="col-xs-4 col-sm-2">
            <select v-model="searchType" id="search-type" class="form-select">
              <option value="name">Nombre</option>
              <option value="nameSchool">Escuela</option>
              <option value="degree">Licenciatura</option>
              <option value="enrollment">Matrícula</option>
            </select>
          </div>
        </div>
        <div class="mb-3 row justify-content-start">
          <h6
            for="table-complete-search"
            class="col-xs-3 col-sm-auto col-form-label"
          >
            Buscar:
          </h6>
          <div class="col-xs-3 col-sm-3">
            <input
              id="table-complete-search"
              type="text"
              class="form-control"
              placeholder="Escribe para buscar..."
              v-model="filterQuery"
            />
          </div>
        </div>
        <div class="table-responsive theme-scrollbar data-table">
          <form>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col" sortable="name">Nombre</th>
                  <th scope="col" sortable="nameSchool">Escuela</th>
                  <th scope="col" sortable="degree">Licenciatura</th>
                  <th scope="col" sortable="enrollment">Matricula</th>
                  <th scope="col" sortable="startDate">Fecha de Inicio</th>
                  <th scope="col" sortable="endDate">Fecha de término</th>
                  <th scope="col" sortable="emailCorporate">
                    Correo Corporativo
                  </th>
                  <th scope="col" sortable="emailPersonal">Correo Personal</th>
                  <th scope="col" sortable="notes">Anotaciones</th>
                  <th scope="col">Accciones</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="row in get_rows" :key="row.idResident">
                  <td>{{ row.name }}</td>
                  <td>{{ row.nameSchool }}</td>
                  <td>{{ row.degree }}</td>
                  <td>{{ row.enrollment }}</td>
                  <td>{{ row.timetable.startDate }}</td>
                  <td>{{ row.timetable.endDate }}</td>
                  <td>{{ row.email }}</td>
                  <td>{{ row.emailPersonal }}</td>
                  <td>{{ row.notes }}</td>
                  <td>
                    <span>
                      <span @click="redirectUpdate(row)">
                        <i
                          class="icofont icofont-edit icofont-2x font-warning ps-2"
                        ></i>
                      </span>
                      <span @click="redirectTimetable(row)">
                        <i
                          class="icofont icofont-ui-calendar icofont-2x font-tasks ps-2"
                        ></i>
                      </span>
                      <span @click="redirectDocuments(row)">
                        <i
                          class="icofont icofont-file-pdf icofont-2x font-documents ps-2"
                        ></i>
                      </span>
                      <!-- <span @click="deleteUserConfirmation(row)">
                        <i
                          class="icofont icofont-ui-delete icofont-2x font-danger ps-2"
                        ></i>
                      </span> -->
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="pagination m-3 justify-content-end pagination-primary">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous"
                  ><span aria-hidden="true">&laquo;</span></a
                >
              </li>
              <li
                class="page-item"
                v-for="i in num_pages()"
                :key="i"
                v-bind:class="[i == currentPage ? 'active' : '']"
                v-on:click="change_page(i)"
              >
                <a class="page-link">{{ i }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next"
                  ><span aria-hidden="true">&raquo;</span></a
                >
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Swal from "sweetalert2";
import { database } from "@/firebase/config";
import { ref, onValue } from "firebase/database";

export default {
  data() {
    return {
      residents: [],
      dataResidents: [],
      users: [],
      selectedResident: null,
      searchType: "name",
      elementsPerPage: 10,
      currentPage: 1,
      ascending: false,
      sortColumn: "",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterQuery: "",
    };
  },
  watch: {
    searchType(newSearchType) {
      console.log("Nuevo tipo de búsqueda:", newSearchType); // Agregamos un mensaje de registro
      // Al cambiar el tipo de búsqueda, restablecer filterQuery a ''
      this.filterQuery = "";
    },
    filterQuery(search) {
      var tableItems = [...this.residents]; // Usar la lista original para filtrar
      var filteredData = tableItems.filter((row) => {
        const field = row[this.searchType].toLowerCase(); // Convertir a minúsculas
        const query = search.toLowerCase(); // Convertir a minúsculas
        return field.includes(query);
      });
      this.dataResidents = search === "" ? [...this.residents] : filteredData;
    },
  },
  computed: {
    columns() {
      if (this.dataResidents.length === 0) {
        return [];
      }
      return Object.keys(this.dataResidents[0]);
    },
    get_rows() {
      const start = (this.currentPage - 1) * this.elementsPerPage;
      const end = start + this.elementsPerPage;
      return this.dataResidents.slice(start, end);
    },
  },
  methods: {
    redirectAdd() {
      this.$router.push("/escuelas/convenios-agregar");
    },
    redirectUpdate(resident){
      this.$store.dispatch('updateSelectedResident', resident);
      this.$router.push(`/residentes/actualizar`);
    },
    redirectTimetable(resident) {
      this.$store.dispatch('updateSelectedResident', resident);
      this.$router.push("/residentes/horario");
    },
    redirectDocuments(resident) {
      this.$store.dispatch('updateSelectedResident', resident);
      this.$router.push("/residentes/documentos");
    },
    closeFloatingForm() {
      this.showUpdate = false;
      this.showTimetable = false;
      this.showDocuments = false;
    },
    async loadData() {
      try {
        // Suscribirse a cambios en users
        const usersRef = ref(database, "users");
        onValue(usersRef, (snapshot) => {
          this.users = []; // Limpiar la lista de usuarios
          snapshot.forEach((userSnapshot) => {
            const user = userSnapshot.val();
            user.id = userSnapshot.key; // Agregar la clave (ID) del usuario
            this.users.push(user);
          });
        });

        // Suscribirse a cambios en residents
        const residentsRef = ref(database, "residents");
        onValue(residentsRef, (snapshot) => {
          this.dataResidents = []; // Limpiar el arreglo dataResidents
          this.residents = []; // Limpiar el arreglo residents
          snapshot.forEach((residentSnapshot) => {
            const resident = residentSnapshot.val();
            const user = this.users.find((u) => u.id === resident.idResident);
            if (user) {
              const combinedData = {
                name: user.name,
                email: user.email, // Agregar solo el nombre del usuario
                department: user.department,
                ...resident, // Agregar todos los datos del residente
              };
              // Agregar el nuevo objeto a los arreglos dataResidents y residents
              this.dataResidents.push(combinedData);
              this.residents.push(combinedData);
            }
          });
        });
      } catch (error) {
        console.error("Error al cargar datos:", error.message);
      }
    },
    num_pages() {
      return Math.ceil(this.dataResidents.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.font-tasks {
  color: #676766;
  cursor: pointer;
}
.font-documents {
  color: #630404;
  cursor: pointer;
}
.font-warning {
  cursor: pointer;
}
</style>