<template>
     <Breadcrumbs main="Usuarios" title="Agregar Usuario"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <createUser/>
              </div>
            </div>
     </div>
</template>
<script>
import createUser from "@/components/users/createUser.vue"
export default {
    components:{
        createUser
    }
}
</script>