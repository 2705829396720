const paisesAmericas = [
    "Antigua y Barbuda",
    "Argentina",
    "Bahamas",
    "Barbados",
    "Belice",
    "Bolivia",
    "Brasil",
    "Canadá",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Dominica",
    "República Dominicana",
    "Ecuador",
    "El Salvador",
    "Granada",
    "Guatemala",
    "Guyana",
    "Haití",
    "Honduras",
    "Jamaica",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "San Cristóbal y Nieves",
    "Santa Lucía",
    "San Vicente y las Granadinas",
    "Surinam",
    "Trinidad y Tobago",
    "Estados Unidos",
    "Uruguay",
    "Venezuela"
  ];

  export default paisesAmericas;
