<template>
  <div class="col-xl-4 col-md-6 box-col-33">
    <div class="card bg-primary card-h">
      <div class="card-body">
        <div class="d-flex faq-widgets">
          <div class="flex-grow-1">
            <h4></h4>
            <h5 class="mb-0">
              En el siguiente apartado, tendrás acceso a una presentación que te
              brindará una visión más clara de quiénes somos y qué hacemos
              dentro de la empresa.
            </h5>
          </div>
          <vue-feather type="file-text"></vue-feather>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-6 box-col-33">
    <div class="card bg-primary card-h">
      <div class="card-body">
        <div class="d-flex faq-widgets">
          <div class="flex-grow-1">
            <h4></h4>
            <h5 class="mb-0">
              Esta presentación está diseñada para ayudarte a conocernos mejor,
              entender nuestra cultura empresarial y familiarizarte con nuestros
              valores y objetivos.
            </h5>
          </div>
          <vue-feather type="book-open"></vue-feather>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-lg-12 box-col-33">
    <div class="card bg-primary">
      <div class="card-body">
        <div class="d-flex faq-widgets">
          <div class="flex-grow-1">
            <h4></h4>
            <h5 class="mb-0">
              Estamos comprometidos a proporcionarte toda la información que
              necesitas para sentirte cómodo(a) y bienvenido(a).
              Esperamos que esta presentación te sea útil y que te
              ayude a integrarte rápidamente en nuestro equipo.
            </h5>
          </div>
          <vue-feather type="help-circle"></vue-feather>
        </div>
      </div>
    </div>
  </div>
  <div class="card-imagen">
    <div class="icon-overlay">
        <a :href="urlImage" target="_blank"> <i class="fa fa-file-pdf-o text-danger"></i></a>
    </div>
    <img src="@/assets/images/training/presentation.png" alt="hand">
  </div>
</template>
<script>
import { database} from "@/firebase/config";
import { ref, onValue} from "firebase/database";

export default {
  data() {
    return {
      urlImage: '',
    };
  },
  methods: {
    loadData() {
      const companyRef = ref(database, 'company');

      // Escuchar cambios en los datos del residente
      onValue(companyRef, (snapshot) => {
        const companyData = snapshot.val();
        if (companyData) {
          this.urlImage = companyData.urlPresentation;
        } else {
          console.log("No se encontraron datos de la compañia.");
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
}
</script>

<style scoped>
.card-h {
  height: 87%;
  padding-top: 22px;
}

.card-imagen {
  text-align: center;
}

img {
  width: 25%;
  margin-top: 0px;
}

i{
    font-size: 100px;
}

.icon-overlay {
  position: absolute; /* Establecer posicionamiento absoluto */
  top: 35%; /* Alinear al centro vertical */
  left: 46%; /* Alinear al centro horizontal */
  transform: translate(-45%, -45%); /* Centrar el icono */
}
</style>