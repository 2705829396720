<template>
     <Breadcrumbs main="Usuarios" title="Actualizar Usuario"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <UpdateUser/>
              </div>
            </div>
     </div>
</template>
<script>
import UpdateUser from "@/components/users/updateUser.vue"
export default {
    components:{
        UpdateUser
    }
}
</script>