<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Información de las pruebas</h4>
      </div>
      <div class="card-header">
                    <div class="mb-3 row justify-content-start">
              <h6 for="search-type" class="col-xs-3 col-sm-auto col-form-label">Tipo de Búsqueda:</h6>
              <div class="col-xs-4 col-sm-2">
                <select v-model="searchType" id="search-type" class="form-select">
                  <option value="nameTest">Nombre</option>
                  <option value="method">Medio</option>
                </select>
              </div>
              <div class="col-auto ms-auto">
                <button class="btn btn-success mb-2" @click="redirectAdd">
                  <i class="fa fa-plus"></i> Agregar
                </button>
              </div>
            </div>
            <div class="mb-3 row justify-content-start">
              <h6
                for="table-complete-search"
                class="col-xs-3 col-sm-auto col-form-label"
                >Buscar:</h6
              >
              <div class="col-xs-3 col-sm-3">
                <input
                  id="table-complete-search"
                  type="text"
                  class="form-control"
                  placeholder="Escribe para buscar..."
                  v-model="filterQuery"
                />
              </div>
            </div>
        <div class="table-responsive theme-scrollbar data-table">
          <form>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col" sortable="nameTest">Nombre de la Prueba</th>
                  <th scope="col" sortable="time">Tiempo</th>
                  <th scope="col" sortable="method">Medio de aplicación</th>
                  <th scope="col" sortable="league">Liga</th>
                  <th scope="col" sortable="Documents">Documentos</th>
                  <th scope="col">Accciones</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="row in get_rows" :key="row.id">
                  <td>{{ row.nameTest }}</td>
                  <td>{{ row.time }}</td>
                  <td>{{ row.method }}</td>
                  <td>{{ row.league }}</td>
                  <td>
                    <span @click="redirectDocument(row)">
                      <i class="icofont icofont-file-pdf icofont-2x font-documents ps-2"></i>
                    </span>
                  </td>
                  <td>
                    <span>
                      <span @click="redirectUpdate(row)">
                        <i
                          class="icofont icofont-edit icofont-2x font-warning ps-2"
                        ></i>
                      </span>
                      <!-- <span @click="deleteUserConfirmation(row)">
                        <i
                          class="icofont icofont-ui-delete icofont-2x font-danger ps-2"
                        ></i>
                      </span> -->
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="pagination m-3 justify-content-end pagination-primary">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous"
                  ><span aria-hidden="true">&laquo;</span></a
                >
              </li>
              <li
                class="page-item"
                v-for="i in num_pages()"
                :key="i"
                v-bind:class="[i == currentPage ? 'active' : '']"
                v-on:click="change_page(i)"
              >
                <a class="page-link">{{ i }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next"
                  ><span aria-hidden="true">&raquo;</span></a
                >
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { database} from "@/firebase/config";
import { ref, onValue} from "firebase/database";
import estados from "@/data/estados.js";

export default {
  data() {
    return {
      tests: [],
      originalTests: [], 
      showForm: false,
      selectedTest: null,
      searchType: "nameTest",
      elementsPerPage: 10,
      currentPage: 1,
      ascending: false,
      sortColumn: "",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterQuery: "",
      states: estados,
    };
  },
  watch: {
    searchType(newSearchType) {
      console.log("Nuevo tipo de búsqueda:", newSearchType); // Agregamos un mensaje de registro
      // Al cambiar el tipo de búsqueda, restablecer filterQuery a ''
      this.filterQuery = '';
    },
    filterQuery(search) {
        var tableItems = [...this.originalTests]; // Usar la lista original para filtrar
        var filteredData = tableItems.filter((row) => {
            const field = row[this.searchType].toLowerCase(); // Convertir a minúsculas
            const query = search.toLowerCase(); // Convertir a minúsculas

            // Verificar si el campo es 'state' y si la consulta coincide exactamente con el estado
            const result = this.searchType === 'state' ? field === query : field.includes(query);

            return result;
        });
        this.tests = search === "" ? [...this.originalTests] : filteredData;
    },
  },
  computed: {
    columns() {
      if (this.tests.length === 0) {
        return [];
      }
      return Object.keys(this.tests[0]);
    },
    get_rows() {
      const start = (this.currentPage - 1) * this.elementsPerPage;
      const end = start + this.elementsPerPage;
      return this.tests.slice(start, end);
    },
  },
  methods: {
    redirectAdd() {
      this.$router.push("/bateria-pruebas/agregar");
    },
    redirectUpdate(test) {
      this.$store.dispatch('updateSelectedTest', test);
      this.$router.push(`/bateria-pruebas/actualizar`);
    },
    redirectDocument(test) {
      this.$store.dispatch('updateSelectedTest', test);
      this.$router.push("/bateria-pruebas/documentos");
    },
    loadData() {
      const testRef = ref(database, "testBattery");
        onValue(testRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            this.originalTests = Object.keys(data)
              .filter(idTest => idTest !== 'reportTests') // Excluir 'reportTests'
              .map((idTest) => ({
                id: idTest,
                ...data[idTest],
              }));
            this.tests = [...this.originalTests]; 
          } else {
            this.tests = [];
            this.originalTests = [];
          }
        });
    },
    num_pages() {
      return Math.ceil(this.tests.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
<style scoped>
.font-documents {
  color: #630404;
  cursor: pointer;
}
</style>