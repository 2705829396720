<template>
     <Breadcrumbs main="Batería de Pruebas" title="Pruebas Aplicadas"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <TestAppliedUpdatePage/>
              </div>
            </div>
     </div>
</template>
<script>
import TestAppliedUpdatePage from "@/components/testBattery/appliedTestUpdate.vue"
export default {
    components:{
        TestAppliedUpdatePage
    }
}
</script>