<template>
     <Breadcrumbs main="Batería de Pruebas" title="Pruebas Aplicadas"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <TestAppliedCreatePage/>
              </div>
            </div>
     </div>
</template>
<script>
import TestAppliedCreatePage from "@/components/testBattery/appliedTestCreate.vue"
export default {
    components:{
        TestAppliedCreatePage
    }
}
</script>