<template>
     <Breadcrumbs main="Batería de Pruebas" title="Agregar"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <TestCreate/>
              </div>
            </div>
     </div>
</template>
<script>
import TestCreate from "@/components/testBattery/testCreate.vue"
export default {
    components:{
        TestCreate
    }
}
</script>