import { database} from "@/firebase/config";
import { ref, onValue} from "firebase/database";

const state = {
    data: []
}

const mutations = {
    setData(state, newData) {
        state.data = newData
    }
}

const actions = {
    fetchData({ commit }) {
        const dbRef = ref(database, 'users');
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            commit('setData', data);
        }, (error) => {
            console.error('Error fetching data:', error);
        });
    }
}

const getters = {
    getData: state => state.data
}

export default {
    state,
    mutations,
    actions,
    getters
}