<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
             
              <g id="freepik--background-complete--inject-23">
                <path class="shp1" d="M92.64,317.14l-14.84.28-7.51.12c-2.51,0-5,0-7.55,0H62.3v-.45l0-12.63V304h.5l14.95.07c5,0,10,.11,15,.16h.27v.27c0,2.14-.09,4.27-.13,6.38Zm0,0-.15-6.37c0-2.1-.11-4.19-.13-6.26l.28.27c-5,.06-10,.16-15,.17L62.74,305l.5-.5-.05,12.63-.45-.44c2.47,0,4.93,0,7.4.05l7.43.12Z"></path>
                <path class="shp1" d="M76,333.07l-14.83.28-7.52.12c-2.51,0-5,0-7.55,0h-.44v-.45l-.06-12.63v-.5h.5L61,320c5,0,10,.12,14.95.17h.27v.27c0,2.14-.08,4.27-.13,6.38Zm0,0-.14-6.37c0-2.1-.11-4.19-.13-6.26l.27.27c-5,.06-10,.16-14.95.17l-14.95.06.5-.5-.05,12.63-.45-.44c2.47,0,4.93,0,7.4.05l7.44.12Z"></path>
                <path class="shp1" d="M76,59.71,61.13,60l-7.52.11c-2.51,0-5,0-7.55.06h-.44v-.45l-.06-12.63v-.5h.5L61,46.64c5,0,10,.12,14.95.17h.27v.27c0,2.14-.08,4.27-.13,6.38Zm0,0-.14-6.37c0-2.1-.11-4.19-.13-6.26l.27.27c-5,0-10,.16-14.95.17l-14.95.06.5-.5-.05,12.63-.45-.44c2.47,0,4.93,0,7.4,0l7.44.12Z"></path>
                <path class="shp1" d="M453.94,59.71,439.1,60l-7.51.11c-2.51,0-5,0-7.55.06h-.44v-.45l0-12.63v-.5h.5l14.95.06c5,0,10,.12,14.95.17h.27v.27c0,2.14-.09,4.27-.13,6.38Zm0,0-.15-6.37c0-2.1-.11-4.19-.13-6.26l.28.27c-5,0-10,.16-14.95.17L424,47.58l.5-.5-.05,12.63-.45-.44c2.47,0,4.93,0,7.4,0l7.43.12Z"></path>
                <path class="shp1" d="M437.26,75.64l-14.83.28-7.52.11c-2.51,0-5,0-7.55.06h-.44v-.45L406.86,63v-.5h.5l14.95.06c5,0,10,.12,14.95.17h.28V63c0,2.14-.08,4.27-.13,6.38Zm0,0-.14-6.37c-.05-2.11-.11-4.19-.13-6.26l.27.27c-5,0-10,.16-14.95.17l-14.95.06.5-.5-.05,12.63-.45-.44c2.47,0,4.93,0,7.4,0l7.44.12Z"></path>
                <path class="shp1" d="M360.69,223.44l-14.83.28-7.52.11c-2.51,0-5,0-7.54,0h-.45v-.45l-.05-12.64v-.5h.51l14.94.07c5,0,10,.11,14.95.16H361v.27c0,2.14-.08,4.27-.13,6.38Zm0,0-.14-6.38c-.05-2.1-.11-4.18-.13-6.26l.27.28c-5,0-10,.16-14.95.16l-14.94.06.49-.5-.05,12.64-.44-.45c2.46,0,4.92,0,7.39.06l7.44.11Z"></path>
                <path class="shp1" d="M344,239.37l-14.83.28-7.51.11c-2.51.05-5,0-7.55.05h-.45v-.45l-.05-12.64v-.5h.5l14.95.07c5,0,10,.11,14.94.16h.28v.27c0,2.14-.09,4.27-.13,6.38Zm0,0-.14-6.38c0-2.1-.11-4.18-.13-6.26l.27.28c-5,.05-10,.16-14.94.16l-14.95.06.5-.5-.06,12.64-.44-.45c2.46,0,4.93,0,7.4.06l7.43.11Z"></path>
                <path class="shp1" d="M327.4,223.44l-14.83.28-7.51.11c-2.52,0-5,0-7.55,0h-.45v-.45L297,210.8v-.5h.51l14.94.07c5,0,10,.11,14.95.16h.28v.27c0,2.14-.09,4.27-.14,6.38Zm0,0-.14-6.38c0-2.1-.11-4.18-.13-6.26l.27.28c-5,0-10,.16-14.95.16l-14.94.06.5-.5L298,223.44l-.44-.45c2.46,0,4.92,0,7.4.06l7.43.11Z"></path>
                <path class="shp1" d="M453.94,333.07l-14.84.28-7.51.12c-2.51,0-5,0-7.55,0h-.44v-.45l0-12.63v-.5h.5L439,320c5,0,10,.12,14.95.17h.27v.27c0,2.14-.09,4.27-.13,6.38Zm0,0-.15-6.37c0-2.1-.11-4.19-.13-6.26l.28.27c-5,.06-10,.16-14.95.17l-14.95.06.5-.5-.05,12.63-.45-.44c2.47,0,4.93,0,7.4.05l7.43.12Z"></path>
                <path class="shp1" d="M199.93,97.28l-14.84.28-7.51.11c-2.51,0-5,0-7.55.06h-.44v-.45l-.05-12.63v-.5h.5l14.95.06c5,0,10,.11,15,.17h.27v.27c0,2.14-.09,4.27-.13,6.37Zm0,0-.15-6.37c0-2.11-.11-4.19-.13-6.26l.28.27c-5,0-10,.16-15,.16L170,85.15l.5-.5-.05,12.63-.45-.44c2.47,0,4.93,0,7.4,0l7.43.11Z"></path>
              </g>
              <g id="freepik--Floor--inject-23">
                <polygon class="shp2" points="43.37 402.33 95.03 402.08 146.69 402 250 401.83 353.31 401.99 404.97 402.08 456.63 402.33 404.97 402.57 353.31 402.66 250 402.82 146.69 402.66 95.03 402.57 43.37 402.33"></polygon>
              </g>
              <g id="freepik--Shadow--inject-23">
                <ellipse class="shp1" cx="178.92" cy="445.22" rx="115.1" ry="18.2"></ellipse>
              </g>
              <g id="freepik--mouse-house--inject-23">
                <path class="shp8" d="M423.54,402.33H271.46c0-20.58-7.76-125.09,76-125.09S423.54,381.75,423.54,402.33Z"></path>
                <path class="shp10" d="M376.23,282.32c-2.5-.22-5.11-.34-7.81-.34-80.61,0-73.15,100.55-73.15,120.35H271.45c0-20.58-7.75-125.09,76-125.09A76.93,76.93,0,0,1,376.23,282.32Z"></path>
              </g>
              <g id="freepik--404-error--inject-23">
                <path class="shp2" d="M124,237.88V204.2H58V177.31l75.72-97.19h20.56v97h19.89V204.2H154.28v33.68Zm-35.71-60.8h39.1V126Z"></path>
                <path class="shp2" d="M391.82,237.88V204.2h-66V177.31l75.71-97.19h20.57v97H442V204.2H422.11v33.68Zm-35.71-60.8h39.1V126Z"></path>
                <path class="shp6" d="M213.35,90.37c9.33-5.1,17.66-3.32,23.32-3.4s17.15-7.91,23.75-6.73S299.38,97,304.5,104s13.64,24.73,14.44,28.31-1.3,23.8-.32,28.43-2.2,12.17-4.18,15.41-6.75,15.37-7.93,17.58S275,219.91,264.38,221.42s-14.75-1.5-15.91-2.16,2.34-8.21-3.17-10-7.57-2.69-7.57-2.69-1.56-4.63-4.46-5.72-7,2-7,2-1.24.69-3.65-.42-5.79.38-5.79.38a6.9,6.9,0,0,0-3.78-3.2c-2.81-1-6.1,4.94-6.1,4.94s-18.37-15.36-19.38-19.13-1-8.78-2.41-13.45-4.28-6.86-4.33-14.7,1.62-24.4,3.68-28.33,3.86-9.45,5.15-11.18S203.7,95.64,213.35,90.37Z"></path>
                <g class="shp4">
                  <path class="shp3" d="M213.35,90.37c9.33-5.1,17.66-3.32,23.32-3.4s17.15-7.91,23.75-6.73S299.38,97,304.5,104s13.64,24.73,14.44,28.31-1.3,23.8-.32,28.43-2.2,12.17-4.18,15.41-6.75,15.37-7.93,17.58S275,219.91,264.38,221.42s-14.75-1.5-15.91-2.16,2.34-8.21-3.17-10-7.57-2.69-7.57-2.69-1.56-4.63-4.46-5.72-7,2-7,2-1.24.69-3.65-.42-5.79.38-5.79.38a6.9,6.9,0,0,0-3.78-3.2c-2.81-1-6.1,4.94-6.1,4.94s-18.37-15.36-19.38-19.13-1-8.78-2.41-13.45-4.28-6.86-4.33-14.7,1.62-24.4,3.68-28.33,3.86-9.45,5.15-11.18S203.7,95.64,213.35,90.37Z"></path>
                </g>
                <path class="shp6" d="M276,191.63c1.54-1.88,5.47-2.19,5.93-.17s-.59,4.35-3.26,4.29S275.4,192.39,276,191.63Z"></path>
                <path class="shp7" d="M278.37,195.72c-2.39-.3-2.94-3.36-2.34-4.09a4.85,4.85,0,0,1,3.68-1.55c-.69.25-2.35,1.1-2.6,2.13S276.78,195,278.37,195.72Z"></path>
                <path class="shp6" d="M192,151.12c2.64-5.17,11.31-2.44,12.08,2.22s-3.87,8-7.2,7.76S189.13,156.78,192,151.12Z"></path>
                <path class="shp6" d="M288.11,157.66c-4.15-1.3-14.58,1.37-16.7,10.34-1.21,5.12,5.36,15.8,12.09,16.38s11.71-6.41,13.21-12.14S294.47,159.65,288.11,157.66Z"></path>
                <path class="shp7" d="M285.11,184.37a7.86,7.86,0,0,1-1.61,0c-6.73-.58-13.3-11.26-12.09-16.38,2.11-9,12.55-11.65,16.7-10.34a11.87,11.87,0,0,1,2.22,1c-5.38,1-12.77,6.39-13.38,10.23C276.3,173,279.16,183.43,285.11,184.37Z"></path>
                <path class="shp6" d="M256,90.12c-.34,3.43-4,5.76-7,8.09s-9,1.33-13.19-.66-6.65-10.2-6.54-10.81h0c2.76,0,5.24.26,7.35.23,3.89,0,10.56-3.79,16.49-5.72C254.34,83.56,256.26,87.72,256,90.12Z"></path>
                <path class="shp7" d="M246.7,99.28c-3.18.78-7.56-.18-10.85-1.73-4.21-2-6.65-10.2-6.54-10.81h0c2.2,0,4.22.17,6,.22.52,2.14,1.93,7.38,3.5,8.78C240.84,97.52,244.59,99.2,246.7,99.28Z"></path>
                <path class="shp6" d="M289.83,119.27c2.79-2,7.87,0,8.21,3.92s-4.77,6.17-8.21,3.51A4.58,4.58,0,0,1,289.83,119.27Z"></path>
                <path class="shp7" d="M290.72,121.93c-.58,2.16-.32,3.68,1.71,5.87a5.45,5.45,0,0,1-2.6-1.1,4.58,4.58,0,0,1,0-7.43,5.16,5.16,0,0,1,5-.26C294.63,118.92,291.3,119.77,290.72,121.93Z"></path>
                <path class="shp6" d="M247.57,210.7a5.4,5.4,0,0,0-2.27-1.46c-5.52-1.81-7.57-2.69-7.57-2.69s-1.56-4.63-4.46-5.72-7,2-7,2c.67-5.69,3.1-8,7.2-10.16s11.31-1.18,13.53,4.37C248.58,200.92,248,207.2,247.57,210.7Z"></path>
                <path class="shp7" d="M241.41,192.13a11.83,11.83,0,0,0-8.66,4.43,10.55,10.55,0,0,0-1.31,4.07,12,12,0,0,0-5.12,2.25c.67-5.69,3.1-8,7.2-10.16A10.83,10.83,0,0,1,241.41,192.13Z"></path>
                <path class="shp6" d="M218.26,115.18c-7.25,4-12.16,15.13-11.89,18.84s2.74,9.65,5.94,12.48c7.2,6.37,21.4,3.45,24.85,0s7.33-14.05,6.1-19.25C241.21,118.56,225.67,111.11,218.26,115.18Z"></path>
                <path class="shp7" d="M228.27,149.94c-5.39.81-11.83.22-16-3.44-3.2-2.83-5.68-8.76-5.94-12.47s4.64-14.87,11.89-18.85c2.28-1.25,5.32-1.41,8.5-.78-3.74,2.75-9.91,7.83-11.26,12.28-2,6.49-.81,13.26,2,17.19C219.32,146.38,224.66,148.64,228.27,149.94Z"></path>
                <path class="shp6" d="M202.5,161.87a7.93,7.93,0,0,1-4.64,1.36c-3.89-.26-9.05-5-5.7-11.64a6.25,6.25,0,0,1,6.5-3.23c3.51.31,7.07,2.69,7.6,5.82A7.64,7.64,0,0,1,202.5,161.87Z"></path>
                <path class="shp7" d="M202.5,161.87a7.93,7.93,0,0,1-4.64,1.36c-3.89-.26-9.05-5-5.7-11.64a6.25,6.25,0,0,1,6.5-3.23c-1.51,1.15-3.89,3.49-3.89,6.77,0,4.81,3,6,4.85,6.65A6.15,6.15,0,0,0,202.5,161.87Z"></path>
                <path class="shp6" d="M283.61,131.81a1.82,1.82,0,0,1-2.54-2.53,1.55,1.55,0,0,1,1.6-.8,2,2,0,0,1,1.86,1.44A1.87,1.87,0,0,1,283.61,131.81Z"></path>
                <path class="shp7" d="M283.61,131.81a1.82,1.82,0,0,1-2.54-2.53,1.55,1.55,0,0,1,1.6-.8,2.21,2.21,0,0,0-1,1.67,1.55,1.55,0,0,0,1.19,1.64A1.53,1.53,0,0,0,283.61,131.81Z"></path>
                <path class="shp6" d="M202.84,171.24c2.48-1.16,6.64.42,6.12,2.7s-2.7,4.15-5.4,2.8S201.83,171.72,202.84,171.24Z"></path>
                <path class="shp7" d="M207.88,176a3.46,3.46,0,0,1-4.32.7c-2.69-1.35-1.74-5-.72-5.5a4.74,4.74,0,0,1,2.41-.37,4.19,4.19,0,0,0-1.27,2.49C204,174.87,206,176.43,207.88,176Z"></path>
                <path class="shp2" d="M216.47,244.72V249h-15v-21.3h14.71v4.32h-9.79v4.14h8.41v4h-8.41v4.53Z"></path>
                <path class="shp2" d="M219.48,249v-21.3h9.6a6.16,6.16,0,0,1,2.77.63,7.39,7.39,0,0,1,2.21,1.65,7.91,7.91,0,0,1,1.45,2.31,6.88,6.88,0,0,1,.53,2.61,7.1,7.1,0,0,1-.25,1.91,7.34,7.34,0,0,1-.68,1.72A6.58,6.58,0,0,1,234,240a6.48,6.48,0,0,1-1.45,1.09l4.68,7.92h-5.55l-4.08-6.87H224.4V249Zm4.92-11.16h4.5a1.84,1.84,0,0,0,1.5-.82,3.4,3.4,0,0,0,.63-2.12,3,3,0,0,0-.72-2.1,2.13,2.13,0,0,0-1.56-.78H224.4Z"></path>
                <path class="shp2" d="M239.58,249v-21.3h9.59a6.2,6.2,0,0,1,2.78.63,7.39,7.39,0,0,1,2.21,1.65,7.91,7.91,0,0,1,1.45,2.31,6.86,6.86,0,0,1,.52,2.61,8,8,0,0,1-.23,1.91,8.36,8.36,0,0,1-.69,1.72,6.58,6.58,0,0,1-1.1,1.46,6.48,6.48,0,0,1-1.45,1.09l4.67,7.92h-5.54l-4.08-6.87H244.5V249Zm4.92-11.16H249a1.84,1.84,0,0,0,1.5-.82,3.46,3.46,0,0,0,.62-2.12,3,3,0,0,0-.71-2.1,2.13,2.13,0,0,0-1.57-.78H244.5Z"></path>
                <path class="shp2" d="M269,249.22a9.91,9.91,0,0,1-4.32-.93,10.64,10.64,0,0,1-3.33-2.44,11.15,11.15,0,0,1-2.14-3.47,10.92,10.92,0,0,1-.77-4,10.63,10.63,0,0,1,.8-4.05,10.77,10.77,0,0,1,5.59-5.8,10.19,10.19,0,0,1,4.26-.89,10,10,0,0,1,4.33.93,10.77,10.77,0,0,1,3.33,2.46,11.28,11.28,0,0,1,2.13,3.48,11.15,11.15,0,0,1,.74,4,10.62,10.62,0,0,1-.79,4.05,11,11,0,0,1-2.21,3.44,10.83,10.83,0,0,1-3.36,2.38A10,10,0,0,1,269,249.22Zm-5.54-10.8a8.33,8.33,0,0,0,.35,2.39,6.36,6.36,0,0,0,1.07,2.05,5.11,5.11,0,0,0,4.16,2,5.13,5.13,0,0,0,2.46-.57,5.39,5.39,0,0,0,1.73-1.48,6.5,6.5,0,0,0,1-2.07,8.22,8.22,0,0,0,.35-2.36,7.64,7.64,0,0,0-.37-2.38,6,6,0,0,0-1.08-2,5.34,5.34,0,0,0-1.75-1.41A5.21,5.21,0,0,0,269,232a5.09,5.09,0,0,0-2.45.56,5.44,5.44,0,0,0-1.74,1.45,6.18,6.18,0,0,0-1.05,2.06A8.15,8.15,0,0,0,263.46,238.42Z"></path>
                <path class="shp2" d="M282.47,249v-21.3h9.6a6.2,6.2,0,0,1,2.78.63,7.35,7.35,0,0,1,2.2,1.65,7.73,7.73,0,0,1,1.46,2.31,6.86,6.86,0,0,1,.52,2.61,7.52,7.52,0,0,1-.24,1.91,7.37,7.37,0,0,1-.69,1.72,6.34,6.34,0,0,1-2.55,2.55l4.68,7.92h-5.55l-4.08-6.87h-3.21V249Zm4.92-11.16h4.5a1.84,1.84,0,0,0,1.5-.82,3.4,3.4,0,0,0,.63-2.12,3,3,0,0,0-.72-2.1,2.11,2.11,0,0,0-1.56-.78h-4.35Z"></path>
                <path class="shp2" d="M69.37,75.24a9.91,9.91,0,0,1-4.32-.93,10.66,10.66,0,0,1-3.33-2.45,11.1,11.1,0,0,1-2.14-3.46,10.92,10.92,0,0,1-.77-4,10.63,10.63,0,0,1,.8-4,10.74,10.74,0,0,1,5.59-5.81,10.18,10.18,0,0,1,4.26-.88,9.91,9.91,0,0,1,4.32.93A10.54,10.54,0,0,1,77.11,57a11.1,11.1,0,0,1,2.13,3.48,11,11,0,0,1,.75,4,10.62,10.62,0,0,1-.79,4,10.86,10.86,0,0,1-5.57,5.82A10,10,0,0,1,69.37,75.24Zm-5.55-10.8a7.94,7.94,0,0,0,.36,2.38,6.41,6.41,0,0,0,1.07,2.06A5.23,5.23,0,0,0,67,70.32a5.17,5.17,0,0,0,2.4.54,5.08,5.08,0,0,0,2.46-.57A5.35,5.35,0,0,0,73.6,68.8a6.5,6.5,0,0,0,1-2.07A8.15,8.15,0,0,0,75,64.38,8,8,0,0,0,74.62,62a6,6,0,0,0-1.08-2,5.34,5.34,0,0,0-1.75-1.41A5.22,5.22,0,0,0,69.4,58a5.08,5.08,0,0,0-2.44.55A5.47,5.47,0,0,0,65.22,60a6.13,6.13,0,0,0-1,2A8.22,8.22,0,0,0,63.82,64.44Z"></path>
                <path class="shp2" d="M90,75.36a9.18,9.18,0,0,1-3.58-.66,7.84,7.84,0,0,1-2.66-1.79,7.52,7.52,0,0,1-1.63-2.61,8.89,8.89,0,0,1,0-6.21,7.52,7.52,0,0,1,1.63-2.61,8,8,0,0,1,2.66-1.8,9.87,9.87,0,0,1,7.15,0,8,8,0,0,1,2.64,1.8,7.72,7.72,0,0,1,1.65,2.61,8.62,8.62,0,0,1,.57,3.11,8.77,8.77,0,0,1-.55,3.1,7.55,7.55,0,0,1-1.64,2.61,7.8,7.8,0,0,1-2.65,1.79A9.23,9.23,0,0,1,90,75.36ZM86.44,67.2a4.34,4.34,0,0,0,1,3A3.21,3.21,0,0,0,90,71.31a3.17,3.17,0,0,0,1.38-.3,3.33,3.33,0,0,0,1.1-.84,4,4,0,0,0,.73-1.31,4.94,4.94,0,0,0,.27-1.66,4.37,4.37,0,0,0-1-3A3.17,3.17,0,0,0,90,63.09a3.26,3.26,0,0,0-2.5,1.14,4,4,0,0,0-.74,1.3A5,5,0,0,0,86.44,67.2Z"></path>
                <path class="shp2" d="M110.71,75.36a6.21,6.21,0,0,1-3.12-.75,5.16,5.16,0,0,1-2-2.1v8.94h-4.8V59.31h4.17v2.55a6.33,6.33,0,0,1,2.18-2.1,6.11,6.11,0,0,1,3.1-.75,6.79,6.79,0,0,1,2.93.63,7.13,7.13,0,0,1,2.31,1.72A8.1,8.1,0,0,1,117,63.94a9.08,9.08,0,0,1,.55,3.2,10.1,10.1,0,0,1-.51,3.27A8.17,8.17,0,0,1,115.6,73a6.59,6.59,0,0,1-2.16,1.73A6,6,0,0,1,110.71,75.36Zm-1.62-4a3,3,0,0,0,1.43-.35,3.77,3.77,0,0,0,1.12-.91,4.06,4.06,0,0,0,.74-1.34,5,5,0,0,0,.25-1.6,4.43,4.43,0,0,0-.28-1.61,4,4,0,0,0-.78-1.27,3.42,3.42,0,0,0-1.2-.84,3.85,3.85,0,0,0-1.52-.3,3.46,3.46,0,0,0-1.9.58,3.82,3.82,0,0,0-1.4,1.58v3.24a5,5,0,0,0,1.46,2A3.2,3.2,0,0,0,109.09,71.31Z"></path>
                <path class="shp2" d="M126.16,75.36a12.54,12.54,0,0,1-4-.65,9,9,0,0,1-3.3-1.87l1.71-2.94a14.26,14.26,0,0,0,2.81,1.53A7.1,7.1,0,0,0,126,72a2.65,2.65,0,0,0,1.44-.33,1.07,1.07,0,0,0,.51-1,1.13,1.13,0,0,0-.64-1,10,10,0,0,0-2.27-.79c-1.06-.3-2-.59-2.68-.87a7.62,7.62,0,0,1-1.79-.93,3,3,0,0,1-1-1.17,3.74,3.74,0,0,1-.3-1.56,4.9,4.9,0,0,1,1.8-3.87,6.29,6.29,0,0,1,2-1.08,7.75,7.75,0,0,1,2.5-.39,11.15,11.15,0,0,1,3.32.48,8.27,8.27,0,0,1,3,1.74l-1.86,2.88a12.67,12.67,0,0,0-2.4-1.35,5.48,5.48,0,0,0-2-.42,2.58,2.58,0,0,0-1.32.31,1.09,1.09,0,0,0-.54,1,1.07,1.07,0,0,0,.59,1,9.65,9.65,0,0,0,2.17.72,27.8,27.8,0,0,1,2.84.9,7.54,7.54,0,0,1,1.87,1,3.42,3.42,0,0,1,1.05,1.28,4.08,4.08,0,0,1,.33,1.71A4.5,4.5,0,0,1,130.87,74,7.38,7.38,0,0,1,126.16,75.36Z"></path>
                <path class="shp2" d="M134.89,75.06V70.17h3.93v4.89Z"></path>
                <path class="shp2" d="M142.06,75.06V70.17H146v4.89Z"></path>
                <path class="shp2" d="M149.23,75.06V70.17h3.93v4.89Z"></path>
              </g>
              <g id="freepik--Mouse--inject-23">
                <path class="shp6" d="M250.74,312.28c-2.47-.14-14.43-1.4-16.52-3.32-5.7-5.24-9-8.64-9.49-11.87-.46-2.87,1.8-8.3,1.85-11.25s5.06-1.34,6.78-1.52c3.56-.36,4.35,1.7,6.22,2.37,1.3.47,5.73-1.91,8.86.33s2.5,6.05,2.5,6.05,7.29,3.34,7.42,7.32C258.5,304.89,253.52,312.44,250.74,312.28Z"></path>
                <path class="shp5" d="M250.74,312.28c-2.47-.14-14.43-1.4-16.52-3.32-5.7-5.24-9-8.64-9.49-11.87-.46-2.87,1.8-8.3,1.85-11.25s5.06-1.34,6.78-1.52c3.56-.36,4.35,1.7,6.22,2.37,1.3.47,5.73-1.91,8.86.33s2.5,6.05,2.5,6.05,7.29,3.34,7.42,7.32C258.5,304.89,253.52,312.44,250.74,312.28Z"></path>
                <path class="shp0" d="M110.32,406.11c-3.63,4.24-18.46,7.16-27.17.33S69.27,376.91,69.5,369.26,92.09,268,98.45,245.7s-4-33.39-11.29-38.66-22.71.47-25.83,3.8S56.8,223,56.8,223s2.58-8,7.29-10.65,15.06-4.53,20.48-1.47,13.36,16.69,8.91,28.6S62,359.43,61.33,369.53c-.77,12.13,6.06,33.5,16.41,42.73s28.12,8.36,30,9,2.16,3.38,2.16,3.38Z"></path>
                <path class="shp7" d="M110.32,406.11c-3.63,4.24-18.46,7.16-27.17.33S69.27,376.91,69.5,369.26,92.09,268,98.45,245.7s-4-33.39-11.29-38.66-22.71.47-25.83,3.8S56.8,223,56.8,223s2.58-8,7.29-10.65,15.06-4.53,20.48-1.47,13.36,16.69,8.91,28.6S62,359.43,61.33,369.53c-.77,12.13,6.06,33.5,16.41,42.73s28.12,8.36,30,9,2.16,3.38,2.16,3.38Z"></path>
                <g class="shp9">
                  <path d="M104.82,410.46a38.5,38.5,0,0,0-.3,10.46l-.4,0a30.16,30.16,0,0,1-.3-5.11,30.49,30.49,0,0,1,.62-5.39c.08-.37.16-.74.25-1.11l.37-.12C105,409.59,104.89,410,104.82,410.46Z"></path>
                  <path d="M91.84,410.12l-.12.69a45.16,45.16,0,0,0-.57,5.34c0-.9-.07-1.8,0-2.7s.1-1.8.23-2.7l.09-.7Z"></path>
                  <path d="M93.85,419.58c0,.19,0,.37,0,.56,0-.19-.06-.38-.08-.57-.11-.7-.21-1.4-.27-2.11,0-.9-.12-1.8-.09-2.71a27.88,27.88,0,0,1,.36-4.36l.49,0a33.1,33.1,0,0,0-.47,4.34A40.65,40.65,0,0,0,93.85,419.58Z"></path>
                  <path d="M97.74,417.54q0,1.36,0,2.73l-.46-.07c0-.9,0-1.79,0-2.68a22.27,22.27,0,0,1,.9-5.32A32,32,0,0,0,97.74,417.54Z"></path>
                  <path d="M87.7,409c-.18.84-.34,1.69-.45,2.54a36.71,36.71,0,0,0-.41,5.34c0,.24,0,.47,0,.71l-.35-.13c0-.19,0-.39,0-.58a30.58,30.58,0,0,1,.3-5.41,26.2,26.2,0,0,1,.52-2.64Z"></path>
                  <path d="M77.57,399.29q-.19.39-.36.78a38.61,38.61,0,0,0-2.77,8.71l-.14-.17c0-.33.08-.66.13-1,.16-.88.3-1.78.54-2.65a32,32,0,0,1,1.78-5.12c.17-.37.35-.74.53-1.1Z"></path>
                  <path d="M79,401.73c-.31.77-.58,1.55-.82,2.34A35.37,35.37,0,0,0,77,409.26c-.12.66-.22,1.31-.29,2a3.45,3.45,0,0,1-.26-.28,17,17,0,0,1,.17-1.75,31.17,31.17,0,0,1,1.17-5.3c.29-.9.61-1.79,1-2.66C78.82,401.41,78.92,401.57,79,401.73Z"></path>
                  <path d="M81.67,407.24a31.75,31.75,0,0,0-1.15,5.25c-.08.59-.15,1.18-.2,1.77L79.9,414c.06-.51.14-1,.24-1.54A22.77,22.77,0,0,1,81.67,407.24Z"></path>
                  <path d="M75.73,395.53a30.61,30.61,0,0,0-1.8,2.73A46.77,46.77,0,0,0,71.44,403c.3-.84.59-1.7,1-2.53s.75-1.64,1.2-2.42a24.67,24.67,0,0,1,1.92-3C75.58,395.2,75.65,395.37,75.73,395.53Z"></path>
                  <path d="M71.11,381.27c-.37.33-.74.66-1.1,1a50.42,50.42,0,0,0-3.7,3.89c.53-.72,1-1.47,1.62-2.16s1.17-1.38,1.82-2c.4-.42.82-.82,1.25-1.19C71,381,71.08,381.11,71.11,381.27Z"></path>
                  <path d="M71.78,384a30.5,30.5,0,0,0-2.51,2.7,47.2,47.2,0,0,0-3.22,4.3c.44-.79.86-1.59,1.35-2.34s1-1.51,1.57-2.21a26.83,26.83,0,0,1,2.67-3C71.69,383.64,71.73,383.82,71.78,384Z"></path>
                  <path d="M73.11,388.55h0a32.29,32.29,0,0,0-2.4,2.71,34.19,34.19,0,0,0-3,4.41c-.17.28-.33.56-.49.84l-.24-.56c.09-.18.2-.36.3-.54A31.19,31.19,0,0,1,70.41,391a25.4,25.4,0,0,1,2.64-2.65Z"></path>
                  <path d="M69.84,374.32a34.27,34.27,0,0,0-5.16.6c-1.07.19-2.14.42-3.19.71,0-.15,0-.3,0-.45,1-.3,2.08-.54,3.14-.75a29.27,29.27,0,0,1,5.2-.5C69.81,374.06,69.82,374.19,69.84,374.32Z"></path>
                  <path d="M70.86,361.12c0,.16-.07.32-.1.48a34.59,34.59,0,0,0-3.81,0,36.9,36.9,0,0,0-4.32.4.43.43,0,0,1,0-.11c.53-.13,1.05-.25,1.59-.34.89-.13,1.79-.28,2.69-.34A28.65,28.65,0,0,1,70.86,361.12Z"></path>
                  <path d="M70.4,363.47l-.09.47c-.49,0-1,0-1.47,0a35.2,35.2,0,0,0-5.33.48c-.48.07-.95.15-1.42.24,0-.11,0-.22.07-.33.42-.11.85-.21,1.28-.29a31.43,31.43,0,0,1,5.39-.6C69.35,363.44,69.88,363.45,70.4,363.47Z"></path>
                  <path d="M66.94,368.25a32.69,32.69,0,0,0-5.33.59l-.22.05c0-.12,0-.26,0-.4l.1,0A22.72,22.72,0,0,1,66.94,368.25Z"></path>
                  <path d="M71.68,357.12c0,.12-.05.23-.07.34-.31,0-.63-.07-.94-.09a36.75,36.75,0,0,0-5.35,0c-.59,0-1.17.08-1.76.15,0-.17.08-.33.11-.49q.81-.09,1.62-.15a30.67,30.67,0,0,1,5.42.14Z"></path>
                  <path d="M74.39,344.62c0,.16-.07.33-.11.49a34.77,34.77,0,0,0-5.11-.75c-.87-.08-1.75-.12-2.62-.12l.06-.25c.86,0,1.72-.07,2.59,0A31.2,31.2,0,0,1,74.39,344.62Z"></path>
                  <path d="M73.88,346.93c0,.15-.07.3-.1.44a28.08,28.08,0,0,0-3-.41,33.45,33.45,0,0,0-4.8-.18c0-.14.07-.28.1-.42a32.76,32.76,0,0,1,4.75.1A29.18,29.18,0,0,1,73.88,346.93Z"></path>
                  <path d="M68.36,351a28.51,28.51,0,0,0-3.33-.12,2.86,2.86,0,0,1,.07-.28A19.76,19.76,0,0,1,68.36,351Z"></path>
                  <path d="M77.38,331.42c0,.12,0,.23-.08.35-1.62-.33-3.27-.5-4.92-.68.9,0,1.8,0,2.7.07S76.62,331.29,77.38,331.42Z"></path>
                  <path d="M76.85,333.73c0,.16-.07.31-.11.46-1-.18-2-.3-3.07-.38a39.82,39.82,0,0,0-4.64-.14.24.24,0,0,0,0-.08c.64-.07,1.29-.14,1.94-.17.9,0,1.8-.06,2.71,0A27.54,27.54,0,0,1,76.85,333.73Z"></path>
                  <path d="M76.08,338.34l-.27-.06a31.72,31.72,0,0,0-5-.65c-.88,0-1.76-.07-2.65-.06,0-.15.07-.3.11-.45.86,0,1.72.05,2.57.13a23.12,23.12,0,0,1,5,1Z"></path>
                  <path d="M78.38,327.11c0,.16-.08.32-.11.47-.38-.09-.76-.16-1.14-.22a36.42,36.42,0,0,0-5.31-.6c-.37,0-.75,0-1.12,0,0-.11,0-.23.08-.34h1a31.41,31.41,0,0,1,5.39.49C77.61,326.94,78,327,78.38,327.11Z"></path>
                  <path d="M82.21,310.74c0,.1,0,.2-.07.3-1.22-.22-2.47-.37-3.71-.52.87,0,1.74,0,2.62.09Z"></path>
                  <path d="M80.75,316.93c0,.14-.07.28-.1.41l-.89-.19a46.84,46.84,0,0,0-5.31-.78c.89,0,1.8,0,2.7.07s1.8.16,2.68.33Z"></path>
                  <path d="M85,298.93c0,.16-.08.31-.11.46a34.89,34.89,0,0,0-4.08-1.06c-1-.22-2-.38-3-.5l.06-.22.35,0c.9.09,1.8.16,2.69.32A27.73,27.73,0,0,1,85,298.93Z"></path>
                  <path d="M83.94,303.44c0,.15-.07.3-.11.45-.7-.21-1.42-.39-2.14-.54a36,36,0,0,0-5.06-.8l.09-.39a31.21,31.21,0,0,1,5.08.71C82.52,303,83.23,303.22,83.94,303.44Z"></path>
                  <path d="M88,281.19a32.51,32.51,0,0,0-5.17-1.43l-.43-.08c0-.13.07-.26.1-.39l.41.09A23.55,23.55,0,0,1,88,281.19Z"></path>
                  <path d="M86,295l-.09.35-.44-.15a35.56,35.56,0,0,0-5.19-1.32c-.46-.09-.93-.17-1.4-.23l.12-.46,1.37.2a30.48,30.48,0,0,1,5.23,1.45Z"></path>
                  <path d="M99.48,241.47a.69.69,0,0,1,0,.13,32.75,32.75,0,0,0-3.9-1.57c-.68-.23-1.38-.44-2.07-.63a3.66,3.66,0,0,0,.15-.43c.69.21,1.38.44,2,.7A25.27,25.27,0,0,1,99.48,241.47Z"></path>
                  <path d="M97.12,245.11a32.17,32.17,0,0,0-5-1.46h0c0-.12.08-.23.11-.35A23,23,0,0,1,97.12,245.11Z"></path>
                  <path d="M97.67,219.43c-1.35.19-2.7.47-4,.75.86-.26,1.72-.55,2.6-.76l1.31-.29C97.58,219.23,97.63,219.33,97.67,219.43Z"></path>
                  <path d="M98.71,222c-.58,0-1.16.09-1.73.16a37.16,37.16,0,0,0-3.9.61.69.69,0,0,0,0-.13c.41-.13.83-.25,1.24-.35.88-.19,1.76-.4,2.66-.51.54-.09,1.09-.16,1.63-.2A3.52,3.52,0,0,1,98.71,222Z"></path>
                  <path d="M100.38,234.43c0,.1,0,.2,0,.31-1.23-.29-2.48-.51-3.73-.73.89.07,1.8.12,2.69.25Z"></path>
                  <path d="M98.11,226.55a29.4,29.4,0,0,0-3.87.3c0-.11,0-.2-.07-.3h0A20.75,20.75,0,0,1,98.11,226.55Z"></path>
                  <path d="M94.2,213.73c-1,.47-1.94,1-2.86,1.55-.53.3-1,.62-1.54,1l-.16-.24c.49-.37,1-.73,1.5-1.05a24.37,24.37,0,0,1,2.76-1.59Z"></path>
                  <path d="M88.84,209.82A29.33,29.33,0,0,0,86.19,212l-.22-.19A20.44,20.44,0,0,1,88.84,209.82Z"></path>
                  <path d="M77.58,205c-.57,1.07-1.07,2.16-1.53,3.27-.15.36-.29.72-.43,1.09l-.48,0c.18-.43.36-.85.55-1.27A27.29,27.29,0,0,1,77.36,205h.22Z"></path>
                  <path d="M73.75,206.31a28.65,28.65,0,0,0-1.45,3.38l-.35.06A20.83,20.83,0,0,1,73.75,206.31Z"></path>
                  <path d="M70.13,206.4c-.1,1-.16,2-.22,3.07,0-.9-.09-1.8-.06-2.7,0-.1,0-.19,0-.29Z"></path>
                  <path d="M67.38,210.91,67,211a29.59,29.59,0,0,1-.28-3.4l.48-.2A30.92,30.92,0,0,0,67.38,210.91Z"></path>
                  <path d="M64.93,209.45c-.08.83-.12,1.67-.12,2.5l-.25.12A19.21,19.21,0,0,1,64.93,209.45Z"></path>
                  <path d="M88.21,207.83a34.39,34.39,0,0,0-3,2.28l-.78.68a3.17,3.17,0,0,0-.41-.21c.31-.26.62-.52.94-.77a26.88,26.88,0,0,1,3.08-2.09Z"></path>
                  <path d="M92.54,211.78c-1,.64-1.91,1.34-2.86,2,.67-.59,1.33-1.21,2-1.77l.62-.47Z"></path>
                  <path d="M88.48,284.7l-.12.48-.3-.11a35.26,35.26,0,0,0-5.16-1.45c-.47-.11-.94-.2-1.42-.28,0-.1,0-.19.07-.29.48.05,1,.11,1.43.2a30.78,30.78,0,0,1,5.24,1.35Z"></path>
                  <path d="M91.39,272.91a1.6,1.6,0,0,0,0,.21l-2.22-.64C89.87,272.62,90.63,272.74,91.39,272.91Z"></path>
                  <path d="M90.27,277.39l-.09.41-1-.28a49.61,49.61,0,0,0-5.27-1c.89.06,1.8.1,2.69.21s1.79.25,2.67.47C89.58,277.22,89.93,277.3,90.27,277.39Z"></path>
                  <path d="M96.05,254.58l-.12.45q-.84-.24-1.68-.42a33.85,33.85,0,0,0-5-.82c0-.14.07-.27.11-.4a32.58,32.58,0,0,1,5,.73C94.93,254.26,95.49,254.41,96.05,254.58Z"></path>
                  <path d="M92.5,268.46l-.12.46a29.63,29.63,0,0,0-3.08-.8,33.62,33.62,0,0,0-3.72-.64.83.83,0,0,0,0-.15c.36,0,.71,0,1.07.05.89.1,1.79.18,2.68.36A25.8,25.8,0,0,1,92.5,268.46Z"></path>
                  <path d="M95,258.58a.93.93,0,0,1,0,.13l-1.32-.39Z"></path>
                  <path d="M96.14,248.55a31.85,31.85,0,0,0-5.06-1.3c0-.12.06-.24.1-.36A22.78,22.78,0,0,1,96.14,248.55Z"></path>
                  <path d="M79.76,321.14a1.17,1.17,0,0,1-.05.2,30.68,30.68,0,0,0-3.29-.74,37.83,37.83,0,0,0-4.11-.52c0-.15.07-.31.11-.46a29.37,29.37,0,0,1,4.07.6A27,27,0,0,1,79.76,321.14Z"></path>
                </g>
                <path class="shp2" d="M145,449a35.83,35.83,0,0,1,8.73-.12,27.92,27.92,0,0,1,9.43,2.58c.87.42,1.63-.87.76-1.29a33.07,33.07,0,0,0-19.32-2.62c-.95.15-.54,1.6.4,1.45Z"></path>
                <path class="shp2" d="M142.13,449.33a19.89,19.89,0,0,1,8.85,3.08,25.72,25.72,0,0,1,7.32,6.51c.55.78,1.85,0,1.29-.76a26.48,26.48,0,0,0-7.86-7,21.38,21.38,0,0,0-9.6-3.29c-1-.05-1,1.45,0,1.5Z"></path>
                <path class="shp2" d="M208.4,433a35.74,35.74,0,0,1,8.72-.12,28.28,28.28,0,0,1,9.44,2.58c.86.42,1.62-.87.75-1.29A33.12,33.12,0,0,0,208,431.58c-.95.16-.55,1.6.4,1.45Z"></path>
                <path class="shp2" d="M205.48,433.32a19.78,19.78,0,0,1,8.85,3.09,25.54,25.54,0,0,1,7.32,6.5c.55.78,1.85,0,1.29-.76a26.61,26.61,0,0,0-7.86-7,21.5,21.5,0,0,0-9.6-3.29c-1-.05-1,1.45,0,1.5Z"></path>
                <path class="shp0" d="M167.75,247.91s-23.36-.44-40.18-14.19c-13.91-11.39-19-17-22.06-14.2s1.43,48.7,38,59c12.45,3.49,24.24-19,24.24-19Z"></path>
                <path class="shp7" d="M167.75,247.91s-23.36-.44-40.18-14.19c-13.91-11.39-19-17-22.06-14.2s1.43,48.7,38,59c12.45,3.49,24.24-19,24.24-19Z"></path>
                <path class="shp7" d="M167.75,247.91v11.58S156,282,143.51,278.49a47.66,47.66,0,0,1-23.31-14.81c8.74-5.71,21.91-14.27,29.31-18.87A77.47,77.47,0,0,0,167.75,247.91Z"></path>
                <path class="shp0" d="M141.34,327.14s-14.26-26.82-13.28-39.92S143,252,154.6,246.44s65.67,2.38,83.53,20.8c3.87,4,.19,11.05-1.8,14.66-2.58,4.67-11.6,15.19-19.42,18.24,0,0,1.4,15.41-15,23.77,0,0,5.57,26.2,10.65,40.45s17.36,38,9.82,55.2-26.51,15.89-39,17.69S150,451.17,131.5,448.55,105.65,425.39,110.86,403C116.6,378.45,126.27,348.6,141.34,327.14Z"></path>
                <path class="shp0" d="M145.11,270.19s-7-.66-20.53-6.12-35.9-19.84-57.65-8.3c-25.12,13.32-33.51,73.71,14.41,92.38,41.34,16.1,70.76-45,70.76-45Z"></path>
                <path class="shp7" d="M152.09,303.17s-29.41,61.08-70.75,45c-10.8-4.2-18.74-10.53-24.27-18A66.38,66.38,0,0,1,48.16,302c-.83-11.85,1-29,16.37-38.49S99.58,263,115,271.33s29.16,9,32.65,10.74c0,0,0,0,0,0C155.23,289.52,152.09,303.17,152.09,303.17Z"></path>
                <path class="shp2" d="M115,259.78l7.44,2.92c2.49.95,5,1.93,7.46,2.84s5,1.81,7.52,2.62c1.26.43,2.53.78,3.82,1.13.64.16,1.28.34,1.93.49s1.3.26,2,.41a37.3,37.3,0,0,1-7.92-1.29,72.45,72.45,0,0,1-7.66-2.42c-2.51-.94-5-2-7.41-3.08S117.29,261.11,115,259.78Z"></path>
                <path class="shp2" d="M138,325.05l1.8-2.73,1.82-2.7,3.61-5.41c1.2-1.81,2.36-3.63,3.53-5.46.57-.92,1.16-1.83,1.69-2.78s1.09-1.87,1.67-2.81a45.16,45.16,0,0,1-2.69,6c-1,1.93-2.18,3.79-3.37,5.62s-2.47,3.6-3.82,5.31A63,63,0,0,1,138,325.05Z"></path>
                <path class="shp2" d="M139.05,269a3.87,3.87,0,0,1,1.17-.53,6.11,6.11,0,0,1,3.78.08c-.43.05-.84.05-1.25.09l-1.23.09C140.7,268.75,139.89,268.85,139.05,269Z"></path>
                <path class="shp2" d="M149.06,309a18.76,18.76,0,0,1,1.84-1.92c.64-.6,1.25-1.15,1.91-1.81a4.14,4.14,0,0,1-1.4,2.35A5.7,5.7,0,0,1,149.06,309Z"></path>
                <path class="shp2" d="M149.06,291.42l-7.22-.26c-2.41-.08-4.82-.16-7.22-.19-4.81-.07-9.63-.15-14.41.21s-9.28,2.05-13.67,3.93c-2.2.94-4.36,2-6.48,3.13s-4.24,2.29-6.31,3.52a82.94,82.94,0,0,1,12.47-7.35,42.1,42.1,0,0,1,13.92-4.22,137.51,137.51,0,0,1,14.5,0C139.46,290.45,144.27,290.83,149.06,291.42Z"></path>
                <path class="shp2" d="M145.11,293.17c-2.94.57-5.87,1.15-8.78,1.8s-5.8,1.37-8.62,2.28a33,33,0,0,0-4.11,1.6,29.5,29.5,0,0,0-3.81,2.21,28.84,28.84,0,0,0-3.46,2.78,27.69,27.69,0,0,0-3,3.26,24.59,24.59,0,0,1,6-6.66,25.27,25.27,0,0,1,3.85-2.44,32.64,32.64,0,0,1,4.22-1.7,63.62,63.62,0,0,1,8.78-2.08A78.78,78.78,0,0,1,145.11,293.17Z"></path>
                <path class="shp2" d="M225.21,306.55a19.59,19.59,0,0,1,3.57-12.3c.58-.78-.73-1.53-1.29-.76a19,19,0,0,0-3,6.42,22.87,22.87,0,0,0-.78,6.64c.06,1,1.56,1,1.5,0Z"></path>
                <path class="shp2" d="M226.43,307.6a12.65,12.65,0,0,1,3-5.57,18.31,18.31,0,0,1,6-4.57c.87-.4.11-1.69-.76-1.3a16.2,16.2,0,0,0-2.06,1.19,21.39,21.39,0,0,0-4.34,3.77A14,14,0,0,0,225,307.2c-.18.95,1.26,1.35,1.44.4Z"></path>
                <path class="shp2" d="M227,308.89a11.26,11.26,0,0,1,3.74-3.93,12.31,12.31,0,0,1,6.61-2.29.75.75,0,0,0,0-1.5,13.67,13.67,0,0,0-7.37,2.5,12.87,12.87,0,0,0-4.28,4.46c-.43.86.86,1.62,1.3.76Z"></path>
                <path class="shp0" d="M180.93,331.48s25.51,4.23,42.47-25.44c0,0,3.67-2.1,4.56,1.9,0,0,1.93,29.27-15.37,42.45-17.56,13.37-38.08,9.25-38.08,9.25S161.51,341.9,180.93,331.48Z"></path>
                <path class="shp2" d="M202.48,327.14a20.41,20.41,0,0,1-7.62,3.7,27.27,27.27,0,0,1-4.19.75,23.21,23.21,0,0,1-4.25,0l4.13-.8c1.36-.27,2.71-.6,4-.95s2.66-.76,4-1.2S201.19,327.73,202.48,327.14Z"></path>
                <path class="shp2" d="M174.51,359.64a44.43,44.43,0,0,0,5,.37c1.68,0,3.34,0,5-.09a56.57,56.57,0,0,0,9.86-1.48,50.87,50.87,0,0,0,9.43-3.2,61.1,61.1,0,0,0,8.77-4.85c-.66.52-1.32,1-2,1.55a22.66,22.66,0,0,1-2,1.48l-2.16,1.33c-.71.46-1.5.79-2.24,1.19a41,41,0,0,1-4.66,2c-.79.3-1.61.51-2.42.77l-1.21.38-1.24.29a45,45,0,0,1-10.06,1.27A38.54,38.54,0,0,1,174.51,359.64Z"></path>
                <path class="shp2" d="M177,361.19a11.17,11.17,0,0,0,1.44-.13c.48-.07,1-.12,1.41-.2.94-.13,1.84-.29,2.83-.52A4.56,4.56,0,0,1,180,361.6a6.36,6.36,0,0,1-1.51,0A3.12,3.12,0,0,1,177,361.19Z"></path>
                <path class="shp2" d="M196.28,283.27c-.17-.11-.15.45,0,.59,3.87,3.46,7.62,8.06,5.11,12.55a.15.15,0,0,0,.25.17C205.63,292,200.59,285.94,196.28,283.27Z"></path>
                <path class="shp2" d="M189.48,283.44c-6.95,5.82,3.55,17.33,10,11.93S195.31,278.56,189.48,283.44Z"></path>
                <path class="shp2" d="M174.39,289.42a10.83,10.83,0,0,1-1.81-4.24,12.42,12.42,0,0,1-.08-4.71,10,10,0,0,1,1.93-4.36,8.76,8.76,0,0,1,3.69-2.82,23.89,23.89,0,0,0-2.37,3.66,13.78,13.78,0,0,0-1.28,3.89,22.87,22.87,0,0,0-.34,4.17C174.15,286.44,174.24,287.89,174.39,289.42Z"></path>
                <path class="shp2" d="M173.64,289.52a10.9,10.9,0,0,1-2.64-3.78,12.32,12.32,0,0,1-1.05-4.59,9.92,9.92,0,0,1,1-4.67,8.72,8.72,0,0,1,3-3.52A22.58,22.58,0,0,0,172.4,277a14,14,0,0,0-.45,4.07,22,22,0,0,0,.53,4.15C172.79,286.65,173.19,288.05,173.64,289.52Z"></path>
                <path class="shp11" d="M213.41,430.37c-9,5.89-21.13,5.6-30,6.88s-21.81,7.68-35.23,10.41c-.2-10.81.8-34,11-56,13.22-28.5,32-37.29,38.11-14.55C202,394.69,202.53,410.47,213.41,430.37Z"></path>
                <path class="shp2" d="M201.93,323.91a30.64,30.64,0,0,1-8.79,2.44,32.09,32.09,0,0,1-9.18-.12,34.84,34.84,0,0,1-4.5-1,30,30,0,0,1-4.25-1.77,20.59,20.59,0,0,1-2-1.09l-1.92-1.26a15.39,15.39,0,0,1-1.8-1.42c-.58-.49-1.15-1-1.72-1.49a41.47,41.47,0,0,0,7.78,4.56,34.83,34.83,0,0,0,4.21,1.56,34.34,34.34,0,0,0,4.37.94,38.28,38.28,0,0,0,8.95.35l2.24-.24c.74-.12,1.49-.22,2.23-.37A35.69,35.69,0,0,0,201.93,323.91Z"></path>
                <path class="shp2" d="M239.71,273.32s-9.9.63-9.9-2.36c0-1.38,7.56-6,10.29-6S239.71,273.32,239.71,273.32Z"></path>
                <circle class="shp6" cx="193.55" cy="305.12" r="7.16"></circle>
              </g>
              <g id="freepik--Crumbs--inject-23">
                <path class="shp6" d="M255.56,435.53c0-1.4,2.6-3.16,3.32-3.6s4-.66,4.22-1.22,3.38-1.22,4.54-1.16,2.72,2.1,2.94,3.71a3.28,3.28,0,0,1-2.33,3.6c-1.44.28-4.07,1.61-5.17,1.72S255.56,437.09,255.56,435.53Z"></path>
                <path class="shp5" d="M255.56,435.53c0-1.4,2.6-3.16,3.32-3.6s4-.66,4.22-1.22,3.38-1.22,4.54-1.16,2.72,2.1,2.94,3.71a3.28,3.28,0,0,1-2.33,3.6c-1.44.28-4.07,1.61-5.17,1.72S255.56,437.09,255.56,435.53Z"></path>
                <path class="shp6" d="M244.32,430.74c-.72-1,.91-1.77,1.72-2.94s3.53-.7,4.05-.33a4.75,4.75,0,0,1,.86,3.27,5.11,5.11,0,0,1-3.22,2.08C246.82,432.82,244.77,431.34,244.32,430.74Z"></path>
                <path class="shp5" d="M244.32,430.74c-.72-1,.91-1.77,1.72-2.94s3.53-.7,4.05-.33a4.75,4.75,0,0,1,.86,3.27,5.11,5.11,0,0,1-3.22,2.08C246.82,432.82,244.77,431.34,244.32,430.74Z"></path>
                <path class="shp6" d="M257.1,428.8c.19-1.59,3.29-1.13,3.78-.27s-1.3,2.54-2.11,1.84S257,429.39,257.1,428.8Z"></path>
                <path class="shp5" d="M257.1,428.8c.19-1.59,3.29-1.13,3.78-.27s-1.3,2.54-2.11,1.84S257,429.39,257.1,428.8Z"></path>
                <path class="shp6" d="M246,438.11c.5-.62,2-.76,2.23-.4s.72,1.08.36,1.37-1.37.94-1.83.58S245.76,438.44,246,438.11Z"></path>
                <path class="shp5" d="M246,438.11c.5-.62,2-.76,2.23-.4s.72,1.08.36,1.37-1.37.94-1.83.58S245.76,438.44,246,438.11Z"></path>
                <path class="shp6" d="M235.85,433.23c.29-1,1.73-1.66,2.65-1.77s2.63.65,2.53,1.3-1.4,1.22-1.62,1.69-.9,1.48-1.58,1.21S235.52,434.34,235.85,433.23Z"></path>
                <path class="shp5" d="M235.85,433.23c.29-1,1.73-1.66,2.65-1.77s2.63.65,2.53,1.3-1.4,1.22-1.62,1.69-.9,1.48-1.58,1.21S235.52,434.34,235.85,433.23Z"></path>
              </g>
            </svg>
</template>
<style lang="scss" scoped>
  tspan { white-space:pre }
                .shp0 { fill: #f2b15c }
                .shp1 { fill: #ebebeb }
                .shp2 { fill: #263238 }
                .shp3 { fill: #ffffff }
                .shp4 { opacity: 0.300 }
                .shp5 { fill: #ffffff;opacity:0.300 }
                .shp6 { fill: #eeb82f }
                .shp7 { opacity: 0.1 }
                .shp8 { fill: #c7c7c7 }
                .shp9 { opacity: 0.2 }
                .shp10 { fill: #a6a6a6 }
                .shp11 { fill: #ffffff;opacity:0.5 }
</style>