<template>
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper col-auto p-0">
      <sidebar />
    </div>
    <div class="left-header col horizontal-wrapper ps-0">
      <div class="header-logo-wrapper col-auto p-0">
        <img
          class="logo-img"
          :src="logo"
        />
      </div>
    </div>
    <div class="nav-right col-6 pull-right right-header p-0">
      <ul class="nav-menus">
        <li class="serchinput">
          <searchInput />
        </li>
        <li>
          <modeView />
        </li>
        <li class="maximize">
          <maximizeView />
        </li>
        <li class="profile-nav onhover-dropdown">
          <profileView />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { database} from "@/firebase/config";
import { ref, onValue} from "firebase/database";

import searchInput from "./searchInput.vue";
import sidebar from "./sidebarView.vue";
import modeView from "./modeView.vue";
import maximizeView from "./maximizeView.vue";
import profileView from "./profileView.vue";

export default {
  components: {
    sidebar,
    modeView,
    maximizeView,
    searchInput,
    profileView,
  },
  data() {
    return {
      filtered: false,
      logo: "",
    };
  },
  methods: {
    collapseFilter() {
      this.filtered = !this.filtered;
    },
    loadData() {
      const companyRef = ref(database, 'company');

      // Escuchar cambios en los datos del residente
      onValue(companyRef, (snapshot) => {
        const companyData = snapshot.val();
        if (companyData) {
          this.logo = companyData.urlImage;

        } else {
          console.log("No se encontraron datos de la compañia.");
        }
      });
    },
  },
  mounted(){
    this.loadData()
  },

};
</script>

<style scoped>
.logo-img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .logo-img {
    max-width: 70%;
  }
}

img {
  background-color: rgba(255, 255, 255, 0.381);
  border-radius: 5px;
}
</style>