<template>
  <div id="sidebar-menu">
    <ul class="sidebar-links custom-scrollbar custom-scrollbar-hide" id="myDIV" :style="[
      layoutobject.split(' ').includes('horizontal-wrapper') ? layout.settings.layout_type == 'rtl' ? { 'margin-right': margin + 'px' } : { 'margin-left': margin + 'px' } : { margin: '0px' },
    ]">
      <li class="back-btn">
        <div class="mobile-back text-right">
          <span>Back</span> <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
        </div>
      </li>
      <li class="menu-box">
        <ul>
          <!-- Verificar si el menú no está minimizado y si el usuario está autenticado -->
          <li class="user-profile" v-if="!!togglesidebar && !!userData" style="padding-top: 10px; margin-left: auto;">
            <div class="d-flex align-items-start justify-content-end">
              <!-- Mostrar la imagen de perfil -->
              <!-- <img class="img-70 img-fluid m-r-10 rounded-circle update_img_0" src="@/assets/images/user/1.jpg" alt="" style="margin-top: -5px;"> -->
              <div class="img-70 img-fluid m-r-10 rounded-circle update_img_0" 
                style="margin-top: -5px; background-color: #007bff; color: #ffffff; font-size: 24px; text-align: center; line-height: 70px;">
                  {{ getInitials(userData.name) }}
              </div>
              <div class="status bg-success"></div>
              <div class="flex-grow-1">
                <!-- Mostrar el nombre del usuario -->
                <h6><span class="name text-white">{{ userData.name }}</span></h6>
                <!-- Mostrar el tipo de usuario -->
                <h6><span class="typeUser text-white">{{ userData.typeUser }}</span></h6>
                <!-- Mostrar el correo electrónico del usuario -->
                <p class="email text-white">{{ userData.email }}</p>
              </div>
            </div>
          </li>

          <!-- Fin de la sección de perfil -->
          <li v-for="(menu, index) in filteredMenuItems" :key="index" class="sidebar-list">
            <!-- Submenu -->
            <template v-if="menu.type === 'sub'">
              <label :class="'badge badge-' + menu.badgeType" v-if="menu.badgeType">{{ menu.badgeValue }}</label>
              <a href="javascript:void(0)" :class="{ active: menu.active }" class="sidebar-link sidebar-title" @click="toggleSubmenu(menu)">
                <vue-feather :type="menu.icon" class="top"></vue-feather>
                <span>{{ menu.title }}</span>
                <div class="according-menu">
                  <i class="pull-right" :class="[menu.active ? 'fa fa-angle-down' : 'fa fa-angle-right']"></i>
                </div>
              </a>
              <ul v-if="menu.active" class="submenu">
                <li v-for="(subItem, subIndex) in menu.children" :key="subIndex">
                  <router-link :to="subItem.path" class="sidebar-link sidebar-title">
                    <vue-feather :type="subItem.icon" class="top"></vue-feather>
                    <span>{{ subItem.title }}</span>
                  </router-link>
                </li>
              </ul>
            </template>
            <!-- Link -->
            <template v-else-if="menu.type === 'link'">
              <router-link :to="menu.path" class="sidebar-link sidebar-title" v-on:click="hidesecondmenu()">
                <vue-feather :type="menu.icon" class="top"></vue-feather>
                <span>{{ menu.title }}</span>
                <div class="according-menu">
                  <i class="pull-right" :class="[menu.active ? 'fa fa-angle-right' : 'fa fa-angle-down']"></i>
                </div>
              </router-link>
            </template>
            <!-- External Link -->
            <template v-else-if="menu.type === 'extLink'">
              <a :href="menu.path" class="sidebar-link sidebar-title" target="_blank" @click="setNavActive(menu, index)">
                <vue-feather :type="menu.icon" class="top"></vue-feather>
                <span>{{ menu.title }}</span>
                <i class="fa fa-angle-right pull-right" v-if="menu.children"></i>
              </a>
            </template>
            <!-- Default -->
            <template v-else>
              <a :href="menu.path" class="sidebar-link sidebar-title">
                <vue-feather :type="menu.icon" class="home"></vue-feather>
                <span class="menu1">{{ menu.title }}</span>
                <div class="according-menu">
                  <i class="pull-right" :class="[menu.active ? 'fa fa-angle-down' : 'fa fa-angle-right']"></i>
                </div>
              </a>
            </template>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'; // Importa mapGetters para acceder a los getters del store
import navMenu from "@/mixins/navMenu";

export default {
  mixins: [navMenu],
  data() {
    return {
      menuMinimized: false, // Variable para controlar si el menú está minimizado
    };
  },
  methods: {
  getInitials(name) {
      // Obtener las dos primeras iniciales del nombre del usuario
      const initials = name.split(' ').map(word => word.charAt(0)).slice(0, 3).join('');
      return initials.toUpperCase();
  },
  toggleSubmenu(menu) {
    menu.active = !menu.active;
  },
   // Método para verificar si el usuario tiene acceso a una opción de menú específica
  hasAccess(menu) {
    if (!this.userData || !menu.allowedRoles) return true; 
    return menu.allowedRoles.includes(this.userData.typeUser);
  }
  },
  computed:{
    // Mapea los getters que necesitas del store
    ...mapGetters(['userData']),
    filteredMenuItems() {
      return this.menuItems.filter(menu => this.hasAccess(menu));
    }
  },
}
</script>

<style scoped>
.user{
  font-size: 22px;
}
.typeUser{
  font-size: 13px;
}
.email{
  font-size: 10px;
}
</style>