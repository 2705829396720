<template>
  <div class="account-user"><vue-feather type="user"></vue-feather></div>
  <ul class="profile-dropdown onhover-show-div">
    <li>
      <a @click="viewEditProfile">
        <vue-feather type="user"></vue-feather><span>Perfil</span>
      </a>
    </li>
    <li>
      <a @click="logout">
        <vue-feather type="log-in"></vue-feather><span>Cerrar Cesión</span></a
      >
    </li>
  </ul>
</template>
<script>

export default {
  components: {},
  methods: {
      logout() {
      this.$store.dispatch("logout")
      .then(() => {
        // Limpiar cualquier dato local adicional si es necesario
        localStorage.clear("userData");
        // Redireccionar al usuario a la página de inicio después de cerrar sesión
        this.$router.replace("/");
      })
      .catch(error => {
        console.error("Error al cerrar sesión:", error);
        // Manejar el error si es necesario
      });
    },
    viewEditProfile() {
      this.$router.push("/perfil");
    },
  },
};
</script>