<template>
     <Breadcrumbs main="Escuelas" title="Directorio General"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <AdminSchool/>
              </div>
            </div>
     </div>
</template>
<script>
import AdminSchool from "@/components/schools/createSchool.vue"
export default {
    components:{
        AdminSchool
    }
}
</script>