<template>
            <div class="row justify-content-center">
              <div class="col-sm-6 col-lg-3 ">
                <div class="card o-hidden user-widget ">
                  <div class="card-header pb-0">
                    <div class="d-flex"> 
                      <div class="flex-grow-1"> 
                        <p class="badge badge-light-primary font-cards f-w-600 header-text-info">Usuarios<i class="fa fa-circle"> </i></p>
                        <h4>{{totalUsers}}</h4>
                      </div>
                      <div class="d-flex static-widget">
                       <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE0klEQVR4nO1Za2hcVRDeFpT6FtQ+xLp7zm59RKEtgogiClohgogKgigI4quoCIrgH9FUqIr+kD5+SETR/hCUUv/EUqru7sxNmkBsxcRaRZI2uTObTUMpsfHVJCtz7t0ktnvP2dy7iRF24PzZO/fMfOebmTN3NpVqSlOa0pS4clNv5RyN9IBGblfIvQp4TAH/lfPK2dT/QiqVZapIT2pg0siVsxftTOcHV6SWsqTzgysU0O7aAOYsoGmFfFADPZJakkwg73GCOGMppLbUUhLl0aPzBRGyM6WQNi2ut5XKcg3+XRr4Qw18SAH7q/aVLjC/Ix2OBSTIm4Js35IvXyiFQQMf08ATaRy5peEYdGfpRo3Uc4YT++WZKpZujg8iyJls0V9r7AAfmf2dQSM/k86XVzcEROAonarhwA55nkV+OhEQkyv+fQYI0uc1GDupivxQIhDp/OClCqgc4cBrBijQm0mBZIFeMHshb6vNGv+eLvD18dlAaosOCX7LnGKx9ErDgAC/H80afxAfCFCfZeOPQuOPJQWSwdLDob1PLPaGk7QZk9EO0GHDSGdppV3PmexTmU5Om72Av7foTV/VNXTe/JFUKsvkZYsTJ0QnPMn++IxQ94w9rFFU5ixT7uOIBjpqofozAwJpU9LQksoY7MVfR+oAj8UCEQLZYaHalF+F9HxSIBr5QWMP6Yvog6OP4wNB6rYwsicA4t+dFMg6HNEhIwejGaF8bCAKaNyycbmlv/9cWRr4pwRAgg7hwMgqhXTakkuchJGizYkM0FNGr7O0UiF/N+/cQPpGLl2zB/BWqy5QR2wg0rhJ/bZsPp4t+rfGveEVlp4LDoxbFdDflnw8mvb8DbGBhA5usToEvE/0cgV/4/yA0GS1WXSxqZDeSCWVDA6vdxg5LaEVgv62bjaAdhs2CsPrXLoZHF6fGEjoYIfDqS2iJ42d1Ps6cmO0OoiIbBSxqstfNgSEcTBfXu0Ir4kccIvoaqB3nYwAvWf29fwNMl2x6V5dHF3TMCDSGrhDhV8KgPB2NyO8TXQV8ssu3XSjPqxE5FTcp8ybDRDk9jpypD0Evdmlmyv4GxsCQjpOjfSpI1SkuWwNgNAuNxDaFYJudTSmFSkg1bsm/pwK/Sc00oDDsZ+zRbp/DuiBOoAMVAd1MpFUyL84DmpEI714XY9/Wd0AwlahzfKJG4xxgL4yLFQqy2dvd9pbR1hVT7ojB3yFMSqTGI/vNXsCTVly60/5rs94dI89hIBfV0i/WU7ypFScbNdQrvreml46X2JdIR+vF8SsYzSqPX527gdTtmsoJzaMLTurPRrotrNYsHa60j4Ab78mT5dX35F7QCG/Xc+94WaHxxTyO3OH3GJLbFpbF6RJGQ7OlFaF9KMFRF/1jhDJAN9hQsgSArEXyFyY9oqNGYawdIPNP438h3QHkmyvRm/MIBPA2VbC3g03dlGhGsLXescvUsieRXen1PJDEUz8museuzioLPy463t6gcCcEtvGh/0nLomsiMBHUlEOKo9unxn3OGr9gi6gafEhDLM7I4BMCCNY4ySK8uJa79iV9iq2OEsBjYsvhplgJvzv58id1jvF9dW2uMzwVvsNaAOC9MN/DgBnWOmLDaSuv9IWCwiyFxtIU5rSlKY0JbVA8g+dbRiPVAt1XgAAAABJRU5ErkJggg==">
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0">
                    <div class="progress-widget">
                      <div class="progress sm-progress-bar progress-animate">
                        <div class="progress-gradient-info" role="progressbar" style="width: 48%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span class="animate-circle"></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="card o-hidden message-widget">
                  <div class="card-header pb-0">
                    <div class="d-flex"> 
                      <div class="flex-grow-1"> 
                        <p class="badge badge-light-primary font-cards f-w-600 header-text-secondary">Convenios<i class="fa fa-circle"></i></p>
                        <h4>{{totalAgreements}}</h4>
                      </div>
                      <div class="d-flex static-widget">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADAUlEQVR4nO1YS2sUQRBu3ao1UTCKiKIgXtSDIhFR/4GPH+DBN3jwKB40Bz0IihcvPg5614MQcKs2CWriYRUVNT7xpAR8EHOO0WyS6g221GR3SfbhJsPs7ix0QcEw0/1VfV09M9WfMd68tZa5jAFJ4wnL8EVdr/WeaRVzF81iSScPzhBAN9fhmyU8FWtCrlABgqFi4gRDeq/a/VgRchUrUL7y8x3XcAubWGwIuYgSaRohV6fADSPkGhSobnFck0rvooobl5fRhc0jLgRC5xVXAqVWM09hfB3rP+48OgghfKlEXlRr6iZpyUZhPC6MN4XxkSX4bAlGhHFUPVgRhg/C2C+EVyWFhyZ62jaYGqZjhPBwMIdwQDEUSwq4BCMaSwgfCuENzaEUd3ZTKoxPyoK4btNhCbosw6fypq+2C+Ffy/jWEpx1ZFYUcfvMSstwLngWAtfObKWPiqE5Vl0l12+WCeFlYfwVPlAJKcY/rtsk1fU6QtxRYbzkeszSMiJC+CaqQLPdZUybej2whXCwEpHJFiQy6YlYXxH0W8v6d4T9V8v5zy/7/8hc83929i2K870W+6YRfRvv/HkEIzxYEfywDHcsw+lcOrF/ipNbsty+TkUF9UBlIezMpRJ7A8GC4J5lGK59QoThYCxBl85VDMVyedxsb/t6jaUxLcEZS3DXEvxcEBEVCYTxmjDuMCHMObPIMuwRwutC+LtARAjHFNemYLeOCYNtCXcG8hDh+P+IaKArLmVWFZPqM2slhUeF8LYwZgJNKw+SBxrP61wZYbwljEdc2qwpzu82HaoQBj5LwpkHblYrIIRPgzGEx0pwV+c1sbFKRDoL17lU4oAQPhbG6QULAozTOle3RWmMCHAHcpTYV6io3MdtFUsnadyuEkuEKscr3Wa2B3ZFjDsoadxamcSMhJmth2xTDxfGCWE8Wf4ixSA5G8IrVeR5s5OyC6/KM1Mvm0olN1uG71UTIPg61ZvcZFrBnCr6DBcswXsVxQNhnOCdJTjvHpjlzc7Pm2mA/QOUBSk4S7u8ZwAAAABJRU5ErkJggg==">
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0">
                    <div class="progress-widget">
                      <div class="progress sm-progress-bar progress-animate">
                        <div class="progress-gradient-secondary" role="progressbar" style="width: 48%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span class="animate-circle"></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="card o-hidden product-widget">
                  <div class="card-header pb-0">
                    <div class="d-flex"> 
                      <div class="flex-grow-1"> 
                        <p class="badge badge-light-primary font-cards f-w-600 header-text-success">Pruebas<i class="fa fa-circle"> </i></p>
                        <h4>{{totalBatteryTests}}</h4>
                      </div>
                      <div class="d-flex static-widget">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2klEQVR4nO1aQU4CUQz90cRLqNGNxuiCuFATlfkdPMP0g95CFi7doZ6AE+jCrS6FXzDGC3gDFcUD6HLMFwgwJDAzyvyZ2Jd0weK3fX3tpCQVYkqQWi0DqSvQ6sOY1Hjp1g6XRJYg9dGCJPUCpPxhw+fCnTcvsgIgVe0mfmMSNyYJb7uEqokm4z15c1KrC9DYGq1sOBusfr6hFuP6kRpfpcZzk1NkIuZh3MA/prEZ9Amk7n/lk/AsMpGoSkQO0CcXpTit6QZIigjFiPNviUBCJpgI2VcBWBHBM+JzaxHPyHjY/jpBZr9aWn06hFtQL+5kmwhh2cQtNL31LBN59K69WeGfzkxa9f+UiNtQu6H91HFv8G2h4W27NcxJwveuEl9Sl9a6MY8TnRGpvf2wfhxS+aEi1DDXayGpsQ2kTszvg6ZaNXOSKJFf2ltPAYdKK72WkhofwrxPExHzH7zt1oob/VhYDvtWpIlITxmHiptmZsK0FKSYSCwTTITsqwCsiOAZ8WO3DS+NE5DwEPPSOBHjKshLI3VWE14aKZ0rCi+NkWB7pwLetQKwXXlgRQKwXXlgRQIw1wa2qw8BM/ctIirMlYHtxGHUKpGJmLsPQyYNysjOpVEl1i0KQ9jBN1jroutLWHglAAAAAElFTkSuQmCC">
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0">
                    <div class="progress-widget">
                      <div class="progress sm-progress-bar progress-animate">
                        <div class="progress-gradient-success" role="progressbar" style="width: 60%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span class="animate-circle"></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" box-size">
                <div class="card profile-greeting ">               
                  <div class="card-header rounded-5">
                    <div class="d-sm-flex d-block justify-content-between">
                      <div class="flex-grow-1"> 
                        <div class="weather d-flex">
                          <h2 class="f-w-400"> <span>{{ weatherData.temperature }}<sup><i class="fa fa-circle-o font-icon"></i></sup>C </span></h2>
                          <div class="span sun-bg f-22">
                            <i v-if="weatherData.weatherCondition === 'Nublado'" class="icofont icofont-cloud font-primary"></i>
                            <i v-else-if="weatherData.weatherCondition === 'Despejado'" class="icofont icofont-sun-alt font-primary"></i>
                            <i v-else-if="weatherData.weatherCondition === 'Lluvia'" class="icofont icofont-rain font-primary"></i>
                            <i v-else-if="weatherData.weatherCondition === 'Tormenta eléctrica'" class="icofont icofont-flash font-primary"></i>
                            <i v-else class="icofont icofont-sun font-primary"></i>
                          </div>
                        </div>
                        <span class="font-primary f-20 f-w-700">{{ weatherData.weatherCondition }}</span>
                        <h5>{{ weatherData.description }}</h5>
                      </div>
                      <div class="badge-group text-hour">
                        <div class="badge badge-light-primary f-18"><i class="fa fa-clock-o"></i><span id="txt" v-show="time">{{time}}</span></div>
                      </div>
                    </div>
                    <div class="greeting-user"> 
                      <div class="profile-vector">
                        <ul class="dots-images">
                          <li class="dot-small bg-info dot-1"></li>
                          <li class="dot-medium bg-primary dot-2"></li>
                          <li class="dot-medium bg-info dot-3"></li>
                          <li class="semi-medium bg-primary dot-4"></li>
                          <li class="dot-small bg-info dot-5"></li>
                          <li class="dot-big bg-info dot-6"></li>
                          <li class="dot-small bg-primary dot-7"></li>
                          <li class="semi-medium bg-primary dot-8"></li>
                          <li class="dot-big bg-info dot-9"></li>
                        </ul>
                        <!-- <img class="img-fluid" src="@/assets/images/dashboard/default/profile.png" alt=""> -->
                        <ul class="vector-image"> 
                          <li> <img src="@/assets/images/dashboard/default/ribbon1.png" alt=""></li>
                          <li> <img src="@/assets/images/dashboard/default/ribbon3.png" alt=""></li>
                          <li> <img src="@/assets/images/dashboard/default/ribbon4.png" alt=""></li>
                          <li> <img src="@/assets/images/dashboard/default/ribbon5.png" alt=""></li>
                          <li> <img src="@/assets/images/dashboard/default/ribbon6.png" alt=""></li>
                          <li> <img src="@/assets/images/dashboard/default/ribbon7.png" alt=""></li>
                        </ul>
                      </div>
                      <span class="font-primary f-w-700 font-user" >Bienvenido {{truncateName(userData.name)}}</span>   <span class="right-circle"><i class="fa fa-check-circle font-primary f-14 middle"></i></span>
                      <div><span class="badge badge-primary custom-font-size" v-show="date">{{date}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
</template>
<script>
import { database} from "@/firebase/config";
import { ref,get} from "firebase/database";
import {mapGetters} from 'vuex';

export default {
  data(){
    return{
      time: '',
      date: '',
      weatherData: {
        temperature: '',
        description: '',
        weatherCondition: ''
      },
      totalUsers: 0,
      totalAgreements: 0,
      totalBatteryTests: 0
    }
  },
  computed: {
    ...mapGetters(['userData'])
  },
  methods:{
    async fetchWeatherData() {
      try {
        const apiKey = '6abdc2ded13fae1ef78d9ee3e7261c27'; // Reemplaza 'tu_api_key' con tu propia API Key de OpenWeatherMap
        const lat = 19.4326;
        const lon = -99.1332;
        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&units=metric`);
        const data = await response.json();
        console.log(data); // Para ver la estructura de la respuesta y extraer la información necesaria

        const weatherDescriptions = {
              'clear sky': 'cielo despejado',
              'few clouds': 'pocas nubes',
              'Overcast clouds': 'Nubes cubiertas',
              'scattered clouds': 'nubes dispersas',
              'broken clouds': 'nubes rotas',
              'shower rain': 'lluvia',
              'light rain': 'lluvia ligera',
              'rain': 'lluvia',
              'thunderstorm': 'tormenta eléctrica',
              'snow': 'nieve',
              'mist': 'neblina'
        };
        // Mapea las condiciones del clima en inglés a español
        const weatherConditions = {
          'Clear': 'Despejado',
          'Clouds': 'Nublado',
          'Rain': 'Lluvia',
          'Thunderstorm': 'Tormenta eléctrica',
          'Drizzle': 'Llovizna',
          'Snow': 'Nieve',
          'Mist': 'Neblina',
          'Fog': 'Niebla',
          'Haze': 'Neblina'
          
        };
        // Traduce la descripción del clima
        const description = (weatherDescriptions[data.weather[0].description] || data.weather[0].description).charAt(0).toUpperCase() + (weatherDescriptions[data.weather[0].description] || data.weather[0].description).slice(1);

        // Traduce la condición del clima
        const weatherCondition = weatherConditions[data.weather[0].main] || data.weather[0].main;

        this.weatherData.temperature = data.main.temp;
        this.weatherData.description = description;
        this.weatherData.weatherCondition = weatherCondition;
      } catch (error) {
        console.error('Error al obtener los datos del clima:', error);
      }
    },
    truncateName(name) {
      // Dividir el nombre en palabras
      const words = name.split(' ');

      // Tomar solo las dos primeras palabras
      const truncatedName = words.slice(0, 3).join(' ');

      return truncatedName;
    },
    printTime: function () {
            return new Date().toLocaleTimeString([], { hour12: true });
    },
    printDate: function () {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return new Date().toLocaleDateString(undefined, options);
    },
    loadUsers(){
      // Consulta el nodo "users" en la base de datos
      const usersRef = ref(database, "users");
      console.log(usersRef);
      get(usersRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            // Itera sobre los datos de los usuarios
            snapshot.forEach(() => {
              this.totalUsers++; // Incrementa el recuento por cada usuario
            });
          } else {
            console.log("No existen datos de los usuarios");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos de Firebase: ", error);
        });
    },
    loadAgreements(){
      // Consulta el nodo "agreements" en la base de datos
      const agreementsRef = ref(database, "agreements");
      console.log(agreementsRef);
      get(agreementsRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            // Itera sobre los datos de los convenios
            snapshot.forEach(() => {
              this.totalAgreements++; // Incrementa el recuento por cada convenio
            });
          } else {
            console.log("No existen datos de los convenios");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos de Firebase: ", error);
        });
    },
    loadBatteryTests(){
      // Consulta el nodo "batteryTests" en la base de datos
      const batteryTestsRef = ref(database, "testBattery");
      console.log(batteryTestsRef);
      get(batteryTestsRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            // Itera sobre los datos de las pruebas de batería
            snapshot.forEach(() => {
              this.totalBatteryTests++; // Incrementa el recuento por cada prueba de batería
            });
          } else {
            console.log("No existen datos de las pruebas de batería");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos de Firebase: ", error);
        });
    }
  },
  mounted(){
    setInterval(() => {
      this.time = this.printTime();
    }, 1000);
    this.date = this.printDate();
    this.fetchWeatherData();
    this.loadUsers();
    this.loadAgreements();
    this.loadBatteryTests();
  }
}
</script>
<style scoped>
  .font-icon {
    font-size: 0.8rem;
  }
  ul.vector-image li {
    position: absolute;
    overflow: hidden;
  }
  
  ul.vector-image li img {
    width: auto;
    height: 30px;
  }
  
  ul.vector-image li:first-child {
    top: 15%;
    right: 4%;
    animation: move2 1.2s infinite alternate;
  }
  
  ul.vector-image li:nth-child(2) {
    top: 55%;
    left: 15%;
    animation: move1 8s linear infinite;
  }
  
  ul.vector-image li:nth-child(3) {
    top: 5%;
    left: 38%;
    animation: move2 1.2s infinite alternate;
  }
  
  ul.vector-image li:nth-child(4) {
    top: 10%;
    right: 25%;
    animation: move1 8s linear infinite;
  }
  
  ul.vector-image li:nth-child(5) {
    top: 35%;
    right: 15%;
    animation: move2 1.2s infinite alternate;
  }
  
  ul.vector-image li:nth-child(6) {
    bottom: 1%;
    right: 36%;
    animation: move2 1.2s infinite alternate;
  }

  .box-size{
    max-width: 43%;
    overflow: hidden; 
    margin: 0 auto; 
    padding: 10px; 
  }

  .font-cards{
    font-size: 1.0rem;
  }

  .font-user{
    font-size: 1.4rem;
  }

  .custom-font-size{
    font-size: 0.9rem !important;
    margin-top: 7px;
  }

  .text-hour{
    padding-left: 50px;
  }
</style>