<template>
     <Breadcrumbs main="Batería de Pruebas" title="Informes"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <TestReportPage/>
              </div>
            </div>
     </div>
</template>
<script>
import TestReportPage from "@/components/testBattery/reportTest.vue"
export default {
    components:{
        TestReportPage
    }
}
</script>