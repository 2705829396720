import { createStore } from 'vuex';

// Importa los módulos necesarios
import menu from "./modules/menu";
import layout from "./modules/layout";
import bootstrap from "./modules/bootstrap";
import datatable from "./modules/datatable";
import firebd from './modules/firebase';

export default createStore({
  state: {
    // Recupera los datos del usuario del sessionStorage si están disponibles
    userData: JSON.parse(sessionStorage.getItem('userData')) || null,
    selectedUser: JSON.parse(sessionStorage.getItem('selectedUser')) || null,
    selectedSchool: JSON.parse(sessionStorage.getItem('selectedSchool')) || null,
    selectedAgreement: JSON.parse(sessionStorage.getItem('selectedAgreement')) || null,
    selectedResident: JSON.parse(sessionStorage.getItem('selectedResident')) || null,
    selectedTest: JSON.parse(sessionStorage.getItem('selectedTest')) || null,
    selectedApplied: JSON.parse(sessionStorage.getItem('selectedApplied')) || null,
    selectedReport: JSON.parse(sessionStorage.getItem('selectedReport')) || null
  },
  getters: {
    // Agregar un getter para obtener los datos del usuario
    userData: state => state.userData,

    getSelectedUser(state) {
      return state.selectedUser;
    },

    getSelectedSchool(state) {
      return state.selectedSchool;
    },

    getSelectedAgreement(state) {
      return state.selectedAgreement;
    },

    getSelectedResident(state) {
      return state.selectedResident;
    },

    getSelectedTest(state) {
      return state.selectedTest;
    },

    getSelectedReport(state) {
      return state.selectedReport;
    },

    getSelectedApplied(state) {
      return state.selectedApplied;
    }

  },
  mutations: {
    // Agregar una mutación para guardar los datos del usuario
    setUserData(state, userData) {
      state.userData = userData;
      // Almacena los datos del usuario en el sessionStorage
      sessionStorage.setItem('userData', JSON.stringify(userData));
    },  
    // Agregar una mutación para limpiar los datos del usuario al cerrar sesión
    clearUserData(state) {
      state.userData = null;
      sessionStorage.removeItem('userData');
    },

    setSelectedUser(state, user) {
      sessionStorage.setItem('selectedUser', JSON.stringify(user));
      state.selectedUser = user;
    },  

    setSelectedSchool(state, school) {
      sessionStorage.setItem('selectedSchool', JSON.stringify(school));
      state.selectedSchool = school;
    },
    setSelectedAgreement(state, agreement) {
      sessionStorage.setItem('selectedAgreement', JSON.stringify(agreement));
      state.selectedAgreement = agreement;
    },
    setSelectedResident(state, resident) {
      sessionStorage.setItem('selectedResident', JSON.stringify(resident));
      state.selectedResident = resident;
    },

    setSelectedTest(state, test) {
      sessionStorage.setItem('selectedTest', JSON.stringify(test));
      state.selectedTest = test;
    },

    setSelectedReport(state, report) {
      sessionStorage.setItem('selectedReport', JSON.stringify(report));
      state.selectedReport = report;
    },

    setSelectedApplied(state, applied) {
      sessionStorage.setItem('selectedApplied', JSON.stringify(applied));
      state.selectedApplied = applied;
    },
  },
  actions: {
    // Agregar una acción para cerrar sesión
    logout({ commit }) {
      // Llama a la mutación para limpiar los datos del usuario
      commit('clearUserData');
      commit('updateFilteredMenu', null);
    },
    updateSelectedUser(context, user) {
      context.commit('setSelectedUser', user);
    },
    updateSelectedSchool(context, school) {
      context.commit('setSelectedSchool', school);
    },
    updateSelectedAgreement(context, agreement) {
      context.commit('setSelectedAgreement', agreement);
    },
    updateSelectedResident(context, resident) {
      context.commit('setSelectedResident', resident);
    },
    updateSelectedTest(context, test) {
      context.commit('setSelectedTest', test);
    },
    updateSelectedApplied(context, applied) {
      context.commit('setSelectedApplied', applied);
    },
    updateSelectedReport(context, report) {
      context.commit('setSelectedReport', report);
    },
  },
  modules: {
    menu,
    layout,
    bootstrap,
    datatable,
    firebd
  }
});
