<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card">
          <div>
            <div>
              <a class="logo">
                <img
                  class="img-fluid"
                  src="@/assets/images/logo/logo2.png"
                  alt="login page"
                />
              </a>
            </div>
            <div class="login-main">
              <form class="theme-form" @submit.prevent="">
                <h4 class="text-center">Iniciar Sesión</h4>
                <p class="text-center">Ingresa tu correo y contraseña</p>
                <div class="form-group">
                  <label class="col-form-label">Correo Electrónico</label>
                  <input
                    v-model="email"
                    class="form-control"
                    type="email"
                    required
                    placeholder="Ingresa tu correo electrónico "
                  />
                </div>
                <div class="form-group">
                  <label class="col-form-label">Contraseña</label>
                  <div class="form-input position-relative">
                    <input
                      v-model="password"
                      :type="type"
                      class="form-control"
                      name="login[password]"
                      required
                      placeholder="*********"
                    />
                    <div class="show-hide">
                      <span class="show" @click="showPassword"></span>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <!-- <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox" />
                    <label class="text-muted" for="checkbox1"
                      >Recordar Contraseña</label
                    >
                  </div>
                  <a class="link"
                    ><router-link to="/authentication/forgetpassword"
                      >¿Olvido su Contraseña?
                    </router-link></a
                  > -->
                  <div class="text-end mt-3">
                    <button
                      class="btn btn-primary btn-block w-100"
                      type="submit"
                      @click="doLogin"
                    >
                      Ingresar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { auth } from '@/firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { database } from '@/firebase/config';
import { ref, get } from 'firebase/database';


export default {
  data() {
    return {
      type: "password",
      email: "",
      password: "",
    };
  },

  methods: {
    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    async doLogin() {
      try {
        // Limpiar el sessionStorage
        sessionStorage.clear();
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        // Obtener el usuario autenticado
        const user = userCredential.user;

        // Buscar la información del usuario en la base de datos
        const userRef = ref(database, `users/${user.uid}`);
        const userSnapshot = await get(userRef);

        // Verificar si existe la información del usuario en la base de datos
        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();

          // Guardar la información del usuario en Vuex
          this.$store.commit('setUserData', userData);
        }

        // Redireccionar al usuario a la página de inicio
        this.$router.push("/inicio");
      } catch (error) {
        console.error(error);
        toast.error('El usuario o contraseña son incorrectos', { autoClose: 3000 });
      }
    },
  },
};
</script>