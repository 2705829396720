<template>
     <Breadcrumbs main="Usuario" title="Perfil"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <EditUser/>
              </div>
            </div>
     </div>
</template>
<script>
import EditUser from "@/components/users/editUser.vue"
export default {
    components:{
        EditUser
    }
}
</script>