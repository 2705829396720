<template>
     <Breadcrumbs main="Escuelas" title="Actualizar Convenio"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <UpdateSchool/>
              </div>
            </div>
     </div>
</template>
<script>
import UpdateSchool from "@/components/schools/updateAgreement.vue"
export default {
    components:{
        UpdateSchool
    }
}
</script>