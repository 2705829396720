<template>
  <div  class="d-flex justify-content-center">
    <div class="col-xl-7 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos Generales</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Nombre de Escuela</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.name"
                  :class="{ 'is-invalid':formSubmitted && !formData.name }"
                  required
                  placeholder="Nombre de Escuela"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 mt-4">
              <div class="mb-3">
                <div class="d-flex mb-2">
                              <label class="col-form-label m-r-10">¿La escuela es extranjera?</label>
                              <div class="flex-grow-1 text-end icon-state switch-outline">
                                <label class="switch">
                                  <input v-model="formData.foreigner" type="checkbox"  data-bs-original-title="" title=""><span class="switch-state bg-warning"></span>
                                </label>
                              </div>
                </div>
              </div>
            </div>
            <div v-if="formData.foreigner" class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >País</h6>
                <select
                  class="form-select digits"
                  v-model="formData.country"
                  :class="{ 'is-invalid': formSubmitted && !formData.country }"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option 
                    v-for="country in americaCountries" 
                    :value="country" 
                    :key="country">
                    {{ country }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.country">
                  Por favor, introduce el país.
                </div>
              </div>
            </div>
            <div v-if="formData.foreigner === false" class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Estado</h6>
                <select
                  class="form-select digits"
                  v-model="formData.state"
                  :class="{ 'is-invalid': formSubmitted && !formData.state }"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option 
                    v-for="state in states" 
                    :value="state" 
                    :key="state">
                    {{ state }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.state">
                  Por favor, introduce el estado.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Tipo de escuela</h6>
                <select
                  class="form-select digits"
                  v-model="formData.typeSchool"
                  :class="{ 'is-invalid': formSubmitted && !formData.typeSchool}"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Pública">Pública</option>
                  <option value="Privada">Privada</option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.typeSchool">
                  Por favor, introduce el tipo de escuela.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500"> Nombre de contacto</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.contactName"
                  placeholder="Nombre de contacto"
                />
            </div>
            </div>
            <div class="col-md-5">
              <div class="mb-3">
                <h6 class="form-label f-w-500"> Teléfono Escuela </h6>
                <input
                  class="form-control"
                  type="number"
                  v-model="formData.phone"
                  placeholder="Teléfono Escuela"
                />
              </div>
            </div>
            <div class="col-md-1">
              <div class="mb-3 ">
                  <button class="btn btn-success w-100 mt-4 rounded-2 d-flex justify-content-center" type="button" @click="toggleSecondaryPhone" >
                    <i class="fa fa-plus"></i>
                  </button>
              </div>
            </div>
            <div v-if="showSecondaryPhone === true" class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500"> Teléfono Secundario (opcional) </h6>
                <input
                  class="form-control"
                  type="number"
                  v-model="formData.secondaryPhone"
                  placeholder="Teléfono Secundario"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-5">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Correo Electrónico</h6>
                <input
                  class="form-control"
                  type="email"
                  v-model="formData.email"
                  placeholder="Correo Electrónico"
                />
              </div>
            </div>
            <div class="col-md-1">
              <div class="mb-3">
                  <button class="btn btn-success w-100 mt-4 rounded-2 d-flex justify-content-center" type="button" @click="toggleSecondaryEmail" >
                    <i class="fa fa-plus"></i>
                  </button>
              </div>
            </div>
            <div v-if="showSecondaryEmail" class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Correo Electrónico Secundario (opcional)</h6>
                <input
                  class="form-control"
                  type="email"
                  v-model="formData.secondaryEmail"
                  placeholder="Correo Electrónico Secundario"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Enlace</h6>
                <input
                  class="form-control"
                  type="url"
                  v-model="formData.link"
                  placeholder="https://www.contacto.com"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-start">
              <button class="btn btn-secondary"
                @click="$router.push('/escuelas/directorio')"
              >Regresar</button>
          </div>
          <div class="float-end">
            <button
              class="btn btn-primary"
              type="submit"
              @click.prevent="validateAndSubmitForm"
            >
              Guardar Cambios
            </button>
          </div>

        </div>
      </form>
    </div>
  </div>
</template>

<script>

import { database } from "@/firebase/config";
import { ref, update } from "firebase/database";
import {mapGetters} from 'vuex';
import estados from "@/data/estados.js";
import paisesAmerica from "@/data/paisesAmerica.js";
import Swal from "sweetalert2";
import { toast } from 'vue3-toastify'

export default {
  data() {
    return {
      formData: {
        name: "",
        state: "",
        country: "México",
        foreigner: false,
        typeSchool: "",
        contactName: "",
        phone: null,
        secondaryPhone: null,
        email: "",
        secondaryEmail: null,
        link: "",
      },
      showSecondaryPhone: false,
      showSecondaryEmail: false,
      states: estados,
      americaCountries: paisesAmerica,
      formSubmitted: false,
    };
  },
  watch: {
    getSelectedSchool:{
      immediate: true,
      handler(newValue){
        if (newValue){
          this.formData = {
            id: newValue.id,
            name: newValue.name,
            state: newValue.state,
            country: newValue.country,
            foreigner: newValue.foreigner,
            typeSchool: newValue.typeSchool,
            contactName: newValue.contactName,
            phone: newValue.phone,
            secondaryPhone: newValue.secondaryPhone,
            email: newValue.email,
            secondaryEmail: newValue.secondaryEmail,
            link: newValue.link,
          }
        }

        if(this.formData.foreigner === undefined){
          this.formData.foreigner = false
        }
      }
    },
    'formData.foreigner'(newVal) {
      if (newVal) {
        this.formData.state = "";
        this.formData.country = "";
      }
    },
  },
  methods: {
    toggleSecondaryPhone() {
      this.showSecondaryPhone = !this.showSecondaryPhone;
    },
    toggleSecondaryEmail() {
      this.showSecondaryEmail = !this.showSecondaryEmail;
    },

    isEmailValid() {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.formData.email);
    },

    validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.updateSchool();
      }
    },

    validateForm() {
      // Validando campos necesarios del formulario
      if(this.formData.foreigner === true && (!this.formData.name || !this.formData.country || !this.formData.typeSchool)) {
        return false;  
      }

      if (this.formData.foreigner === false && (!this.formData.name || !this.formData.state || !this.formData.typeSchool)) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },
    cleanInputs() {
      this.formData = {};
    },
    async updateSchool() {
      const confirmation = await Swal.fire({
        title: "¿Guardar cambios?",
        text: "¿Está seguro de que desea guardar los cambios?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
      });

      if (confirmation.isConfirmed) {
        const schoolId = this.formData.id;
        const schoolRef = ref(database, `schools/${schoolId}`);
        try {
          await update(schoolRef, this.formData);
          toast.success('Datos actualizados correctamente');
          // Esperar unos segundos antes de redireccionar
          await new Promise(resolve => setTimeout(resolve, 1500)); // Espera 1.5 segundos
          this.$router.push("/escuelas/directorio");
        } catch (error) {
          console.error(error);
          // Muestra un mensaje de error
          Swal.fire({
            icon: "error",
            title: "Error al guardar los cambios",
            text: "Hubo un problema al guardar los cambios. Por favor, inténtelo de nuevo más tarde.",
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters(['getSelectedSchool']),
  },
};
</script>