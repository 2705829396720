<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-8 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos Personales</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500 ">Nombre</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.name"
                  :class="{ 'is-invalid':formSubmitted && !formData.name }"
                  required
                  placeholder="Nombre"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.name">
                  Por favor, introduce tu nombre.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Rol del usuario</h6>
                <select
                  class="form-select digits"
                  v-model="formData.typeUser"
                  :class="{ 'is-invalid': formSubmitted && !formData.typeUser }"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Administrador">Administrador</option>
                  <option value="Recursos Humanos">Recursos Humanos</option>
                  <option value="Residente">Residente</option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.typeUser">
                  Por favor, introduce el tipo de usuario.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Departamento</h6>
                <select
                  class="form-select digits"
                  v-model="formData.department"
                  :class="{ 'is-invalid': formSubmitted && !formData.department }"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Sistemas Computacionales">Sistemas Computacionales</option>
                  <option value="Diseño">Diseño</option>
                  <option value="Mercadotecnia">Mercadotecnia</option>
                  <option value="RRHH">RRHH</option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.department">
                  Por favor, introduce el departamento del usuario
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Correo Electrónico</h6>
                <input
                  class="form-control"
                  type="email"
                  v-model="formData.email"
                  :class="{ 'is-invalid':formSubmitted && !isEmailValid() }"
                  required
                  placeholder="Correo Electrónico"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !isEmailValid()">
                  Por favor, introduce un correo electrónico válido.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Teléfono</h6>
                <input
                  class="form-control"
                  type="number"
                  v-model="formData.phone"
                  placeholder="Teléfono"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Contraseña</h6>
                <input
                  class="form-control"
                  :type="type"
                  v-model="formData.password"
                  :class="{ 'is-invalid':formSubmitted && !formData.password }"
                  required
                  placeholder="Contraseña"
                />
                <div class="show-hide">
                      <span class="show" @click="showPassword = !showPassword"></span>
                </div>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.password">
                  Por favor, introduce tu contraseña.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Confirmar Contraseña</h6>
                <input
                  class="form-control"
                  :type="confirmType"
                  v-model="confirmPassword"
                  @input="validatePasswordMatch()"
                  :class="{ 'is-invalid':formSubmitted && !passwordsMatch }"
                  required
                  placeholder="Confirmar Contraseña"
                />
                <div class="show-hide">
                  <span class="show" @click="showConfirmPassword = !showConfirmPassword"></span>
                </div>
                <div class="invalid-feedback" v-if="formSubmitted && !passwordsMatch">
                  Las contraseñas no coinciden. Por favor, inténtalo de nuevo.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <button
            class="btn btn-primary"
            type="submit"
            @click.prevent="validateAndSubmitForm"
          >
            Agregar Usuario
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase/config";
import { database } from "@/firebase/config";
import { ref, set } from "firebase/database";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";
import { toast } from 'vue3-toastify'

export default {
  data() {
    return {
      formData: {
        idUser: "",
        name: "",
        typeUser: "",
        department: "",
        phone: "",
        status: "Activo",
        email: "",
        password: "",
      },
      residents:{
        idResident: "",
        idSchool: "",
        nameSchool: "",
        degree: "",
        enrollment: "",
        emailPersonal: "",
        timetable: {
          hours: "",
          totalHours: "",
          startDate: "",
          endDate: "",
        }
      },
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      formSubmitted: false,
    };
  },
  methods: {
    isEmailValid() {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.formData.email);
    },

    validatePasswordMatch() {
      this.passwordsMatch = this.formData.password === this.confirmPassword;
    },

    validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.submitForm();
      }
    },

    validateForm() {
      // Recorre todos los campos en formData
      for (const key in this.formData) {
        // Si el campo es lastNameM y está vacío, omite la validación
        if (key === "phone" || key === "idUser") {
          continue;
        }
        // Verifica si algún otro campo está vacío
        if (!this.formData[key]) {
          return false; // Si algún campo está vacío, retorna false
        }
      }
      // Verificar si el correo electrónico es válido
      if (!this.isEmailValid()) {
        return false;
      }
      // Verificar si las contraseñas coinciden
      this.validatePasswordMatch();
      if (!this.passwordsMatch) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },
    cleanInputs() {
      this.formData = {
        idUser: "",
        name: "",
        typeUser: "",
        department: "",
        phone: null,
        status: "Activo",
        email: "",
        password: "",
      };
      this.confirmPassword = "";
    },
    async submitForm() {
      try {
        // Crear el usuario en Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          this.formData.email,
          this.formData.password
        );
        const user = userCredential.user;
        this.formData.idUser = user.uid;

        // Guardar los datos del usuario en Firebase Realtime Database
        const userRef = ref(database, "users/" + user.uid);
        await set(userRef, {

          ...this.formData,
          email: this.formData.email, // Actualizamos el campo de email para asegurarnos de que coincida con el email autenticado
        });

        if (this.formData.typeUser === "Residente") {
          const residentRef = ref(database, "residents/" + user.uid);
          this.residents.idResident = user.uid;
          await set(residentRef, {
            ...this.residents,
          });
        }

      

        Swal.fire({
          icon: "success",
          title: "Usuario creado exitosamente",
        });

        this.cleanInputs();
        this.formSubmitted = false;

        console.log("Usuario creado exitosamente:", user);
      } catch (error) {
        // Manejar errores
        if (error.code === 'auth/email-already-in-use') {
          // Mostrar toast de error
          toast.error('El correo electrónico ya se encuentra registrado');
        }else{
          Swal.fire({
            icon: "error",
            title: "Error al crear usuario",
            //text: error.message, // Muestra el mensaje de error retornado por Firebase
          });
        }
      }
    },
  },
  computed: {
    type() {
      return this.showPassword ? "text" : "password";
    },
    confirmType() {
      return this.showConfirmPassword ? "text" : "password";
    }
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}
</style>