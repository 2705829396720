<template>
    <div class="col-xl-4 col-lg-5">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h4 class="card-title mb-0">Mi perfil</h4>
                      <div class="card-options"><a class="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a></div>
                    </div>
                    <div class="card-header">
                      <form>
                        <div class="row mb-2">
                          <div class="profile-title">
                            <div class="d-lg-flex d-block align-items-center">
                              <div class="profile-image img-100 m-r-10 rounded-circle update_img_0">
                                  {{ getInitials(userData.name) }}
                              </div>
                              <!-- <img class="img-70 rounded-circle" alt="" src="@/assets/images/user/7.jpg"> -->
                              <div class="flex-grow-1">
                                <h3 class="mb-4 f-20 txt-primary">{{userData.name}}</h3>
                                <h5 class="f-17 mb-2">Tipo de usuario:</h5>
                                <h5 class="f-17 mb-2">{{userData.typeUser}}</h5>
                                <h5 class="f-17 mb-2">Departamento:</h5>
                                <h5 class="f-17 mb-2">{{userData.department}}</h5>
                                <h5 class="f-17 mb-2">Estado:</h5>
                                <h5 class="f-17 mb-2 text-success">{{userData.status}}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="form-footer text-center">
                          <button class="btn btn-primary btn-block" @click.prevent="_blank">Cambiar Contraseña  
                            <i class="fa fa-key"></i>
                          </button>
                        </div> -->
                      </form>
                    </div>
                  </div>
              </div>
              <div class="col-xl-8 col-lg-7">
                  <form class="card">
                    <div class="card-header pb-0">
                      <h4 class="card-title mb-3">Editar Perfil</h4>
                      <div class="card-options"><a class="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a></div>
                      <h5 class="card-title mb-0">Datos Personales</h5> 
                    </div>
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <h5 class="form-label f-w-500">Nombre</h5>
                            <input class="form-control" type="text" v-model="personalData.name" placeholder="Nombre">
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                          <div class="mb-3">
                            <h5 class="form-label f-w-500">Correo Corporativo </h5>
                            <input class="form-control" type="email" v-model="personalData.email" placeholder="Correo Corporativo">
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="mb-3">
                            <h5 class="form-label f-w-500">Télefono</h5>
                            <input class="form-control" type="number" v-model="personalData.phone" placeholder="Télefono">
                          </div>
                        </div>
                        <div v-if="userData.typeUser ==='Residente'" class="row">
                          <h5 class="card-title mb-3">Datos Escolares</h5>
                          <div class="col-sm-6 col-md-6">
                            <div class="mb-3">
                              <h5 class="form-label f-w-500" >Nombre de la escuela</h5>
                              <Select2
                                v-model="residentData.idSchool"
                                :options="schools"
                                placeholder="Selecciona una escuela"
                                label="text"
                                track-by="id"
                                :class="{'is-invalid': formSubmitted && !residentData.idSchool}"
                                required
                                :noResult="'No se encontraron opciones disponibles'"
                              />
                              <div class="invalid-feedback" v-if="formSubmitted && !residentData.idSchool">
                                Por favor, introduce el nombre de la escuela.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <h5 class="form-label f-w-500">Licenciatura</h5>
                              <input
                                class="form-control"
                                type="text"
                                v-model="residentData.degree"
                                :class="{ 'is-invalid':formSubmitted && !residentData.degree }"
                                required
                                placeholder="Licenciatura"
                              />
                              <div class="invalid-feedback" v-if="formSubmitted && !residentData.degree">
                                Por favor, introduce el nombre de la licenciatura.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <h5 class="form-label f-w-500">Matrícula o No.Control</h5>
                              <input
                                class="form-control"
                                type="text"
                                v-model="residentData.enrollment"
                                :class="{ 'is-invalid':formSubmitted && !residentData.enrollment }"
                                required
                                placeholder="Matrícula o No.Control"
                              />
                              <div class="invalid-feedback" v-if="formSubmitted && !residentData.enrollment">
                                Por favor, introduce el nombre de la licenciatura.
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-6">
                            <div class="mb-3">
                              <h5 class="form-label f-w-500">Correo Personal</h5>
                              <input class="form-control" 
                                type="email"
                                v-model="residentData.emailPersonal" 
                                placeholder="Correo Personal">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-6">
                            <div class="mb-3">
                              <h5 class="form-label f-w-500" >Horario</h5>
                              <VueDatePicker 
                                v-model="timeRange" 
                                time-picker
                                :range="{ disableTimeRangeValidation: true }"
                                fotmat="HH:mm"
                                model-type="HH:mm"
                                cancel-text="Cancelar" 
                                select-text="Seleccionar"
                                placeholder="Horario"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4">
                            <div class="mb-3">
                              <h5 class="form-label f-w-500">Total de horas</h5>
                              <input class="form-control" 
                                type="number"
                                v-model="residentData.timetable.totalHours" 
                                placeholder="Total de horas">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-6">
                            <div class="mb-3">
                              <h5 class="form-label f-w-500" >Fecha de inicio</h5>
                              <VueDatePicker
                                v-model="residentData.timetable.startDate" 
                                :enable-time-picker="false"  
                                locale="es"
                                model-type="dd/MM/yyyy"
                                format="dd/MM/yyyy"
                                cancel-text="Cancelar" 
                                select-text="Seleccionar"
                                placeholder="Dia/mes/año"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-6">
                            <div class="mb-3">
                              <h5 class="form-label f-w-500" >Fecha de término</h5>
                              <VueDatePicker
                                v-model="residentData.timetable.endDate" 
                                :enable-time-picker="false"  
                                locale="es"
                                model-type="dd/MM/yyyy"
                                format="dd/MM/yyyy"
                                cancel-text="Cancelar" 
                                select-text="Seleccionar"
                                placeholder="Aproximada"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="mb-3">
                              <h6 class="form-label f-w-500" >Días Hábiles</h6>
                              <multiselect 
                                v-model="residentData.timetable.businessDays" 
                                :options="options" 
                                label="name" 
                                track-by="code"
                                placeholder="Seleccione los días hábiles" 
                                :selectLabel="'Haz clic para seleccionar'"
                                :deselectLabel="'Haz clic para deseleccionar'"
                                :searchable="false"
                                multiple
                              >
                              </multiselect>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer text-end">
                      <button class="btn btn-primary" type="submit" @click.prevent="validateAndSubmitForm">Guardar</button>
                    </div>
                  </form>
    </div>
</template>

<script>
import { database } from "@/firebase/config";
import { ref, get , update} from "firebase/database";
import { mapGetters } from 'vuex';
import { toast } from 'vue3-toastify'

export default {
  data() {
    return {
      personalData: {
        idUser: "",
        name: "",
        typeUser: "",
        department: "",
        email: "",
        status: "",
        phone: "",
      },
      residentData: {
        idResident: "",
        idSchool: "",
        nameSchool: "",
        degree: "",
        enrollment: "",
        emailPersonal: "",
        timetable: {
          hours: "",
          totalHours: "",
          startDate: "",
          endDate: "",
        }
      },
      timeRange: [],
      schools:[],
      options: [
        { name: 'Lunes', code: 'Lunes' },
        { name: 'Martes', code: 'Martes' },
        { name: 'Miercoles', code: 'Miercoles' },
        { name: 'Jueves', code: 'Jueves' },
        { name: 'Viernes', code: 'Viernes' },
      ],
      highlightedOption: null,
      formSubmitted: false,
    };
  },
  watch: {
    userData:{
      immediate: true,
        handler(newValue){
          if (newValue){
            this.personalData = {
              idUser: newValue.idUser,
              name: newValue.name,
              typeUser: newValue.typeUser,
              department: newValue.department,
              phone: newValue.phone,
              status: newValue.status,
              email: newValue.email,
            }
          }
        }
    },
    // Observa cambios en la variable temporal y actualiza 'residentData.timetable.hours'
    timeRange(newTimeRange) {
      // Convierte el array de horas en una sola cadena de texto separada por un guión
      this.residentData.timetable.hours = newTimeRange.join(' - ');
    }
  },

  computed:{
    // Mapea los getters que necesitas del store
    ...mapGetters(['userData']),
  },

  methods: {
    getInitials(name) {
      // Obtener las dos primeras iniciales del nombre del usuario
      const initials = name.split(' ').map(word => word.charAt(0)).slice(0, 3).join('');
      return initials.toUpperCase();
    },
    convertTimeRange() {
      // Verificar si hay un rango de horas definido en residentData.timetable.hours
      if (this.residentData.timetable.hours) {
        // Dividir la cadena de horas en un array utilizando el guión como separador
        const hoursArray = this.residentData.timetable.hours.split(' - ');

        // Actualizar el timeRange con el nuevo array de horas
        this.timeRange = hoursArray;
      }
    },
    validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.updateUser();
      }
    },
    validateForm() {
        
        if (!this.personalData.name || !this.personalData.email) {
          return false;
        }
        
        if(this.userData.typeUser === 'Residente'){
          if (!this.residentData.idSchool || !this.residentData.degree || !this.residentData.enrollment) {
          return false;
          }
        }
      return true; // Si todos los campos están llenos retorna true
    },
    async updateUser() {
      try {
          const userRef = ref(database, `users/${this.personalData.idUser}`);
          await update(userRef, this.personalData);
          this.$store.commit('setUserData', this.personalData);
          
          if(this.userData.typeUser === 'Residente'){
              // Obtiene el ID del residente
              const residentRef = ref(database, `residents/${this.residentData.idResident}`);
              // Busca el nombre de la escuela basándose en el ID seleccionado
              const selectedSchoolData = this.schools.find(school => school.id === this.residentData.idSchool);
          
              // Verifica si se encontró la escuela y asigna su nombre
              if (selectedSchoolData) {
                this.residentData.nameSchool = selectedSchoolData.text;
              } else {
                console.error('No se encontró la escuela con el ID seleccionado:', this.residentData.idSchool);
                // Podrías manejar este caso mostrando un mensaje de error al usuario o tomando otra acción adecuada
              }
              // Actualiza los datos del residente
              await update(residentRef, this.residentData);
          }
          toast.success('Datos actualizados correctamente');
          // Limpia los campos del formulario después de la actualización
          this.formSubmitted = false;
      } catch (error) {
          console.error('Error al actualizar los datos:', error.message);
          toast.error('Error al actualizar los datos');
      }
    },
    async loadDataResident(){
      try {
        // Obtener una referencia a la ubicación del usuario en la base de datos
        const residentRef = ref(database, `residents/${this.personalData.idUser}`);

        // Obtener los datos del usuario
        const snapshot = await get(residentRef);

        if (snapshot.exists()) {
          // El usuario existe, obtener los datos
          const userData = snapshot.val();
          console.log('Datos del residente:', userData);
          // Aquí puedes hacer lo que necesites con los datos del residente
          this.residentData = userData;
          console.log('Datos del residente:', this.residentData);
          this.convertTimeRange();
        } else {
          // El usuario no existe
          console.log('El usuario no existe en la base de datos.');
        }
      } catch (error) {
        console.error('Error al cargar los datos del residente:', error);
      }
    }
  },
  mounted() {
    this.loadDataResident();
    // Consulta el nodo "schools" en la base de datos
    const schoolsRef = ref(database, "schools");
    console.log(schoolsRef);
    get(schoolsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          // Itera sobre los datos de las escuelas
          snapshot.forEach((childSnapshot) => {
            const schoolData = childSnapshot.val();
            const schoolId = childSnapshot.key;
            const schoolName = schoolData.name;
            // Agrega el nombre de la escuela y su ID al array
            this.schools.push({ id: schoolId.toString(), text: schoolName });
          });
          console.log(this.schools);
        } else {
          console.log("No existen datos de escuelas");
        }
      })
      .catch((error) => {
        console.error("Error al obtener datos de Firebase: ", error);
      });
  },
  };
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.profile-image {
    margin-top: -5px;
    background-color: #007bff;
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    line-height: 70px;
    max-width: 70px;
    height: 70px;
}

</style>