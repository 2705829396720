<template>
     <Breadcrumbs main="Configuración" title="Datos de la empresa"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <SettingPage/>
              </div>
            </div>
     </div>
</template>
<script>
import SettingPage from "@/components/setting/updateDataCompany.vue"
export default {
    components:{
        SettingPage
    }
}
</script>