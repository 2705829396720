<template>
  <div  class="d-flex justify-content-center">
    <div class="col-xl-7 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos generales del convenio</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Nombre de la escuela</h6>
                <input
                  class="form-select digits"
                  type="text"
                  v-model="name"
                  :class="{ 'is-invalid':formSubmitted && !this.name }"
                  required
                  disabled
                  placeholder="Nombre de la escuela">
                <div class="invalid-feedback" v-if="formSubmitted && !this.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Estado del convenio</h6>
                <select
                  class="form-select digits"
                  v-model="formData.stateA"
                  :class="{ 'is-invalid': formSubmitted && !formData.stateA }"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Activo">Activo</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="Prospecto">Prospecto</option>
                  <option value="Caduco">Caduco</option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.stateA">
                  Por favor, introduce el estado.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Fecha de último contacto</h6>
                <VueDatePicker 
                  v-model="formData.lastContactDate" 
                  :enable-time-picker="false"  
                  locale="es"
                  model-type="dd/MM/yyyy"
                  cancel-text="Cancelar" 
                  select-text="Seleccionar"
                  placeholder="Dia/mes/año"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.lastContactDate">
                  Por favor, introduce la fecha de último contacto.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Fecha de termino de convenio</h6>
                <VueDatePicker 
                  v-model="formData.endDate" 
                  :enable-time-picker="false"
                  locale="es"
                  model-type="dd/MM/yyyy"
                  cancel-text="Cancelar" 
                  select-text="Seleccionar"
                  placeholder="En caso de existir"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-h6 f-w-500">Notas</h6>
                <textarea
                  class="form-control"
                  type="text"
                  v-model="formData.note"
                  placeholder="Escribe alguna nota"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
            <div class="float-start">
              <button class="btn btn-secondary"
                @click="$router.push('/escuelas/convenios')"
              >Regresar</button>
            </div>
            <div class="float-end">
                        <button
            class="btn btn-primary"
            @click.prevent="validateAndSubmitForm"
          >
            Guardar Cambios
          </button>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import { database } from "@/firebase/config";
import { ref, update} from "firebase/database";
import {mapGetters} from 'vuex';
import estados from "@/data/estados.js";
import Swal from "sweetalert2";
import { es } from 'date-fns/locale';
import { toast } from 'vue3-toastify'

export default {
  data() {
    return {
      formData: {
        idSchool: "",
        lastContactDate: "",
        stateA: "",
        endDate: "",
        note: "",
      },
      agreementId: "",
      name: "",
      states: estados,
      locale: es,
      formSubmitted: false,
    };
  },
  watch: {
    getSelectedAgreement:{
      immediate: true,
      handler(newValue){
        if (newValue){
          this.formData ={
            idSchool: newValue.idSchool,
            lastContactDate: newValue.lastContactDate,
            stateA: newValue.stateA,
            endDate: newValue.endDate,
            note: newValue.note,
          }
          this.agreementId = newValue.agreementId;
          this.name = newValue.name;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getSelectedAgreement"]),
  },
  methods: {

    validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.updateSchool();
      }
    },

    validateForm() {
      // Validando campos necesarios del formulario
        if (!this.formData.stateA || !this.formData.lastContactDate) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },
    cleanInputs() {
      this.formData = {};
    },
    async updateSchool() {
      const confirmation = await Swal.fire({
        title: "¿Guardar cambios?",
        text: "¿Está seguro de que desea guardar los cambios?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
      });

      if (confirmation.isConfirmed) {
        const agreementId = this.agreementId;
        const agreementRef = ref(database, `agreements/${agreementId}`);
        try {
          await update(agreementRef, this.formData);
          toast.success('Datos actualizados correctamente');
          // Esperar unos segundos antes de redireccionar
          await new Promise(resolve => setTimeout(resolve, 1500)); // Espera 1.5 segundos
          this.$router.push("/escuelas/convenios");
        } catch (error) {
          console.error(error);
          // Muestra un mensaje de error
          Swal.fire({
            icon: "error",
            title: "Error al guardar los cambios",
            text: "Hubo un problema al guardar los cambios. Por favor, inténtelo de nuevo más tarde.",
          });
        }
      }
    },
  },
};
</script>