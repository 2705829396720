import { createRouter, createWebHistory } from 'vue-router'
import Login from "@/pages/auth/LoginPage.vue"
import BodyView from "@/layout/BodyView.vue"

import indexDashbord from "@/pages/dashboard/indexDashbord.vue"

import createUser from "@/pages/users/indexCreateUser.vue"
import adminUsers from "@/pages/users/indexAdminUsers.vue"
import updateUser from "@/pages/users/indexUpdateUser.vue"
import editUser from "@/pages/users/indexEditUser.vue"

import addSchool from "@/pages/schools/indexSchoolsAdd.vue"
import schools from "@/pages/schools/indexSchools.vue"
import updateSchool from "@/pages/schools/indexSchoolUpdate.vue"

import addAgreement from "@/pages/schools/indexAgreementAdd.vue"
import agreements from "@/pages/schools/indexAgreement.vue"
import updateAgreement from "@/pages/schools/indexAgreementUpdate.vue"
import agreementDocuments from "@/pages/schools/indexAgreementDocuments.vue"	

import battery from "@/pages/testbattery/indexTestBattery.vue"
import addBattery from "@/pages/testbattery/indexTestCreate.vue"
import updateBattery from "@/pages/testbattery/indexTestUpdate.vue"
import documentsBattery from "@/pages/testbattery/indexTestDocuments.vue"

import appliedTest from "@/pages/testbattery/indexTestApplied.vue"
import appliedTestCreate from "@/pages/testbattery/indexTestAppliedCreate.vue"
import appliedTestUpdate from "@/pages/testbattery/indexTestAppliedUpdate.vue"

import reportTest from "@/pages/testbattery/indexTestReport.vue"
import reportTestCreate from "@/pages/testbattery/indexTestReportCreate.vue"
import reportTestUpdate from "@/pages/testbattery/indexTestReportUpdate.vue"

import residents from "@/pages/residents/indexResidents.vue"
import residentsUpdate from "@/pages/residents/indexUpdateResident.vue"
import residentsTimetable from "@/pages/residents/indexTimetableResident.vue"
import residentsDocuments from "@/pages/residents/indexDocumentsResident.vue"
import generateDocuments from "@/pages/residents/indexGenerateDocuments.vue"

import residentAppliedTest from "@/pages/resident/indexTestAppliedResident.vue"
import residentAppliedTestCreate from "@/pages/resident/indexTestAppliedCreate.vue"
import residentAppliedTestUpdate from "@/pages/resident/indexTestAppliedUpdate.vue"
import residentDocuments from "@/pages/resident/indexDocumentsResident.vue"

import training from "@/pages/training/indexTraining.vue"
import config from "@/pages/setting/indexSetting.vue"

import errorPage from "@/pages/error/errorPage.vue"

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: "/inicio",
    name: "inicio",
    component: BodyView,
    children: [
      {
        path: "",
        name: "dashbord",
        component: indexDashbord,
        meta: {
          title: 'Residentes - Inicio',
        }
      }
    ]
  },
  {
    path: "/perfil",
    name: "perfil",
    component: BodyView,
    children: [
      {
        path: "",
        name: "editar-perfil",
        component: editUser,
        meta: {
          title: 'editar-perfil',
        },
      },
    ]  
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: BodyView,
    children: [
      {
        path: "/usuarios/agregar",
        name: "agregar-usuario",
        component: createUser,
        meta: {
          title: 'Residentes - Usuarios',
        },
      },
      {
        path: "/usuarios/administrar",
        name: "administrar-usuarios",
        component: adminUsers,
        meta: {
          title: 'Residentes - Usuarios',
        },
      },
      {
        path: "/usuarios/actualizar",
        name: "actualizar-usuarios",
        component: updateUser,
        meta: {
          title: 'Residentes - Usuarios',
        },
      },
    ]
  },
  {
    path: "/bateria-pruebas",
    name: "Batería de Pruebas",
    component: BodyView,
    children: [
      {
        path: "",
        name: "Pruebas",
        component: battery,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/agregar",
        name: "Agregar Pruebas",
        component: addBattery,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/actualizar",
        name: "Actualizar Pruebas",
        component: updateBattery,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/documentos",
        name: "Documentos Pruebas",
        component: documentsBattery,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/aplicadas",
        name: "Pruebas Aplicadas",
        component: appliedTest,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/aplicadas-agregar",
        name: "Agregar Prueba Aplicada",
        component: appliedTestCreate,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/aplicadas-actualizar",
        name: "Actualizar Prueba Aplicada",
        component: appliedTestUpdate,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/informes",
        name: "Informes Pruebas",
        component: reportTest,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/informes-agregar",
        name: "Informes Agregar",
        component: reportTestCreate,
        meta: {
          title: 'Residentes - Pruebas',
        }
      },
      {
        path: "/bateria-pruebas/informes-actualizar",
        name: "Informes Actualizar",
        component: reportTestUpdate,
        meta: {
          title: 'Residentes - Pruebas',
        }
      }
    ]
  },
  {
    path: "/residentes",
    name: "residentes",
    component: BodyView,
    children: [
      {
        path: "/residentes/administrar",
        name: "residentes-administrar",
        component: residents,
        meta: {
          title: 'Residentes - Administrar',
        },
      },
      {
        path: "/residentes/actualizar",
        name: "residentes-actualizar",
        component: residentsUpdate,
        meta: {
          title: 'Residentes - Administrar',
        },
      },
      {
        path: "/residentes/horario",
        name: "residentes-horario",
        component: residentsTimetable,
        meta: {
          title: 'Residentes - Administrar',
        },
      },
      {
        path: "/residentes/documentos",
        name: "residentes-documentos",
        component: residentsDocuments,
        meta: {
          title: 'Residentes - Administrar',
        },
      },
      {
        path: "/residentes/generar-documentos",
        name: "residentes-generar-documentos",
        component: generateDocuments,
        meta: {
          title: 'Residentes - Documentos',
        },
      },
    ]
  },
  {
    path: "/escuelas",
    name: "escuelas",
    component: BodyView,
    children: [
      {
        path: "/escuelas/directorio",
        name: "escuelas-directorio",
        component: schools,
        meta: {
          title: 'Residentes - Escuelas',
        },
      },
      {
        path: "/escuelas/agregar",
        name: "escuelas-directorio-agregar",
        component: addSchool,
        meta: {
          title: 'Residentes - Escuelas',
        },
      },
      {
        path: "/escuelas/actualizar",
        name: "escuelas-directorio-acutalizar",
        component: updateSchool,
        meta: {
          title: 'Residentes - Escuelas',
        },
      },
      {
        path: "/escuelas/convenios",
        name: "escuelas-convenios",
        component: agreements,
        meta: {
          title: 'Residentes - Escuelas',
        },
      },
      {
        path: "/escuelas/convenios-agregar",
        name: "escuelas-convenios-agregar",
        component: addAgreement,
        meta: {
          title: 'Residentes - Escuelas',
        },
      },
      {
        path: "/escuelas/convenios-actualizar",
        name: "escuelas-convenios-acutalizar",
        component: updateAgreement,
        meta: {
          title: 'Residentes - Escuelas',
        },
      },
      {
        path: "/escuelas/convenios-documentos",
        name: "escuelas-convenios-documentos",
        component: agreementDocuments,
        meta: {
          title: 'Residentes - Escuelas',
        },
      },
    ]
  },
  {
    path: "/residente-pruebas-aplicadas",
    name: "residente-pruebas-aplicadas",
    component: BodyView,
    children: [
      {
        path: "",
        name: "residentes-pruebas-aplicadas",
        component: residentAppliedTest,
        meta: {
          title: 'Residente - Pruebas Aplicadas',
        }
      },
      {
        path: "/residente-pruebas-aplicadas/agregar",
        name: "residentes-pruebas-aplicadas-agregar",
        component: residentAppliedTestCreate,
        meta: {
          title: 'Residente - Pruebas Aplicadas',
        }
      },
      {
        path: "/residente-pruebas-aplicadas/actualizar",
        name: "residentes-pruebas-aplicadas-actualizar",
        component: residentAppliedTestUpdate,
        meta: {
          title: 'Residente - Pruebas Aplicadas',
        }
      }
    ]
  },  
  {
    path: "/residente-documentos",
    name: "residente-documentos",
    component: BodyView,
    children: [
      {
        path: "",
        name: "residente-documentos",
        component: residentDocuments,
        meta: {
          title: 'Residente - Documentos',
        }
      }
    ]
  },  
  {
    path: "/capacitacion",
    name: "capacitacion",
    component: BodyView,
    children: [
      {
        path: "",
        name: "capacitacion",
        component: training,
        meta: {
          title: 'Residentes - Capacitación',
        }
      }
    ]
  },
  {
    path: "/configuracion",
    name: "configuracion",
    component: BodyView,
    children: [
      {
        path: "",
        name: "configuracion",
        component: config,
        meta: {
          title: 'Residentes - Configuración',
        }
      }
    ]
  },
  {
    // Esta es la ruta para cualquier dirección no definida previamente
    path: "/:catchAll(.*)",
    name: "error",
    component: errorPage,
    meta: {
      title: 'Página no encontrada',
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,) => {
  if (typeof (to.meta.title) === 'string') {
    document.title = to.meta.title;
  }

});


export default router

