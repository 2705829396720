<template>
     <Breadcrumbs main="Residentes" title="Administrar Residentes"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <AdminResidents/>
              </div>
            </div>
     </div>
</template>
<script>
import AdminResidents from "@/components/residents/adminResidents.vue"
export default {
    components:{
        AdminResidents
    }
}
</script>