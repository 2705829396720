<template>
     <Breadcrumbs main="Batería de Pruebas" title="Actualizar"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <TestUpdate/>
              </div>
            </div>
     </div>
</template>
<script>
import TestUpdate from "@/components/testBattery/testUpdate.vue"
export default {
    components:{
        TestUpdate
    }
}
</script>