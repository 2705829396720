<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-7 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos Generales</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Nombre de prueba</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.nameTest"
                  :class="{ 'is-invalid':formSubmitted && !formData.nameTest }"
                  required
                  placeholder="Nombre de la Prueba"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.nameTest">
                  Por favor, introduce el nombre de la prueba.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Tiempo</h6>
                <VueDatePicker
                  v-model="formData.time"
                  :class="{ 'is-invalid':formSubmitted && !formData.time }"
                  time-picker
                  fotmat="HH:mm"
                  model-type="HH:mm"
                  cancel-text="Cancelar"
                  select-text="Seleccionar"
                  placeholder="Tiempo"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.time">
                  Por favor, introduce el tiempo de duración.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Medio de aplicación</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.method"
                  :class="{ 'is-invalid':formSubmitted && !formData.method }"
                  required
                  placeholder="Medio de aplicación"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.method">
                  Por favor, introduce el medio de aplicación.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Liga</h6>
                <input
                  class="form-control"
                  type="link"
                  v-model="formData.league"
                  placeholder="https://www.ejemplo.com"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-start">
            <button class="btn btn-secondary"
                @click="$router.push('/bateria-pruebas')"
            >Regresar</button>
          </div>
          <div class="float-end">
             <button
                class="btn btn-primary"
                type="submit"
                @click.prevent="validateAndSubmitForm"
            >
                Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { database} from "@/firebase/config";
import { ref,update} from "firebase/database";
import Swal from "sweetalert2";
import {mapGetters} from 'vuex';
//import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      formData: {
        idTest: "",
        nameTest: "",
        time: "",
        method: "",
        league: "",
        documents: {},
      },
      namedocument: "",
      urlPresentation: "",
      formSubmitted: false,
      document: null,
      image: null,
    };
  },
  methods:{
    validateForm() {
      // Validando campos necesarios del formulario
        if (!this.formData.nameTest || !this.formData.time || !this.formData.method) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },

     validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.updateTest();
      }
    },

    async updateTest() {
        try {
            // 1. Obtener una referencia a la colección 'testBattery'
            const testRef = ref(database, `testBattery/${this.formData.idTest}`);

            await update(testRef, this.formData);

            // Mostrar un mensaje de éxito
            await Swal.fire({
            icon: "success",
            title: "Se actualizaron los datos correctamente",
            });
            console.log("Datos actualizados correctamente.");
            this.$router.push('/bateria-pruebas')
        } catch (error) {
            console.error("Error al actualizar los datos:", error);
            Swal.fire({
            icon: "error",
            title: "Error al actualizar los datos",
            //text: error.message, // Muestra el mensaje de error retornado por Firebase
            });
        }
    },

  },
    computed:{
    ...mapGetters(['getSelectedTest'])
  },
    watch: {
    getSelectedTest: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.formData.idTest = newValue.idTest || '';
          this.formData.nameTest = newValue.nameTest || '';
          this.formData.time = newValue.time || '';
          this.formData.method = newValue.method || '';
          this.formData.league = newValue.league || '';
        }
      },
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.img-logo {
  max-width: 100%;
  max-height: 100%;
}

.img-container {
  background-color: #eee; /* Color gris */
  border: 1px solid #ccc; /* Borde gris */
  padding: 10px; /* Espacio interior */
  text-align: center; /* Centrar contenido */
  border-radius: 5px;
  margin-bottom: 5px;
  height:  70px;
  margin-left: auto; /* Centrar horizontalmente */
  margin-right: auto; /* Centrar horizontalmente */
  overflow: hidden; /* Evita que la imagen se salga del contenedor */
}
</style>