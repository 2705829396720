<template>
     <Breadcrumbs main="Escuelas" title="Convenios"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <AdminAgreement/>
              </div>
            </div>
     </div>
</template>
<script>
import AdminAgreement from "@/components/schools/adminAgreement.vue"
export default {
    components:{
        AdminAgreement
    }
}
</script>