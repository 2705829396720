<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Información de convenios con las escuelas</h4>
      </div>
      <div class="card-header">
        <div class="mb-3 row justify-content-start">
          <h6 for="search-type" class="col-xs-3 col-sm-auto col-form-label">
            Tipo de Búsqueda:
          </h6>
          <div class="col-xs-4 col-sm-2">
            <select v-model="searchType" id="search-type" class="form-select">
              <option value="name">Nombre</option>
              <option value="state">Estado</option>
              <option value="typeSchool">Tipo de Escuela</option>
            </select>
          </div>
          <div class="col-auto ms-auto">
            <button class="btn btn-success mb-2" @click="redirectAdd">
              <i class="fa fa-plus"></i> Agregar
            </button>
          </div>
        </div>
        <div
          v-if="searchType === 'state'"
          class="mb-3 row justify-content-start"
        >
          <h6 for="user-search" class="col-xs-3 col-sm-auto col-form-label">
            Buscar:
          </h6>
          <div class="col-xs-3 col-md-3">
            <select v-model="filterQuery" id="user-search" class="form-select">
              <option value="" disabled selected>Selecciona una opción</option>
              <option v-for="state in states" :value="state" :key="state">
                {{ state }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-else-if="searchType === 'typeSchool'"
          class="mb-3 row justify-content-start"
        >
          <h6 for="status-search" class="col-xs-3 col-sm-auto col-form-label">
            Buscar:
          </h6>
          <div class="col-xs-3 col-md-3">
            <select
              v-model="filterQuery"
              id="status-search"
              class="form-select"
            >
              <option value="" disabled selected>Selecciona una opción</option>
              <option value="Pública">Pública</option>
              <option value="Privada">Privada</option>
            </select>
          </div>
        </div>
        <div v-else class="mb-3 row justify-content-start">
          <h6
            for="table-complete-search"
            class="col-xs-3 col-sm-auto col-form-label"
          >
            Buscar:
          </h6>
          <div class="col-xs-3 col-sm-3">
            <input
              id="table-complete-search"
              type="text"
              class="form-control"
              placeholder="Escribe para buscar..."
              v-model="filterQuery"
            />
          </div>
        </div>
        <div class="table-responsive theme-scrollbar data-table">
          <form>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col" sortable="name">Nombre de la escuela</th>
                  <th scope="col" sortable="state">Estado</th>
                  <th scope="col" sortable="tipySchool">Tipo de escuela</th>
                  <th scope="col" sortable="lastContactDate">
                    Fecha de último contacto
                  </th>
                  <th scope="col" sortable="state">Estado de convenio</th>
                  <th scope="col" sortable="endDate">Fecha de término</th>
                  <th scope="col" sortable="note">Notas</th>
                  <th scope="col">Accciones</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="row in get_rows" :key="row.id">
                  <td>{{ row.name }}</td>
                  <td>{{ row.state }}</td>
                  <td>{{ row.typeSchool }}</td>
                  <td>{{ row.lastContactDate }}</td>
                  <td>{{ row.stateA }}</td>
                  <td>{{ row.endDate }}</td>
                  <td>{{ row.note }}</td>
                  <td>
                    <span>
                      <span @click="redirectUpdate(row, 'update')">
                        <i
                          class="icofont icofont-edit icofont-2x font-warning ps-2"
                        ></i>
                      </span>
                      <span @click="showFloatingForm(row, 'activities')">
                        <i
                          class="icofont icofont-tasks-alt icofont-2x font-tasks ps-2"
                        ></i>
                      </span>
                      <span @click="redirectDocuments(row)">
                        <i
                          class="icofont icofont-file-pdf icofont-2x font-documents ps-2"
                        ></i>
                      </span>
                      <span v-if="userData.typeUser === 'Administrador'" @click="deleteAgreementConfirmation(row)">
                        <i
                          class="icofont icofont-ui-delete icofont-2x font-danger ps-2"
                        ></i>
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="pagination m-3 justify-content-end pagination-primary">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous"
                  ><span aria-hidden="true">&laquo;</span></a
                >
              </li>
              <li
                class="page-item"
                v-for="i in num_pages()"
                :key="i"
                v-bind:class="[i == currentPage ? 'active' : '']"
                v-on:click="change_page(i)"
              >
                <a class="page-link">{{ i }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next"
                  ><span aria-hidden="true">&raquo;</span></a
                >
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ActivitiesAgreement
    :showForm="showActivities"
    :selectedSchool="selectedSchool"
    @close="closeFloatingForm"
  />
</template>

<script>
import Swal from "sweetalert2";
import { database } from "@/firebase/config";
import { ref, onValue, remove } from "firebase/database";
import ActivitiesAgreement from "@/components/schools/activitiesAgreement.vue";
import estados from "@/data/estados.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      schools: [],
      originalsAgreements: [],
      agreements: [],
      showUpdate: false,
      showActivities: false,
      showDocuments: false,
      selectedSchool: null,
      searchType: "name",
      elementsPerPage: 10,
      currentPage: 1,
      ascending: false,
      sortColumn: "",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterQuery: "",
      states: estados,
    };
  },
  components: {
    ActivitiesAgreement,
  },
  watch: {
    searchType(newSearchType) {
      console.log("Nuevo tipo de búsqueda:", newSearchType); // Agregamos un mensaje de registro
      // Al cambiar el tipo de búsqueda, restablecer filterQuery a ''
      this.filterQuery = "";
    },
    filterQuery(search) {
      var tableItems = [...this.originalsAgreements]; // Usar la lista original para filtrar
      var filteredData = tableItems.filter((row) => {
        const field = row[this.searchType].toLowerCase(); // Convertir a minúsculas
        const query = search.toLowerCase(); // Convertir a minúsculas

        // Verificar si el campo es 'state' y si la consulta coincide exactamente con el estado
        const result =
          this.searchType === "state" ? field === query : field.includes(query);

        return result;
      });
      this.agreements =
        search === "" ? [...this.originalsAgreements] : filteredData;
    },
  },
  computed: {
    ...mapGetters(['userData']),
    columns() {
      if (this.schools.length === 0) {
        return [];
      }
      return Object.keys(this.schools[0]);
    },
    get_rows() {
      const start = (this.currentPage - 1) * this.elementsPerPage;
      const end = start + this.elementsPerPage;
      return this.agreements.slice(start, end);
    },
  },
  methods: {
    redirectAdd() {
      this.$router.push("/escuelas/convenios-agregar");
    },
    redirectUpdate(agreement) {
      this.$store.dispatch("updateSelectedAgreement", agreement);
      this.$router.push("/escuelas/convenios-actualizar");
    },
    redirectDocuments(agreement) {
      this.$store.dispatch("updateSelectedAgreement", agreement);
      this.$router.push("/escuelas/convenios-documentos");
    },
    showFloatingForm(school, type) {
      if (type === "update") {
        this.selectedSchool = school;
        this.showUpdate = true;
        this.showActivities = false;
      } else if (type === "activities") {
        this.selectedSchool = school;
        this.showActivities = true;
        this.showUpdate = false;
      } else if (type === "documents") {
        this.selectedSchool = school;
        this.showDocuments = true;
        this.showUpdate = false;
        this.showActivities = false;
      }
    },
    closeFloatingForm() {
      this.showUpdate = false;
      this.showActivities = false;
      this.showDocuments = false;
    },
    async loadData() {
      try {
        // Suscribirse a cambios en schools
        const schoolsRef = ref(database, "schools");
        onValue(schoolsRef, (snapshot) => {
          this.schools = []; // Limpiar la lista de escuelas
          snapshot.forEach((schoolSnapshot) => {
            const school = schoolSnapshot.val();
            school.id = schoolSnapshot.key;
            this.schools.push(school);
          });
        });

        // Suscribirse a cambios en agreements
        const agreementsRef = ref(database, "agreements");
        onValue(agreementsRef, (snapshot) => {
          this.originalsAgreements = []; // Limpiar la lista de acuerdos originales
          snapshot.forEach((agreementSnapshot) => {
            const agreement = agreementSnapshot.val();
            const school = this.schools.find(
              (s) => s.id === agreement.idSchool
            );
            if (school) {
              const combinedData = {
                idSchool: school.id,
                agreementId: agreementSnapshot.key,
                name: school.name,
                state: school.state,
                typeSchool: school.typeSchool,
                ...agreement,
              };
              this.originalsAgreements.push(combinedData);
            }
          });
          // Actualizar la lista de acuerdos con los nuevos datos
          this.agreements = [...this.originalsAgreements];
        });
      } catch (error) {
        console.error("Error al cargar datos:", error.message);
      }
    },
    async deleteAgreementConfirmation(agreement) {
      // Mostrar el cuadro de diálogo de confirmación
      const result = await Swal.fire({
        title: "¿Está seguro?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario confirma la eliminación, proceder con la eliminación
      if (result.isConfirmed) {
        this.deleteAgreement(agreement);
      }
    },
    async deleteAgreement(agreement) {
      const agreementId = agreement.agreementId;
      const agreementRef = ref(database, `agreements/${agreementId}`);

      try {
        // Eliminar usuario de la base de datos
        await remove(agreementRef);

        // Eliminar usuario de la lista de users
        this.agreements = this.agreements.filter((agreement) => agreement.agreementId !== agreementId);

        // Mostrar un mensaje de éxito
        Swal.fire({
          title: "¡Convenio eliminado!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log("Convenio eliminado correctamente.");
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Hubo un problema al eliminar el convenio.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error al eliminar el convenio:", error);
      }
    },
    num_pages() {
      return Math.ceil(this.agreements.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.font-tasks {
  color: #676766;
  cursor: pointer;
}
.font-documents {
  color: #630404;
  cursor: pointer;
}
.font-warning {
  cursor: pointer;
}
.font-danger {
  cursor: pointer;
}
</style>