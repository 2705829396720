<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-7 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos Generales</h4>
        </div>
        <div class="card-header">
          <div class="row mb-4">
            <div class="col-md-4 offset-md-4">
              <div class="img-container">
                <img 
                  class="img-logo"
                  v-if="formData.urlImage" 
                  :src="formData.urlImage" 
                  alt="Imagen seleccionada" 
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Logo</h6>
                <input
                  class="form-control"
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  @change="fileUpload($event)"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Correo Electrónico</h6>
                <input
                  class="form-control"
                  type="email"
                  v-model="formData.email"
                  placeholder="Ingrese un correo electrónico"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Teléfono</h6>
                <input
                  class="form-control"
                  type="number"
                  v-model="formData.phone"
                  placeholder="No. de teléfono"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Enlace</h6>
                <input
                  class="form-control"
                  type="link"
                  v-model="formData.link"
                  placeholder="Ingresa un enlace"
                />
              </div>
            </div>
            <div class="col-md-14">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Dirección</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.address"
                  placeholder="Ingresa una dirección"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Presentación</h6>
                <input
                  class="form-control"
                  id="document-upload"
                  type="file"
                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  @change="documentUpload($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <button
            class="btn btn-primary"
            type="submit"
            @click.prevent="updateDataCompany"
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { database, storage } from "@/firebase/config";
import { ref, onValue, update} from "firebase/database";
import { ref as refStorage, uploadBytes, getDownloadURL} from "firebase/storage";
// import Swal from "sweetalert2";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      formData: {
        email: "",
        phone: "",
        link: "",
        address: "",
        nameImage: "",
        urlImage: "",
        namePresentation: "",
        urlPresentation: "",
      },
      formSubmitted: false,
      document: null,
      image: null,
    };
  },
  methods:{
    loadData() {
      const companyRef = ref(database, 'company');

      // Escuchar cambios en los datos del residente
      onValue(companyRef, (snapshot) => {
        const companyData = snapshot.val();
        if (companyData) {
          this.formData = companyData;
        } else {
          console.log("No se encontraron datos de la compañia.");
        }
      });
    },

    fileUpload(event) {
      const file = event.target.files[0];
      // Guardar el nombre de la imagen
      this.formData.nameImage = file.name;
      this.formData.urlImage = URL.createObjectURL(file);
      this.image = file;
    },

    documentUpload(event) {
      const file = event.target.files[0];
      // Guardar el nombre de la imagen
      this.formData.namePresentation = file.name;
      this.formData.urlPresentation = URL.createObjectURL(file);
      this.document = file;
    },

    async updateDataCompany() {
        const companyRef = ref(database, 'company');

        try {
          
          if (this.image) {
            const storageRef = refStorage(storage, `company/${this.image.name}`);
            await uploadBytes(storageRef, this.image);
            const url = await getDownloadURL(storageRef);
            this.formData.urlImage = url; // Actualizar la URL de la imagen en formData
            console.log("Imagen subida al almacenamiento.");
          }

          if (this.document) {
            const storageRef = refStorage(storage, `company/${this.document.name}`);
            await uploadBytes(storageRef, this.document);
            const url = await getDownloadURL(storageRef);
            this.formData.urlPresentation = url; // Actualizar la URL de la imagen en formData
            console.log("Documento subido al almacenamiento.");
          }

          // Actualizar los datos en la base de datos
          await update(companyRef, this.formData);

          toast.success("Se actualizaron los datos correctamente.");
          console.log("Datos actualizados correctamente.");
        } catch (error) {
          console.error("Error al actualizar los datos:", error);
        }
    },

  },
  mounted(){
    this.loadData()
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.img-logo {
  max-width: 100%;
  max-height: 100%;
}

.img-container {
  background-color: #eee; /* Color gris */
  border: 1px solid #ccc; /* Borde gris */
  padding: 10px; /* Espacio interior */
  text-align: center; /* Centrar contenido */
  border-radius: 5px;
  margin-bottom: 5px;
  height:  70px;
  margin-left: auto; /* Centrar horizontalmente */
  margin-right: auto; /* Centrar horizontalmente */
  overflow: hidden; /* Evita que la imagen se salga del contenedor */
}
</style>