<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Lista de documentos</h4>
      </div>
      <div class="card-header">
            <div class="col-md-8">
              <div class="mb-3 d-flex align-items-center">
                <input
                  class="form-control d-inline-block"
                  id="document-upload"
                  type="file"
                  accept=".pdf"
                  multiple
                  @change="documentUpload($event)"
                />
                <span 
                  class="btn btn-success d-inline-block ml-2 rounded" 
                  id="add-task"
                  @click.prevent="addDocuments"
                >Agregar</span
                >
              <div class="col-md-4 text-md-end ">
                  <button
                    class="btn btn-generate ml-auto mr-20" 
                    type="submit"
                    @click.prevent="redirect"
                  >
                    Generar Documento
                  </button>
              </div>
              </div>
            </div>
        <div class="table-responsive theme-scrollbar data-table">
          <form>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col" sortable="nameTest">Nombre del archivo</th>
                  <th scope="col" sortable="time">Fecha</th>
                  <th scope="col" sortable="method">Hora</th>
                  <th scope="col" sortable="league">Documento</th>
                  <th scope="col">Accciones</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="row in get_rows" :key="row.id">
                  <td>{{ row.nameDocument }}</td>
                  <td>{{ row.date }}</td>
                  <td>{{ row.time }}</td>
                  <td>
                    <span>
                      <span @click="openPDF(row.url)">
                        <i class="icofont icofont-file-pdf icofont-2x font-documents ps-2"></i>
                      </span>
                    </span>
                  </td>
                  <td>
                      <span @click="deleteConfirmation(row)">
                        <i
                          class="icofont icofont-ui-delete icofont-2x font-danger ps-2"
                        ></i>
                      </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="pagination m-3 justify-content-end pagination-primary">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous"
                  ><span aria-hidden="true">&laquo;</span></a
                >
              </li>
              <li
                class="page-item"
                v-for="i in num_pages()"
                :key="i"
                v-bind:class="[i == currentPage ? 'active' : '']"
                v-on:click="change_page(i)"
              >
                <a class="page-link">{{ i }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next"
                  ><span aria-hidden="true">&raquo;</span></a
                >
              </li>
            </ul>
          </form>
        </div>
        <div class="card-footer">
          <div class="float-start">
            <button class="btn btn-secondary"
                @click="$router.push('/residentes/administrar')"
            >Regresar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { database, storage} from "@/firebase/config";
import { ref, update, set, push, onValue} from "firebase/database";
import { ref as refStorage,uploadBytes, getDownloadURL, deleteObject} from "firebase/storage";
import {mapGetters} from 'vuex';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import Swal from "sweetalert2";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      documents: [],
      elementsPerPage: 10,
      currentPage: 1,
      ascending: false,
      sortColumn: "",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterQuery: "",
      filesToUpload: [],
    };
  },
  computed:{
    ...mapGetters(['getSelectedResident']),

    columns() {
      if (this.documents.length === 0) {
        return [];
      }
      return Object.keys(this.documents[0]);
    },
    get_rows() {
      const start = (this.currentPage - 1) * this.elementsPerPage;
      const end = start + this.elementsPerPage;
      return this.documents.slice(start, end);
    },
  },
  methods: {
    openPDF(url) {
      window.open(url, '_blank');
    },
    documentUpload(event) {
      this.filesToUpload = event.target.files;
      console.log("Documentos seleccionados:", this.filesToUpload);
    },
    redirect() {
      this.$store.dispatch("updateSelectedResident", this.getSelectedResident)
      this.$router.push('/residentes/generar-documentos');
    },
    async addDocuments() {
      try {
        if (!this.filesToUpload || this.filesToUpload.length === 0) {
          // Mostrar una alerta si no hay documentos seleccionados
          toast.error("Selecciona al menos un documento para subir");
          return; // Salir de la función sin hacer nada más
        }

        const loadingToast = toast.loading("Subiendo archivos...");
        // 1. Obtener una referencia a la colección 'documents'
        const documentsRef = ref(database, `residents/${this.getSelectedResident.idResident}/documents`);

        // Inicializar un objeto para las actualizaciones
        const updates = {};

        // 2. Subir cada documento a Firebase Storage y actualizar la URL
        for (let i = 0; i < this.filesToUpload.length; i++) {
          const file = this.filesToUpload[i];

          // Guardar la referencia del documento en Firebase
          const documentRef = await push(ref(database, 'documents'));
          const documentId = documentRef.key;

          const storageRef = refStorage(storage, `residents/${this.getSelectedResident.idResident}/${documentId}/${file.name}`);
          await uploadBytes(storageRef, file, { contentType: file.type });
          const url = await getDownloadURL(storageRef);

          const formattedDate = format(new Date(), 'dd/MM/yy', { locale: es });
          const formattedTime = format(new Date(), 'HH:mm:ss', { locale: es, timeZone: 'America/Mexico_City' });

          // Añadir el nuevo documento a las actualizaciones
          updates[documentId] = {
            idDocument: documentId,
            nameDocument: file.name,
            date: formattedDate,
            time: formattedTime,
            url: url
          };
        }

        // Actualizar la base de datos con los nuevos documentos
        await update(documentsRef, updates);

        // Ocultar el toast de carga
        toast.remove(loadingToast);
        this.filesToUpload = [];
        // Mostrar un mensaje de éxito
        toast.success("Documentos subidos exitosamente");
        console.log("Datos actualizados correctamente.");
      } catch (error) {
        console.error("Error al actualizar los datos:", error);
        toast.error("Error al subir los documentos: " + error.message);
      }
    },
    deleteConfirmation(document) {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
            // Si el usuario confirma la eliminación, procede con la eliminación
            this.deleteDocument(document);
            }
        });
    },
    loadData() {
      const testRef = ref(database, `residents/${this.getSelectedResident.idResident}/documents`);
      onValue(testRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          this.documents = Object.keys(data).map((idResident) => ({
            idResident: idResident,
            ...data[idResident],
          }));
        } else {
          this.documents = [];
        }
      });
    },
    async deleteDocument(document) {
        try {
            const documentRef = ref(database, `residents/${this.getSelectedResident.idResident}/documents/${document.idDocument}`);

            // Eliminar el archivo del almacenamiento
            const storageRef = refStorage(storage, `residents/${this.getSelectedResident.idResident}/${document.idDocument}/${document.nameDocument}`);
            await deleteObject(storageRef);

            // Elimina el documento de la base de datos
            await set(documentRef, null);

            // Muestra un mensaje de éxito
            toast.success("Documento eliminado exitosamente");
        } catch (error) {
            console.error("Error al eliminar el documento:", error);
            toast.error("Error al eliminar el documento");
        }
    },
    num_pages() {
      return Math.ceil(this.documents.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.loadData();
  },
}
</script>
<style scoped>
.font-documents {
  color: #630404;
  cursor: pointer;
}
.rounded {
  border-top-right-radius: 2px;
  height: 35px;
  padding-top: 7px;
}
.btn-generate {
  background-color: rgb(252, 228, 123);
  margin-right: 3%;
  margin-bottom: 2%;
}

.btn-generate:hover { /* Aquí aplicamos estilos cuando el mouse está sobre el botón */
  background-color: rgb(252, 228, 123);; /* Mantenemos el mismo color al pasar el mouse */
}
</style>