<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-7 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos Generales</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Nombre de prueba</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.nameTest"
                  :class="{ 'is-invalid':formSubmitted && !formData.nameTest }"
                  required
                  placeholder="Nombre de la Prueba"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.nameTest">
                  Por favor, introduce el nombre de la prueba.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Tiempo</h6>
                <VueDatePicker
                  v-model="formData.time"
                  :class="{ 'is-invalid':formSubmitted && !formData.time }"
                  time-picker
                  fotmat="HH:mm"
                  model-type="HH:mm"
                  cancel-text="Cancelar"
                  select-text="Seleccionar"
                  placeholder="Tiempo"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.time">
                  Por favor, introduce el tiempo de duración.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Medio de aplicación</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.method"
                  :class="{ 'is-invalid':formSubmitted && !formData.method }"
                  required
                  placeholder="Medio de aplicación"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.method">
                  Por favor, introduce el medio de aplicación.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Liga</h6>
                <input
                  class="form-control"
                  type="link"
                  v-model="formData.league"
                  placeholder="https://www.ejemplo.com"
                />
              </div>
            </div>
            <div class="col-md-10">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Documentos</h6>
                <input
                  class="form-control"
                  id="document-upload"
                  type="file"
                  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
                  multiple
                  @change="documentUpload($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-start">
            <button class="btn btn-secondary"
                @click="$router.push('/bateria-pruebas')"
            >Regresar</button>
          </div>
          <div class="float-end">
             <button
                class="btn btn-primary"
                type="submit"
                @click.prevent="validateAndSubmitForm"
            >
                Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { database, storage } from "@/firebase/config";
import { ref,set,push} from "firebase/database";
import { ref as refStorage, uploadBytes, getDownloadURL} from "firebase/storage";
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import Swal from "sweetalert2";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      formData: {
        idTest: "",
        nameTest: "",
        time: "",
        method: "",
        league: "",
        documents: {},
      },
      filesToUpload: [],
      namedocument: "",
      urlPresentation: "",
      formSubmitted: false,
      document: null,
      image: null,
    };
  },
  methods:{
    validateForm() {
      // Validando campos necesarios del formulario
        if (!this.formData.nameTest || !this.formData.time || !this.formData.method) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },

     validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.addTest();
      }
    },

    documentUpload(event) {
      this.filesToUpload = event.target.files;
      console.log("Documentos seleccionados:", this.filesToUpload);
    },

    async addTest() {
        try {
          
            if (!this.filesToUpload || this.filesToUpload.length === 0) {
              // Mostrar una alerta si no hay documentos seleccionados
              toast.error("Selecciona al menos un documento para subir");
              return; // Salir de la función sin hacer nada más
            }

            const loadingToast = toast.loading("Subiendo información y archivos...");
            // 1. Obtener una referencia a la colección 'testBattery'
            const testRef = ref(database, 'testBattery');

            const newTestRef = push(testRef);
            this.formData.idTest = newTestRef.key;

            // 2. Subir cada documento a Firebase Storage y actualizar la URL
            for (let i = 0; i < this.filesToUpload.length; i++) {
                    const file = this.filesToUpload[i];
                    const storageRef = refStorage(storage, `test/${this.formData.nameTest}/${file.name}`);
                    await uploadBytes(storageRef, file, { contentType: file.type });
                    const url = await getDownloadURL(storageRef);
                    
                    // Guardar la referencia del documento en Firestore
                    const documentRef = await push(ref(database, 'documents'));
                    const documentId = documentRef.key;
                    const formattedDate = format(new Date(), 'dd/MM/yy', { locale: es });
                    const formattedTime = format(new Date(), 'HH:mm:ss', { locale: es, timeZone: 'America/Mexico_City' });

                    this.formData.documents[documentId]=
                      { 
                          id: documentId, 
                          name: file.name, 
                          date: formattedDate,
                          type: file.type,
                          time: formattedTime,
                          url: url 
                      };
            }


            await set(newTestRef, this.formData);
            // Ocultar el toast de carga
            this.filesToUpload = [];
            toast.remove(loadingToast);
            // Mostrar un mensaje de éxito
            await Swal.fire({
            icon: "success",
            title: "La prueba se ha registrado correctamente",
            });
            console.log("Datos actualizados correctamente.");
            this.$router.push('/bateria-pruebas')
        } catch (error) {
            console.error("Error al actualizar los datos:", error);
            Swal.fire({
            icon: "error",
            title: "Error al registrar la prueba",
            //text: error.message, // Muestra el mensaje de error retornado por Firebase
            });
        }
    },

  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.img-logo {
  max-width: 100%;
  max-height: 100%;
}

.img-container {
  background-color: #eee; /* Color gris */
  border: 1px solid #ccc; /* Borde gris */
  padding: 10px; /* Espacio interior */
  text-align: center; /* Centrar contenido */
  border-radius: 5px;
  margin-bottom: 5px;
  height:  70px;
  margin-left: auto; /* Centrar horizontalmente */
  margin-right: auto; /* Centrar horizontalmente */
  overflow: hidden; /* Evita que la imagen se salga del contenedor */
}
</style>