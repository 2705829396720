<template>
     <Breadcrumbs main="Batería de Pruebas" title="Pruebas Aplicadas"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <TestAppliedPage/>
              </div>
            </div>
     </div>
</template>
<script>
import TestAppliedPage from "@/components/testBattery/appliedTest.vue"
export default {
    components:{
        TestAppliedPage
    }
}
</script>