<template>
    <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 p-0 footer-left">
              <p class="mb-0">Copyright By @DevITM 2019 - 2024</p>
            </div>
            <div class="col-md-6 p-0 footer-right">
              <p class="mb-0">DevITM <i class="fa fa-code desing"></i></p>
            </div>
          </div>
        </div>
      </footer>
</template>

<style scoped>
.design{
    color: #eb7200;
    font-size: 53px;
}
</style>