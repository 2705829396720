<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-7 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Horario</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Horario</h6>
                <VueDatePicker
                  v-model="timeRange"
                  time-picker
                  :range="{ disableTimeRangeValidation: true }"
                  fotmat="HH:mm"
                  model-type="HH:mm"
                  cancel-text="Cancelar"
                  select-text="Seleccionar"
                  placeholder="Horario"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Total de horas</h6>
                <input
                  class="form-control"
                  type="number"
                  v-model="residentData.timetable.totalHours"
                  placeholder="Total de horas"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Fecha de inicio</h6>
                <VueDatePicker
                  v-model="residentData.timetable.startDate"
                  :enable-time-picker="false"
                  locale="es"
                  model-type="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  cancel-text="Cancelar"
                  select-text="Seleccionar"
                  placeholder="Dia/mes/año"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Fecha de término</h6>
                <VueDatePicker
                  v-model="residentData.timetable.endDate"
                  :enable-time-picker="false"
                  locale="es"
                  model-type="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  cancel-text="Cancelar"
                  select-text="Seleccionar"
                  placeholder="Aproximada"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Días Hábiles</h6>
                 <multiselect 
                  v-model="residentData.timetable.businessDays" 
                  :options="options" 
                  label="name" 
                  track-by="code"
                  placeholder="Seleccione los días hábiles" 
                  :selectLabel="'Haz clic para seleccionar'"
                  :deselectLabel="'Haz clic para deseleccionar'"
                  :searchable="false"
                  multiple
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-start">
            <button class="btn btn-secondary"
                @click="$router.push('/residentes/administrar')"
            >Regresar</button>
          </div>
          <div class="float-end">
            <button
              class="btn btn-primary"
              type="submit"
              @click.prevent="updateTimetable"
            >
              Guardar Cambios
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { database } from "@/firebase/config";
import { ref, update } from "firebase/database";
import {mapGetters} from 'vuex';
import Swal from "sweetalert2";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      residentData: {
        timetable: {
          hours: "",
          totalHours: "",
          startDate: "",
          endDate: "",
          businessDays:null,
        },
      },
      timeRange: [],
      editForm: false,
      options: [
        { name: 'Lunes', code: 'Lunes' },
        { name: 'Martes', code: 'Martes' },
        { name: 'Miercoles', code: 'Miercoles' },
        { name: 'Jueves', code: 'Jueves' },
        { name: 'Viernes', code: 'Viernes' },
      ],
       highlightedOption: null
    };
  },
  watch: {
    getSelectedResident:{
      immediate: true,
      handler(newValue){
        if (newValue){
          this.residentData = {
            timetable: {
              hours: newValue.timetable.hours,
              totalHours: newValue.timetable.totalHours,
              startDate: newValue.timetable.startDate,
              endDate: newValue.timetable.endDate,
              businessDays: newValue.timetable.businessDays,
            }
          }
          
        }
      }
    },

    timeRange(newTimeRange) {
      // Convierte el array de horas en una sola cadena de texto separada por un guión
      this.residentData.timetable.hours = newTimeRange.join(' - ');
    },
  },
  computed: {
    ...mapGetters(['getSelectedResident']),
  },
  methods: {
    highlightOption(option) {
      this.highlightedOption = option;
    },
    resetHighlight() {
      this.highlightedOption = null;
    },
    convertTimeRange() {
      // Verificar si hay un rango de horas definido en residentData.timetable.hours
      if (this.residentData.timetable.hours) {
        // Dividir la cadena de horas en un array utilizando el guión como separador
        const hoursArray = this.residentData.timetable.hours.split(' - ');

        // Actualizar el timeRange con el nuevo array de horas
        this.timeRange = hoursArray;
      }
    },
    async updateTimetable() {
      const confirmation = await Swal.fire({
        title: "¿Guardar cambios?",
        text: "¿Está seguro de que desea guardar los cambios?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
      });

      if (confirmation.isConfirmed) {
        const residentRef = ref(
          database,
          `residents/${this.getSelectedResident.idResident}/timetable`
        );
        try {
          await update(residentRef, this.residentData.timetable);
          toast.success("Datos actualizados correctamente");
          // Esperar unos segundos antes de redireccionar
          await new Promise(resolve => setTimeout(resolve, 3000)); // Espera 3 segundos
          this.$router.push("/residentes/administrar");
        } catch (error) {
          console.error(error);
          // Muestra un mensaje de error
          Swal.fire({
            icon: "error",
            title: "Error al guardar los cambios",
            text: "Hubo un problema al guardar los cambios. Por favor, inténtelo de nuevo más tarde.",
          });
        }
      }
    },
  },
  mounted(){
    this.convertTimeRange();
  }
};
</script>

<style scoped>
.swal2-container {
  z-index: 10000;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.floating-form {
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  z-index: 999;
}

.btn-circle {
  border-radius: 50%; /* Establece el radio para que el botón sea circular */
  width: 30px; /* Ajusta el ancho del botón según tus preferencias */
  height: 30px; /* Ajusta la altura del botón según tus preferencias */
  padding: 0; /* Elimina el relleno para que el botón tenga un tamaño fijo */
}

.btn-circle i {
  font-size: 14px; /* Ajusta el tamaño del icono según tus preferencias */
  line-height: 30px; /* Centra verticalmente el icono dentro del botón */
}
</style>