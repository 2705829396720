<template>
     <Breadcrumbs main="Capacitación" title="Presentación"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <TrainingPage/>
              </div>
            </div>
     </div>
</template>
<script>
import TrainingPage from "@/components/training/trainingInfo.vue"
export default {
    components:{
        TrainingPage
    }
}
</script>