<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-8 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos generales del convenio</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Nombre de la escuela</h6>
                <Select2
                  v-model="formData.idSchool"
                  :options="schools"
                  placeholder="Selecciona una escuela"
                  label="text"
                  track-by="id"
                  :class="{ 'is-invalid': formSubmitted && !formData.idSchool }"
                  required
                  :noResult="'No se encontraron opciones disponibles'"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.idSchool">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Estado del convenio</h6>
                <select
                  class="form-select digits"
                  v-model="formData.stateA"
                  :class="{ 'is-invalid': formSubmitted && !formData.stateA }"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Activo">Activo</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="Prospecto">Prospecto</option>
                  <option value="Caduco">Caduco</option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.stateA">
                  Por favor, introduce el estado.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Fecha de último contacto</h6>
                <VueDatePicker 
                  v-model="formData.lastContactDate" 
                  :enable-time-picker="false"  
                  locale="es"
                  model-type="dd/MM/yyyy"
                  cancel-text="Cancelar" 
                  select-text="Seleccionar"
                  placeholder="Dia/mes/año"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.lastContactDate">
                  Por favor, introduce la fecha de último contacto.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Fecha de termino de convenio</h6>
                <VueDatePicker 
                  v-model="formData.endDate" 
                  :enable-time-picker="false"
                  locale="es"
                  model-type="dd/MM/yyyy"
                  cancel-text="Cancelar" 
                  select-text="Seleccionar"
                  placeholder="En caso de existir"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Notas</h6>
                <textarea
                  class="form-control"
                  type="text"
                  v-model="formData.note"
                  placeholder="Escribe alguna nota"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
            <div class="float-start">
              <button class="btn btn-secondary"
                @click="$router.push('/escuelas/convenios')"
              >Regresar</button>
            </div>
            <div class="float-end">
                        <button
            class="btn btn-primary"
            @click.prevent="validateAndSubmitForm"
          >
            Agregar
          </button>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import { database } from "@/firebase/config";
import { ref, set, get, push} from "firebase/database";
import estados from "@/data/estados.js";
import Swal from "sweetalert2";
import { es } from 'date-fns/locale';
//import { toast } from 'vue3-toastify'

export default {
  data() {
    return {
      formData: {
        idSchool: "",
        lastContactDate: "",
        stateA: "",
        endDate: "",
        note: "",
        activities: {},
        documents: {},
      },
      schools: [],
      states: estados,
      locale: es,
      formSubmitted: false,
    };
  },
  methods: {

    validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.submitForm();
      }
    },

    validateForm() {
      // Validando campos necesarios del formulario
        if (!this.formData.idSchool || !this.formData.stateA || !this.formData.lastContactDate) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },
    cleanInputs() {
      this.formData = {};
    },
    async submitForm() {
      try {
        this.formData.endDate = this.formData.endDate.toString();
        this.formData.lastContactDate = this.formData.lastContactDate.toString();
        console.log(this.formData.lastContactDate,this.formData.endDate);

        // Crear un nodo separado para los convenios
        const convenioRef = ref(database, "agreements");
        const newConvenioRef = push(convenioRef);
        await set(newConvenioRef, this.formData);

        // Mostrar un mensaje de éxito
        await Swal.fire({
          icon: "success",
          title: "Convenio registrado exitosamente",
        });

        // Limpiar el formulario y redirigir
        this.cleanInputs();
        this.formSubmitted = false;
        this.$router.push("/escuelas/convenios");

        console.log("Convenio registrado:", this.formData.name);
      } catch (error) {
        // Manejar errores
        console.error("Error al registrar el convenio:", error);
        Swal.fire({
          icon: "error",
          title: "Error al registrar el convenio",
          //text: error.message, // Muestra el mensaje de error retornado por Firebase
        });
      }
    },
  },
mounted() {
  // Consulta el nodo "schools" en la base de datos
  const schoolsRef = ref(database, "schools");
  console.log(schoolsRef);
  get(schoolsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        // Itera sobre los datos de las escuelas
        snapshot.forEach((childSnapshot) => {
          const schoolData = childSnapshot.val();
          const schoolId = childSnapshot.key;
          const schoolName = schoolData.name;
          // Agrega el nombre de la escuela y su ID al array
          this.schools.push({ id: schoolId.toString(), text: schoolName });
        });
        console.log(this.schools);
      } else {
        console.log("No existen datos de escuelas");
      }
    })
    .catch((error) => {
      console.error("Error al obtener datos de Firebase: ", error);
    });
},
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}
</style>