<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-8 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos Generales</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Nombre</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="this.residentData.name"
                  disabled
                  placeholder="Nombre"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
                            <div class="mb-3">
                              <label class="form-label f-w-500" >Nombre de la escuela</label>
                              <Select2
                                v-model="residentData.idSchool"
                                :options="schools"
                                placeholder="Selecciona una escuela"
                                label="text"
                                track-by="id"
                                :class="{'is-invalid': formSubmitted && !residentData.idSchool}"
                                required
                                :noResult="'No se encontraron opciones disponibles'"
                              />
                              <div class="invalid-feedback" v-if="formSubmitted && !residentData.idSchool">
                                Por favor, introduce el nombre de la escuela.
                             </div>
            </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Licenciatura</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="residentData.degree"
                  :class="{
                    'is-invalid': formSubmitted && !residentData.degree,
                  }"
                  required
                  placeholder="Nombre"
                />
                <div
                  class="invalid-feedback"
                  v-if="formSubmitted && !residentData.degree"
                >
                  Por favor, introduce el nombre de la licenciatura.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Matrícula o No.Control</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="residentData.enrollment"
                  :class="{
                    'is-invalid': formSubmitted && !residentData.enrollment,
                  }"
                  required
                  placeholder="Matrícula o No.Control"
                />
                <div
                  class="invalid-feedback"
                  v-if="formSubmitted && !residentData.enrollment"
                >
                  Por favor, introduce el nombre de la licenciatura.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Correo Personal</h6>
                <input
                  class="form-control"
                  type="email"
                  v-model="residentData.emailPersonal"
                  placeholder="Correo Personal"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Anotaciones</h6>
                <textarea
                  class="form-control"
                  type="url"
                  v-model="residentData.notes"
                  placeholder="Escribe aquí tus anotaciones..."
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-start">
            <button class="btn btn-secondary"
                @click="$router.push('/residentes/administrar')"
            >Regresar</button>
          </div>
          <div class="float-end"> 
            <button
              class="btn btn-primary"
              type="submit"
              @click.prevent="validateAndSubmitForm"
            >
              Guardar Cambios
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { database } from "@/firebase/config";
import { ref, get, update } from "firebase/database";
import estados from "@/data/estados.js";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      residentData: {
        idSchool: "",
        name: "",
        nameSchool: "",
        degree: "",
        enrollment: "",
        emailPersonal: "",
        notes: "",
      },
      schools:[],
      states: estados,
      formSubmitted: false,
    };
  },
  watch: {
    getSelectedResident: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.residentData = {
            idSchool: newValue.idSchool,
            name: newValue.name,
            nameSchool: newValue.nameSchool,
            degree: newValue.degree,
            enrollment: newValue.enrollment,
            emailPersonal: newValue.emailPersonal,
            notes: newValue.notes,
          };
        }
      },
    },
  },
  computed: {
    ...mapGetters(["getSelectedResident"]),
  },
  mounted() {
    this.getSchools();
  },
  methods: {
    getSchools() {
      // Consulta el nodo "schools" en la base de datos
      const schoolsRef = ref(database, "schools");
      console.log(schoolsRef);
      get(schoolsRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            // Itera sobre los datos de las escuelas
            snapshot.forEach((childSnapshot) => {
              const schoolData = childSnapshot.val();
              const schoolId = childSnapshot.key;
              const schoolName = schoolData.name;
              // Agrega el nombre de la escuela y su ID al array
              this.schools.push({ id: schoolId.toString(), text: schoolName });
            });
            console.log(this.schools);
          } else {
            console.log("No existen datos de escuelas");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos de Firebase: ", error);
        });
    },

    isEmailValid() {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.formData.email);
    },

    validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.updateResident();
      }
    },

    validateForm() {
      // Validando campos necesarios del formulario
      if (
        !this.residentData.idSchool ||
        !this.residentData.degree ||
        !this.residentData.enrollment
      ) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },
    cleanInputs() {
      this.formData = {};
    },
    async updateResident() {
      const confirmation = await Swal.fire({
        title: "¿Guardar cambios?",
        text: "¿Está seguro de que desea guardar los cambios?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
      });

      if (confirmation.isConfirmed) {
        const residentRef = ref(database, `residents/${this.getSelectedResident.idResident}`);
        const schoolSelected = this.schools.find(
          (school) => school.id === this.residentData.idSchool
        );
        this.residentData.nameSchool = schoolSelected.text;
        try {
          await update(residentRef, this.residentData);
          toast.success("Datos actualizados correctamente");

          // Esperar unos segundos antes de redireccionar
          await new Promise(resolve => setTimeout(resolve, 3000)); // Espera 3 segundos

          this.$router.push('/residentes/administrar');
        } catch (error) {
          console.error(error);
          // Muestra un mensaje de error
          Swal.fire({
            icon: "error",
            title: "Error al guardar los cambios",
            text: "Hubo un problema al guardar los cambios. Por favor, inténtelo de nuevo más tarde.",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.swal2-container {
  z-index: 10000;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.floating-form {
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  z-index: 999;
}

.btn-circle {
  border-radius: 50%; /* Establece el radio para que el botón sea circular */
  width: 30px; /* Ajusta el ancho del botón según tus preferencias */
  height: 30px; /* Ajusta la altura del botón según tus preferencias */
  padding: 0; /* Elimina el relleno para que el botón tenga un tamaño fijo */
}

.btn-circle i {
  font-size: 14px; /* Ajusta el tamaño del icono según tus preferencias */
  line-height: 30px; /* Centra verticalmente el icono dentro del botón */
}
</style>