<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-7 col-lg-7">
      <div>
        <form class="card" @submit.prevent="updateUser">
          <div class="card-header pb-0">
            <h4 class="card-title mb-0">Editar Datos Personales</h4>
          </div>
          <div class="card-header">
            <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500 ">Nombre</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="formData.name"
                  :class="{ 'is-invalid':formSubmitted && !formData.name }"
                  required
                  placeholder="Nombre"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.name">
                  Por favor, introduce tu nombre.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Rol del usuario</h6>
                <select
                  class="form-select digits"
                  v-model="formData.typeUser"
                  :class="{ 'is-invalid': formSubmitted && !formData.typeUser }"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Administrador">Administrador</option>
                  <option value="Recursos Humanos">Recursos Humanos</option>
                  <option value="Residente">Residente</option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.typeUser">
                  Por favor, introduce el tipo de usuario.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <h6 class="form-label f-w-500">Estado</h6>
                  <select
                    class="form-select digits"
                    v-model="formData.status"
                    :class="{ 'is-invalid': formSubmitted && !formData.status }"
                  >
                    <option value="" disabled selected>
                      Selecciona una opción
                    </option>
                    <option value="Activo">Activo</option>
                    <option value="Inactivo">Inactivo</option>
                  </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.status">
                  Por favor, introduce el estado del usuario.
                </div>
                </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Departamento</h6>
                <select
                  class="form-select digits"
                  v-model="formData.department"
                  :class="{ 'is-invalid': formSubmitted && !formData.department }"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Sistemas Computacionales">Sistemas Computacionales</option>
                  <option value="Diseño">Diseño</option>
                  <option value="Mercadotecnia">Mercadotecnia</option>
                  <option value="RRHH">RRHH</option>
                </select>
                <div class="invalid-feedback" v-if="formSubmitted && !formData.department">
                  Por favor, introduce el departamento del usuario
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Teléfono</h6>
                <input
                  class="form-control"
                  type="number"
                  v-model="formData.phone"
                  placeholder="Teléfono"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Correo Electrónico</h6>
                <input
                  class="form-control"
                  type="email"
                  v-model="formData.email"
                  :class="{ 'is-invalid':formSubmitted && !isEmailValid() }"
                  required
                  placeholder="Correo Electrónico"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !isEmailValid()">
                  Por favor, introduce un correo electrónico válido.
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-start">
              <button class="btn btn-secondary"
                  @click="$router.push('/usuarios/administrar')"
              >Regresar</button>
            </div>
            <div class="float-end">
              <button
                class="btn btn-primary"
                type="submit"
                @click.prevent="validateAndUpdateUser"
              >
                Guardar Cambios
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { toast } from 'vue3-toastify'
import { database } from "@/firebase/config";
import { ref, update } from "firebase/database";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      formData: {
        idUser: "",
        name: "",
        typeUser: "",
        department: "",
        phone: null,
        status: "",
        email: "",
      },
      formSubmitted: false,
    };
  },
  watch: {
    getSelectedUser:{
      immediate: true,
        handler(newValue){
          if (newValue){
            this.formData = {
              idUser: newValue.idUser,
              name: newValue.name,
              typeUser: newValue.typeUser,
              department: newValue.department,
              phone: newValue.phone,
              status: newValue.status,
              email: newValue.email,
            }
          }
        }
    }
  },
  methods: {
    isEmailValid() {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.formData.email);
    },

    validateAndUpdateUser() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.updateUser();
      }
    },

    validateForm() {
      // Recorre todos los campos en formData
      for (const key in this.formData) {
        // Si el campo es lastNameM y está vacío, omite la validación
        if (key === "phone" || key === "idUser") {
          continue;
        }
        // Verifica si algún otro campo está vacío
        if (!this.formData[key]) {
          return false; // Si algún campo está vacío, retorna false
        }
      }
      // Verificar si el correo electrónico es válido
      if (!this.isEmailValid()) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },

    async updateUser() {
      const confirmation = await Swal.fire({
        title: "¿Guardar cambios?",
        text: "¿Está seguro de que desea guardar los cambios?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
      });

      if (confirmation.isConfirmed) {
        const userId = this.formData.idUser;
        const userRef = ref(database, `users/${userId}`);
        try {
          await update(userRef, this.formData);
          toast.success('Datos actualizados correctamente');
           // Esperar unos segundos antes de redireccionar
          await new Promise(resolve => setTimeout(resolve, 1500)); // Espera 1.5 segundo
          this.$router.push('/usuarios/administrar');
        } catch (error) {
          console.error(error);
          // Muestra un mensaje de error
          Swal.fire({
            icon: "error",
            title: "Error al guardar los cambios",
            text: "Hubo un problema al guardar los cambios. Por favor, inténtelo de nuevo más tarde.",
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getSelectedUser"]),
  },
};
</script>