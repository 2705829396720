<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Información de Usuarios</h4>
      </div>
      <div class="card-header">
        <div class="mb-3 row justify-content-start">
              <h6 for="search-type" class="col-xs-3 col-sm-auto col-form-label">Tipo de Búsqueda:</h6>
              <div class="col-xs-4 col-sm-2">
                <select v-model="searchType" id="search-type" class="form-select">
                  <option value="name">Nombre</option>
                  <option value="typeUser">Rol del usuario</option>
                  <option value="status">Estado</option>
                </select>
              </div>
            </div>
            <div v-if="searchType === 'typeUser'" class="mb-3 row justify-content-start">
              <h6 for="user-search" class="col-xs-3 col-sm-auto col-form-label">Buscar:</h6>
              <div class="col-xs-3 col-md-3">
                <select v-model="filterQuery" id="user-search" class="form-select">
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Administrador">Administrador</option>
                  <option value="Recursos Humanos">Recursos Humanos</option>
                  <option value="Residente">Residente</option>
                </select>
              </div>
            </div>
            <div v-else-if="searchType === 'status'" class="mb-3 row justify-content-start">
              <h6 for="status-search" class="col-xs-3 col-sm-auto col-form-label">Buscar:</h6>
              <div class="col-xs-3 col-md-3">
                <select v-model="filterQuery" id="status-search" class="form-select">
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Activo">Activo</option>
                  <option value="Inactivo">Inactivo</option>
                </select>
              </div>
            </div>
            <div v-else class="mb-3 row justify-content-start">
              <h6
                for="table-complete-search"
                class="col-xs-3 col-sm-auto col-form-label"
                >Buscar:</h6
              >
              <div class="col-xs-3 col-md-3">
                <input
                  id="table-complete-search"
                  type="text"
                  class="form-control"
                  placeholder="Escribe para buscar..."
                  v-model="filterQuery"
                />
              </div>
            </div>
        <div class="table-responsive theme-scrollbar data-table">
          <form>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col" sortable="name">Nombre</th>
                  <th scope="col" sortable="typeUser">Rol del usuario</th>
                  <th scope="col" sortable="status">Estado</th>
                  <th scope="col" sortable="department">Departamento</th>
                  <th scope="col" sortable="phone">Teléfono</th>
                  <th scope="col" sortable="email">Correo Electrónico</th>
                  <th scope="col">Accciones</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="row in get_rows" :key="row.id">
                  <td>{{ row.name }}</td>
                  <td>{{ row.typeUser }}</td>
                  <td :style="{ 
                    color: row.status === 'Activo' ? 'green' : 'red', 
                    fontWeight: 'bold' 
                  }">{{ row.status }}</td>
                  <td>{{ row.department }}</td>
                  <td>{{ row.phone }}</td>
                  <td>{{ row.email }}</td>
                  <td>
                    <span>
                      <span class="cursor-pointer	" @click="redirectUpdate(row)">
                        <i
                          class="icofont icofont-edit icofont-2x font-warning ps-2"
                        ></i>
                      </span>
                      <span v-if="userData.typeUser === 'Administrador'" class="cursor-pointer" @click="deleteUserConfirmation(row)">
                        <i
                          class="icofont icofont-ui-delete icofont-2x font-danger ps-2"
                        ></i>
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="pagination m-3 justify-content-end pagination-primary">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous"
                  ><span aria-hidden="true">&laquo;</span></a
                >
              </li>
              <li
                class="page-item"
                v-for="i in num_pages()"
                :key="i"
                v-bind:class="[i == currentPage ? 'active' : '']"
                v-on:click="change_page(i)"
              >
                <a class="page-link">{{ i }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next"
                  ><span aria-hidden="true">&raquo;</span></a
                >
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { database, auth } from "@/firebase/config";
import { ref, onValue, remove} from "firebase/database";
import { deleteUser as deleteUserAuth } from "firebase/auth";
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      users: [],
      originalUsers: [], 
      searchType: "name",
      elementsPerPage: 10,
      currentPage: 1,
      ascending: false,
      sortColumn: "",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterQuery: "",
    };
  },
  watch: {
    searchType(newSearchType) {
      console.log("Nuevo tipo de búsqueda:", newSearchType); // Agregamos un mensaje de registro
      // Al cambiar el tipo de búsqueda, restablecer filterQuery a ''
      this.filterQuery = '';
    },
    filterQuery(search) {
      var tableItems = [...this.originalUsers]; // Usar la lista original para filtrar
      var filteredData = tableItems.filter((row) => {
        const field = row[this.searchType].toLowerCase(); // Convertir a minúsculas
        const query = search.toLowerCase(); // Convertir a minúsculas

        console.log("Campo:", field);
        console.log("Consulta:", query);

        // Verificar si el campo es 'status' y si la consulta coincide exactamente con el estado
        const result = this.searchType === 'status' ? field === query : field.includes(query);

        console.log("Resultado:", result);

        return result;
      });
      this.users = search === "" ? [...this.originalUsers] : filteredData;
    },
  },
  computed: {
    ...mapGetters(['userData']),
    columns() {
      if (this.users.length === 0) {
        return [];
      }
      return Object.keys(this.users[0]);
    },
    get_rows() {
      const start = (this.currentPage - 1) * this.elementsPerPage;
      const end = start + this.elementsPerPage;
      return this.users.slice(start, end);
    },
  },
  methods: {
    redirectUpdate(user) {
      this.$store.dispatch("updateSelectedUser", user);
      this.$router.push("/usuarios/actualizar");
    },
    loadData() {
      const usersRef = ref(database, "users");
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          this.originalUsers = Object.keys(data).map((userId) => ({
            id: userId,
            ...data[userId],
          }));
          this.users = [...this.originalUsers]; // Inicializar users con la lista original
        } else {
          this.users = [];
          this.originalUsers = [];
        }
      });
    },
    async deleteUserConfirmation(user) {
      // Mostrar el cuadro de diálogo de confirmación
      const result = await Swal.fire({
        title: "¿Está seguro?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario confirma la eliminación, proceder con la eliminación
      if (result.isConfirmed) {
        this.deleteUser(user);
      }
    },
    async deleteUser(user) {
      const userId = user.id;
      const userRef = ref(database, `users/${userId}`);

      try {
        // Eliminar usuario de la base de datos
        await remove(userRef);

        // Eliminar usuario de la autenticación
        const userAuth = auth.currentUser;
        await deleteUserAuth(userAuth);

        // Eliminar usuario de la lista de users
        this.users = this.users.filter((user) => user.id !== userId);

        // Mostrar un mensaje de éxito
        Swal.fire({
          title: "¡Usuario eliminado!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log("Usuario eliminado correctamente.");
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Hubo un problema al eliminar el usuario.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error al eliminar el usuario:", error);
      }
    },
    num_pages() {
      return Math.ceil(this.users.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>