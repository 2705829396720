<template>
     <Breadcrumbs main="Residentes" title="Actualizar"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <UpdateResident/>
              </div>
            </div>
     </div>
</template>
<script>
import UpdateResident from "@/components/residents/updateResident.vue"
export default {
    components:{
        UpdateResident
    }
}
</script>