import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vueform from '@vueform/vueform'
import vueformConfig from './../vueform.config.js'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/scss/app.scss"
import 'sweetalert2/dist/sweetalert2.min.css';

import Select2 from 'vue3-select2-component';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import VueFeather from "vue-feather";
import Breadcrumbs from './layout/breadCrumbs.vue';
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.css"
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';

import Toast from 'vue3-toastify'

createApp(App)
    .use(store)
    .use(router)
    .use(Vueform, vueformConfig)
    .use(PerfectScrollbarPlugin)
    .component('VueDatePicker', VueDatePicker)
    .component(VueFeather.name, VueFeather)
    .component('Breadcrumbs', Breadcrumbs)
    .component('multiselect', Multiselect)
    .component('Select2', Select2)
    .use(Toast)
    .mount('#app')
