<template>
     <Breadcrumbs main="Pruebas Aplicadas" title="Agregar Prueba Aplicada"/>
     <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                  <TestAppliedCreatePage/>
              </div>
            </div>
     </div>
</template>
<script>
import TestAppliedCreatePage from "@/components/resident/appliedTestCreate.vue"
export default {
    components:{
        TestAppliedCreatePage
    }
}
</script>