<template>
  <div class="background-overlay" v-if="showForm">
    <div class="floating-form">
      <div class="card">
        <button
          @click.prevent="closeForm"
          class="btn btn-danger btn-sm ms-auto btn-circle"
        >
          <i class="fa fa-times"></i>
        </button>
        <div class="card-header pb-5">
          <h4>Lista de actividades</h4>
        </div>
        <div class="new-task-wrapper input-group px-4" v-if="!editingActivity">
          <input
            class="form-control"
            v-model="formData.content"
            id="new-task"
            placeholder="Ingresa una nueva Actividad. . ."
            data-bs-original-title=""
            title=""
          />
          <span 
            class="btn btn-primary add-new-task-btn" 
            id="add-task"
            @click.prevent="validateAndSubmitForm"
          >Agregar</span
          >
        </div>
        <div class="new-task-wrapper input-group px-4" v-else>
          <input
            class="form-control"
            v-model="formData.content"
            id="new-task"
            placeholder="Ingresa una nueva Actividad. . ."
            data-bs-original-title=""
            title=""
          />
          <div class="col-sm-6 col-md-4">
              <div>
                <select
                  class="form-select digits"
                  v-model="formData.statusA"
                  required
                >
                    <option value="En proceso">En proceso</option>
                    <option value="Completada">Completada</option>
                </select>
              </div>
          </div>
          <span 
            class="btn btn-primary add-new-task-btn" 
            id="add-task"
            @click.prevent="validateUpdate"
          >Guardar</span
          >
        </div>
        <div class="card-header">
          <PerfectScrollbar class="list-group">
            <div v-for="(activity, index) in activities" :key="index">
              <div class="d-flex align-items-center justify-content-between">
                <a
                  class="list-group-item list-group-item-action flex-column align-items-start active"
                  href="javascript:void(0)"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1"></h5>
                    <h5>{{activity.time}}</h5>
                  </div>
                  <h5 class="mb-1">
                    {{activity.content}}
                  </h5>
                </a>
                <div class="d-flex align-items-center gap-3" style="margin-left: 20px;">
                  <span v-if="activity.statusA === 'En proceso'" class="badge badge-warning done-badge">
                    {{activity.statusA}}
                  </span>  
                  <span v-else class="badge badge-success done-badge">
                    {{activity.statusA}}
                  </span>
                  <h6 class="assign-name m-0">{{activity.date}}</h6>
                  <span class="task-action-btn d-flex align-items-center gap-2">
                    <span
                      class="action-box large delete-btn"
                      title="Opciones"
                    >
                      <i class="icon text-warning fs-4"  style="cursor: pointer;">
                        <i class="icon-pencil-alt" @click.prevent="editingActivityForm(activity)" ></i>
                      </i>  
                      <i class="icon text-danger fs-4"  style="cursor: pointer;"> 
                        <i class="icon-trash" @click.prevent="deleteConfirm(activity.idActivity)"></i>
                      </i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { database } from "@/firebase/config";
import { ref, set, push, onValue} from "firebase/database";
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import { toast } from 'vue3-toastify'
import Swal from "sweetalert2";

export default {
  props: {
    showForm: Boolean,
    selectedSchool: Object,
  },
  data() {
    return {
        activities: [],
        formData: {
          idActivity: "",      
          content: "",
          date: "",
          time: "",
          statusA: "En proceso",
        },
        editingActivity: false,
    };
  },
  methods: {
    closeForm() {
      this.$emit("close");
    },
    clean(){
        this.formData = {
            idActivitie: "",
            content: "",
            date: "",
            time: "",
            statusA: "En proceso",
        };
    },
    validateAndSubmitForm(){
        if (this.formData.content === "") {
            toast.error("Por favor, ingresa una actividad");
            return;
        }
        this.addActivity();
    },
    validateUpdate(){
        if (this.formData.content === "") {
            toast.error("Por favor, ingresa una actividad");
            return;
        }
        this.updateActivity();
    },
    addDateTime(){
        // Formatear la fecha en el formato dd/MM/yy y la hora en el horario de México
        const formattedDate = format(new Date(), 'dd/MM/yy', { locale: es });
        const formattedTime = format(new Date(), 'HH:mm:ss', { locale: es, timeZone: 'America/Mexico_City' });
        this.formData.date = formattedDate;
        this.formData.time = formattedTime;    
    },
    addActivity() {
      try {
        // Obtener el id del convenio desde selectedSchool
        const agreementId = this.selectedSchool.agreementId;

        // Crear una referencia al nodo activities dentro del nodo del convenio
        const activitiesRef = ref(database, `agreements/${agreementId}/activities`);

        // Generar una nueva clave única para la actividad
        const newActivityRef = push(activitiesRef);

        this.addDateTime();

        this.formData.idActivity = newActivityRef.key;

        // Establecer los datos de la nueva actividad
        set(newActivityRef, this.formData);

        // Mostrar un mensaje de éxito
        toast.success("Actividad creada exitosamente");

        // Limpiar el formulario
        this.clean();
      } catch (error) {
        console.error("Error al crear la actividad:", error);
        toast.error("Error al crear la actividad");
      }
    },
    loadActivities() {
        console.log("Cargando actividades...");
        const agreementId = this.selectedSchool.agreementId;
        const activitiesRef = ref(database, `agreements/${agreementId}/activities`);

        // Escuchar cambios en las actividades
        onValue(activitiesRef, (snapshot) => {
            console.log("Snapshot de actividades:", snapshot.val());
            const activitiesData = snapshot.val();
            if (activitiesData) {
            // Convertir las actividades en un arreglo
            const activitiesArray = Object.keys(activitiesData).map((key) => ({
                idActivity: key,
                ...activitiesData[key],
            }));
            // Actualizar el arreglo de actividades
            this.activities = activitiesArray;
            console.log("Actividades cargadas:", this.activities);
            } else {
            this.activities = []; // Si no hay actividades, vaciar el arreglo
            }
        });
    },
    deleteConfirm(id) {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
            // Si el usuario confirma la eliminación, procede con la eliminación
            this.deleteActivity(id);
            }
        });
    },
    deleteActivity(id){
       try {
            const agreementId = this.selectedSchool.agreementId;
            const activityRef = ref(database, `agreements/${agreementId}/activities/${id}`);

            // Elimina la actividad de la base de datos
            set(activityRef, null);

            // Muestra un mensaje de éxito
            toast.success("Actividad eliminada exitosamente");
        } catch (error) {
            console.error("Error al eliminar la actividad:", error);
            toast.error("Error al eliminar la actividad");
        }
    },
    editingActivityForm(activity){
        this.formData = activity;
        this.editingActivity = true;
    },
    updateActivity(){
        try {
            const agreementId = this.selectedSchool.agreementId;
            const activityId = this.formData.idActivity; // Obtener el ID de la actividad que se está editando
            const activityRef = ref(database, `agreements/${agreementId}/activities/${activityId}`);
            
            this.addDateTime();

            // Actualizar los datos de la actividad en la base de datos
            set(activityRef, this.formData);

            // Mostrar un mensaje de éxito
            toast.success("Actividad actualizada exitosamente");

            // Limpiar el formulario y salir del modo de edición
            this.clean();
            this.editingActivity = false;
        } catch (error) {
            console.error("Error al actualizar la actividad:", error);
            toast.error("Error al actualizar la actividad");
        }
    }
  },
  watch: {
    showForm(newValue) {
      if (newValue) {
        this.loadActivities();
      }
    }
  },
};
</script>

<style scoped>
.ps {
  height: 400px; /* or max-height: 400px; */
}
.swal2-container {
  z-index: 10000;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.floating-form {
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  z-index: 999;
}

.btn-circle {
  border-radius: 50%; /* Establece el radio para que el botón sea circular */
  width: 30px; /* Ajusta el ancho del botón según tus preferencias */
  height: 30px; /* Ajusta la altura del botón según tus preferencias */
  padding: 0; /* Elimina el relleno para que el botón tenga un tamaño fijo */
}

.btn-circle i {
  font-size: 14px; /* Ajusta el tamaño del icono según tus preferencias */
  line-height: 30px; /* Centra verticalmente el icono dentro del botón */
}
</style>