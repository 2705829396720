<template>
  <div  class="d-flex">
    <div class="col-xl-7 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos del documento</h4>
        </div>
        <div class="card-header">
          <div class="row" >
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Asunto</h6>
                <select
                  class="form-select digits"
                  v-model="datadocument.issue"
                  required
                  @input="actualizarVistaPrevia"
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="CARTA ACEPTACIÓN">Carta Aceptación</option>
                  <option value="CARTA LIBERACIÓN">Carta Liberación</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Fecha del documento</h6>
                <VueDatePicker 
                  v-model="dateD"
                  :enable-time-picker="false"  
                  :locale='locale'
                  :format="formatDateD"
                  cancel-text="Cancelar" 
                  select-text="Seleccionar"
                  placeholder="Dia/mes/año"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.lastContactDate">
                  Por favor, introduce la fecha de último contacto.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Nombre del destinatario</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="datadocument.receiver"
                  :class="{ 'is-invalid':formSubmitted && !this.name }"
                  required
                  placeholder="Nombre del destinatario">
                <div class="invalid-feedback" v-if="formSubmitted && !this.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Cargo que ocupa</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="datadocument.position"
                  :class="{ 'is-invalid':formSubmitted && !this.name }"
                  required
                  placeholder="Cargo que ocupa">
                <div class="invalid-feedback" v-if="formSubmitted && !this.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Nombre del practicante</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="datadocument.resident"
                  :class="{ 'is-invalid':formSubmitted && !this.name }"
                  required
                  placeholder="Nombre del Practicante">
                <div class="invalid-feedback" v-if="formSubmitted && !this.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Matrícula o No.Control</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="datadocument.enrollment"
                  :class="{ 'is-invalid':formSubmitted && !this.name }"
                  required
                  placeholder="Matrícula o No.Control">
                <div class="invalid-feedback" v-if="formSubmitted && !this.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Licenciatura</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="datadocument.degree"
                  :class="{ 'is-invalid':formSubmitted && !this.name }"
                  required
                  placeholder="Licenciatura">
                <div class="invalid-feedback" v-if="formSubmitted && !this.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Área asignada</h6>
                <select
                  class="form-select digits"
                  v-model="datadocument.department"
                  required
                >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Sistemas Computacionales">Sistemas Computacionales</option>
                  <option value="Diseño">Diseño</option>
                  <option value="Mercadotecnia">Mercadotecnia</option>
                  <option value="RRHH">RRHH</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Fecha de inicio</h6>
                <VueDatePicker 
                  v-model="dateS"
                  :enable-time-picker="false"  
                  :locale="locale"
                  :format="formatDateS"
                  cancel-text="Cancelar" 
                  select-text="Seleccionar"
                  placeholder="Dia/mes/año"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.lastContactDate">
                  Por favor, introduce la fecha de último contacto.
                </div>
              </div>
            </div>
            <div v-if="datadocument.issue === 'CARTA LIBERACIÓN'" class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Fecha de término</h6>
                <VueDatePicker 
                  v-model="dateE"
                  :enable-time-picker="false"  
                  :locale="locale"
                  :format="formatDateE"
                  cancel-text="Cancelar" 
                  select-text="Seleccionar"
                  placeholder="Dia/mes/año"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.lastContactDate">
                  Por favor, introduce la fecha de último contacto.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-0">
                <h6 class="form-label f-w-500" >Total de horas</h6>
                <input
                  class="form-control"
                  type="number"
                  v-model="datadocument.totalHours"
                  :class="{ 'is-invalid':formSubmitted && !this.name }"
                  required
                  placeholder="Total de horas">
                <div class="invalid-feedback" v-if="formSubmitted && !this.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500" >Responsable que firma</h6>
                <input
                  class="form-control"
                  type="text"
                  v-model="datadocument.liable"
                  :class="{ 'is-invalid':formSubmitted && !this.name }"
                  required
                  placeholder="Responsable que firma">
                <div class="invalid-feedback" v-if="formSubmitted && !this.name">
                  Por favor, introduce el nombre de la escuela.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer mb-0 mt-0">
            <div class="float-start">
              <button class="btn btn-secondary"
                  @click="$router.push('/residentes/documentos')"
              >Regresar</button>
            </div>
            <div class="float-end">
                        <button
            class="btn btn-primary"
            @click.prevent="generarPDF"
          >
            Generar documento
          </button>
            </div>
        </div>
      </form>
    </div>
        <!-- PDF en la columna derecha -->
  <div class="col-xl-5 col-lg-7">
    <iframe
      ref="pdfFrame"
      width="100%"
      height="95%"
      :src="pdfUrl"
      frameborder="0"
      scrolling="auto"
    ></iframe>
  </div>
  </div>
</template>

<script>
import { database, storage} from "@/firebase/config";
import { ref, update, onValue, push} from "firebase/database";
import { ref as refStorage,uploadBytes, getDownloadURL} from "firebase/storage";
import { format } from 'date-fns'
import { es } from 'date-fns/locale';
import {mapGetters} from 'vuex';
import jsPDF from 'jspdf';
import { toast } from "vue3-toastify";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            datadocument: {
                issue: '',
                dateDocument:'',
                receiver: '',
                position: '',
                resident: '',
                enrollment: '',
                degree: '',
                department: '',
                startDate: '',
                endDate: '',
                totalHours: '',
                liable: 'MARCO ANTONIO ASPEITIA PEÑA',
            },
            dataCompany: {
              email: "",
              phone: "",
              link: "",
              address: "",
              urlImage: "",
            },
            documents: [],
            idSchool: '',
            pdfUrl: '/template/Plantilla_Carta_Aceptacion.pdf',
            imgBase: '/template/base.jpg',
            pdfDoc: null,
            locale: es,
            formSubmitted: false,
            dateD: new Date(),
            dateS:'',
            dateE:'',
        };
    },
  methods: {
    formatDateD(date) {
        const formattedDate = format(date, 'dd \'de\' MMMM \'del\' yyyy', { locale: es });
        this.datadocument.dateDocument = formattedDate;
        return formattedDate;
    },
    formatDateS(date) {
        const formattedDate = format(date, 'dd \'de\' MMMM \'del\' yyyy', { locale: es });
        this.datadocument.startDate = formattedDate;
        return formattedDate;
    },
    formatDateE(date) {
        const formattedDate = format(date, 'dd \'de\' MMMM \'del\' yyyy', { locale: es });
        this.datadocument.endDate = formattedDate;
        return formattedDate;
    },
    async generarPDF() {
      try {
        // Crea un nuevo objeto jsPDF
        var doc = new jsPDF();
        // Agrega la imagen de fondo a la primera página del PDF
        doc.addImage(this.imgBase, 'JPEG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
        
         // Agrega la imagen recuperada al PDF
        const response = await fetch(this.dataCompany.urlImage);
        const blob = await response.blob();
        const imgData = URL.createObjectURL(blob);
        doc.addImage(imgData, 'PNG', 10, 10, 70, 19);
        
        // Texto que se va a ingresar 
        const topText = `Santa Fe, CDMX, a ${this.datadocument.dateDocument}`;
        const issueText = `${this.datadocument.issue}`;
        const receiverText = `${this.datadocument.receiver}`;
        const positionText = `${this.datadocument.position}`;
        const positionTextB = `P R E S E N T E.`;

        const acceptedtext = `Por este medio me permito comunicarle que el (a) C. ${this.datadocument.resident},con número de control ${this.datadocument.enrollment}, de la carrera de ${this.datadocument.degree},ha sido aceptado para realizar su residencia profesional en la empresa Developing Information Technologies Solutions México o por sus siglas DevITM en el área de ${this.datadocument.department}, a partir del ${this.datadocument.startDate}, cubriendo un total de ${this.datadocument.totalHours} horas efectivas.`;
        const releasedtext = `Por este medio me permito comunicarle que el (a) C. ${this.datadocument.resident},con número de control ${this.datadocument.enrollment}, de la carrera de ${this.datadocument.degree},ha concluido de manera satisfactoria su proceso de prácticas profesionales en la empresa Developing Information Technologies Solutions México o por sus siglas DevITM en el área de ${this.datadocument.department}, cubriendo un total de ${this.datadocument.totalHours} horas efectivas, con inicio a partir del ${this.datadocument.startDate} y finalización el ${this.datadocument.endDate}.`;
        
        const sender = `A T E N T A M E N T E.`;
        const nameSender = `${this.datadocument.liable}
            DIRECTOR GENERAL`;
        const contact = `${this.dataCompany.link}               ${this.dataCompany.email}               ${this.dataCompany.phone}`;
        const direction = `${this.dataCompany.address}`;

        const fontSize = 12;
        doc.setFontSize(fontSize);

        // Fecha del documento
        doc.text(topText, 110,45);
        // Asunto
        doc.setFont(undefined, 'bold').text(issueText, 142, 60)
        // Destinatario
        doc.text(receiverText, 20, 80);
        doc.text(positionText, 20, 85,{maxWidth: 100});
        doc.text(positionTextB, 20, 95);

        // Texto de aceptación o liberación
        if (this.datadocument.issue === 'CARTA ACEPTACIÓN') {
          doc.setFont(undefined,'normal').setLineHeightFactor(1.5).text(acceptedtext, 20, 110, { align: 'justify', maxWidth: doc.internal.pageSize.width - 40 });
        } else {
          doc.setFont(undefined,'normal').setLineHeightFactor(1.5).text(releasedtext, 20, 110, { align: 'justify', maxWidth: doc.internal.pageSize.width - 40 });
        }

        doc.setFont(undefined,'bold').text(sender, 90, 190);
        doc.line(70, 230, 150, 230);
        // Nombre del responsable
        doc.text(nameSender, 75, 235);

        // Contacto
        doc.setTextColor(255,255,255).text(contact, 50, 275);
        doc.setTextColor(255,255,255).text(direction, 55, 290);

        // Convierte el documento PDF a una URL de datos (data URL)
        var pdfUrl = doc.output('datauristring');

        // Actualiza el src del iframe para mostrar el PDF
        this.$refs.pdfFrame.src = pdfUrl;
        this.confirmSaveDocument();
      } catch (error) {
        console.error("Error al generar el PDF:", error);
      }
    },

    async confirmSaveDocument() {
      const result = await Swal.fire({
        title: '¿Deseas guardar el documento?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Guardar',
        denyButtonText: `Cancelar`,
      })

      if (result.isConfirmed) {
        this.saveDocument();
      }
    },

    async saveDocument() {
      try {
        const loadingToast = toast.loading("Subiendo archivo...");

        // 1. Obtener una referencia a la colección de documentos del residente
        const documentsRef = ref(database, `residents/${this.getSelectedResident.idResident}/documents`);

        // Convertir la data URL del PDF a un Blob
        const pdfDataUrl = this.$refs.pdfFrame.src;
        const pdfBlob = this.dataURLtoBlob(pdfDataUrl);

        // Crear una referencia para el documento en Firebase Realtime Database
        const documentRef = await push(ref(database, 'documents'));
        const documentId = documentRef.key;

        const storageRef = refStorage(storage, `residents/${this.getSelectedResident.idResident}/${documentId}/${this.datadocument.issue}`);
        await uploadBytes(storageRef, pdfBlob, { contentType: 'application/pdf' });
        const url = await getDownloadURL(storageRef);

        const formattedDate = format(new Date(), 'dd/MM/yy', { locale: es });
        const formattedTime = format(new Date(), 'HH:mm:ss', { locale: es, timeZone: 'America/Mexico_City' });

        // Guardar los detalles del documento en la base de datos
        const newDocument = {
          idDocument: documentId,
          nameDocument: this.datadocument.issue,
          date: formattedDate,
          time: formattedTime,
          url: url
        };

        // Actualizar la base de datos con el nuevo documento
        const updates = {};
        updates[documentId] = newDocument;
        await update(documentsRef, updates);

        // Ocultar el toast de carga
        toast.remove(loadingToast);
        // Mostrar un mensaje de éxito
        toast.success("Documento subido exitosamente");
      } catch (error) {
        console.error("Error al actualizar los datos:", error);
        toast.error("Error al subir el documento");
      }
    },

    dataURLtoBlob(dataURL) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    loadData() {
      const companyRef = ref(database, 'company');
      const schoolsRef = ref(database, `schools/${this.idSchool}`);

      // Escuchar cambios en los datos del residente
      onValue(companyRef, (snapshot) => {
        const companyData = snapshot.val();
        if (companyData) {
          this.dataCompany = companyData;
        } else {
          console.log("No se encontraron datos de la compañia.");
        }
      });

      onValue(schoolsRef, (snapshot) => {
        const schoolsData = snapshot.val();
        if (schoolsData) {
          this.datadocument.receiver = schoolsData.contactName || '';
        } else {
          console.log("No se encontraron datos de las escuelas.");
        }
      })
    },
  },
  mounted(){
    this.loadData()
  },
  computed:{
    ...mapGetters(['getSelectedResident'])
  },
  watch: {
    getSelectedResident: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.datadocument.resident = newValue.name || '';
          this.datadocument.department = newValue.department || '';
          this.datadocument.enrollment = newValue.enrollment || '';
          this.datadocument.degree = newValue.degree || '';
          // Convertir la fecha de inicio de cadena a objeto de fecha
          if (newValue.timetable.startDate) {
            const startDateParts = newValue.timetable.startDate.split('/');
            if (startDateParts.length === 3) {
              const startDate = new Date(startDateParts[2], startDateParts[1] - 1, startDateParts[0]);
              this.dateS = startDate;
              this.formatDateS(startDate);
            }
          }
          // Convertir la fecha de termino de cadena a objeto de fecha
          if (newValue.timetable.endDate) {
            const endDateParts = newValue.timetable.endDate.split('/');
            if (endDateParts.length === 3) {
              const endDate = new Date(endDateParts[2], endDateParts[1] - 1, endDateParts[0]);
              this.dateE = endDate;
              this.formatDateE(endDate);
            }
          }
          this.datadocument.totalHours = newValue.timetable.totalHours || '';
          this.idSchool = newValue.idSchool;
        }
      },
    },
  },

}
</script>