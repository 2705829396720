<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Información de las Escuelas</h4>
      </div>
      <div class="card-header">
            <div class="mb-3 row justify-content-start">
              <h6 for="search-type" class="col-xs-3 col-sm-auto col-form-label">Mostrar Escuelas:</h6>
              <div class="col-xs-4 col-sm-2">
                <select v-model="foreigner" id="search-type" class="form-select">
                  <option value="false">Nacionales</option>
                  <option value="true">Extranjeras</option>
                </select>
              </div>
            </div>
           <div class="mb-3 row justify-content-start">
              <h6 for="search-type" class="col-xs-3 col-sm-auto col-form-label">Tipo de Búsqueda:</h6>
              <div class="col-xs-4 col-sm-2">
                <select v-model="searchType" id="search-type" class="form-select">
                  <option value="name">Nombre</option>
                  <option v-if="foreigner === 'false'" value="state">Estado</option>
                  <option v-if="foreigner === 'true'" value="country">País</option>
                  <option value="typeSchool">Tipo de Escuela</option>
                </select>
              </div>
              <div class="col-auto ms-auto">
                <button class="btn btn-success mb-2" @click="redirectAdd">
                  <i class="fa fa-plus"></i> Agregar
                </button>
              </div>
            </div>

            <div v-if="searchType === 'state'" class="mb-3 row justify-content-start">
              <h6 for="user-search" class="col-xs-3 col-sm-auto col-form-label">Buscar:</h6>
              <div class="col-xs-3 col-md-3">
                <select v-model="filterQuery" id="user-search" class="form-select" >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option 
                    v-for="state in states" 
                    :value="state" 
                    :key="state">
                    {{ state }}
                  </option>
                </select>
              </div>
            </div>
            <div v-else-if="searchType === 'country'" class="mb-3 row justify-content-start">
              <h6 for="user-search" class="col-xs-3 col-sm-auto col-form-label">Buscar:</h6>
              <div class="col-xs-3 col-md-3">
                <select v-model="filterQuery" id="user-search" class="form-select" >
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option 
                    v-for="country in americaCountries" 
                    :value="country" 
                    :key="country">
                    {{ country }}
                  </option>
                </select>
              </div>
            </div>
            <div v-else-if="searchType === 'typeSchool'" class="mb-3 row justify-content-start">
              <h6 for="status-search" class="col-xs-3 col-sm-auto col-form-label">Buscar:</h6>
              <div class="col-xs-3 col-md-3">
                <select v-model="filterQuery" id="status-search" class="form-select">
                  <option value="" disabled selected>
                    Selecciona una opción
                  </option>
                  <option value="Pública">Pública</option>
                  <option value="Privada">Privada</option>
                </select>
              </div>
            </div>
            <div v-else class="mb-3 row justify-content-start">
              <h6
                for="table-complete-search"
                class="col-xs-3 col-sm-auto col-form-label"
                >Buscar:</h6
              >
              <div class="col-xs-3 col-sm-3">
                <input
                  id="table-complete-search"
                  type="text"
                  class="form-control"
                  placeholder="Escribe para buscar..."
                  v-model="filterQuery"
                />
              </div>
            </div>
        <div class="table-responsive theme-scrollbar data-table">
          <form>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col" sortable="name">Nombre de la escuela</th>
                  <th v-if ="foreigner==='false'" scope="col" sortable="state">Estado</th>
                  <th v-if ="foreigner==='true'" scope="col" sortable="state">País</th>
                  <th scope="col" sortable="typeSchool">Tipo de escuela</th>
                  <th scope="col" sortable="phone">Nombre de contacto</th>
                  <th scope="col" sortable="phone">Teléfono</th>
                  <th scope="col" sortable="email">Correo Electrónico</th>
                  <th scope="col" sortable="link">Enlace</th>
                  <th scope="col">Accciones</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="row in get_rows" :key="row.id">
                  <td>{{ row.name }}</td>
                  <td v-if="foreigner==='false'" >{{ row.state }}</td>
                  <td v-if="foreigner==='true'">{{ row.country }}</td>
                  <td>{{ row.typeSchool }}</td>
                  <td>{{ row.contactName }}</td>
                  <td>{{ row.phone }}</td>
                  <td>{{ row.email }}</td>
                  <td>{{ row.link }}</td>
                  <td>
                    <span>
                      <span class="cursor-pointer	" @click="redirectUpdate(row)">
                        <i
                          class="icofont icofont-edit icofont-2x font-warning ps-2"
                        ></i>
                      </span>
                      <span class="cursor-pointer	" v-if="userData.typeUser === 'Administrador'" @click="deleteSchoolConfirmation(row)">
                        <i
                          class="icofont icofont-ui-delete icofont-2x font-danger ps-2"
                        ></i>
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="pagination m-3 justify-content-end pagination-primary">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous"
                  ><span aria-hidden="true">&laquo;</span></a
                >
              </li>
              <li
                class="page-item"
                v-for="i in num_pages()"
                :key="i"
                v-bind:class="[i == currentPage ? 'active' : '']"
                v-on:click="change_page(i)"
              >
                <a class="page-link">{{ i }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next"
                  ><span aria-hidden="true">&raquo;</span></a
                >
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { database} from "@/firebase/config";
import { ref, onValue, remove} from "firebase/database";
import estados from "@/data/estados.js";
import paisesAmerica from "@/data/paisesAmerica.js";
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      schools: [],
      originalschools: [], 
      searchType: "name",
      elementsPerPage: 10,
      currentPage: 1,
      ascending: false,
      sortColumn: "",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterQuery: "",
      foreigner: "false",
      states: estados,
      americaCountries: paisesAmerica,

    };
  },
  watch: {
    foreigner() {
      this.loadData();
    },  
    searchType(newSearchType) {
      console.log("Nuevo tipo de búsqueda:", newSearchType); // Agregamos un mensaje de registro
      // Al cambiar el tipo de búsqueda, restablecer filterQuery a ''
      this.filterQuery = '';
    },
    filterQuery(search) {
        var tableItems = [...this.originalschools]; // Usar la lista original para filtrar
        var filteredData = tableItems.filter((row) => {
            const field = row[this.searchType].toLowerCase(); // Convertir a minúsculas
            const query = search.toLowerCase(); // Convertir a minúsculas

            // Verificar si el campo es 'state' y si la consulta coincide exactamente con el estado
            if(this.searchType === 'state') {
              const state = this.searchType === 'state' ? field === query : field.includes(query);
              return state;
            }else{
              const country = this.searchType === 'country' ? field === query : field.includes(query);
              return country;
            }


            
        });
        this.schools = search === "" ? [...this.originalschools] : filteredData;
    },
  },
  computed: {
    ...mapGetters(['userData']),
    columns() {
      if (this.schools.length === 0) {
        return [];
      }
      return Object.keys(this.schools[0]);
    },
    get_rows() {
      const start = (this.currentPage - 1) * this.elementsPerPage;
      const end = start + this.elementsPerPage;
      return this.schools.slice(start, end);
    },
  },
  methods: {
    redirectAdd() {
      this.$router.push("/escuelas/agregar");
    },
    redirectUpdate(school) {
      this.$store.dispatch("updateSelectedSchool", school);
      this.$router.push("/escuelas/actualizar");
    },
    loadData() {
      const schoolsRef = ref(database, "schools");
      onValue(schoolsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          if (this.foreigner === "false") {
            this.originalschools = Object.keys(data).map((schoolId) => ({
              id: schoolId,
              ...data[schoolId],
            })).filter((school) => school.state !== '');
          } else {
            this.originalschools = Object.keys(data).map((schoolId) => ({
              id: schoolId,
              ...data[schoolId],
            })).filter((school) => school.foreigner === true);
          }

          this.schools = [...this.originalschools]; 
        } else {
          this.schools = [];
          this.originalschools = [];
        }
      });
    },
    async deleteSchoolConfirmation(school) {
      // Mostrar el cuadro de diálogo de confirmación
      const result = await Swal.fire({
        title: "¿Está seguro?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario confirma la eliminación, proceder con la eliminación
      if (result.isConfirmed) {
        this.deleteSchool(school);
      }
    },
    async deleteSchool(school) {
      const schoolId = school.id;
      const schoolRef = ref(database, `schools/${schoolId}`);

      try {
        // Eliminar usuario de la base de datos
        await remove(schoolRef);

        // Eliminar usuario de la lista de users
        this.schools = this.schools.filter((school) => school.id !== schoolId);

        // Mostrar un mensaje de éxito
        Swal.fire({
          title: "¡Escuela eliminada!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log("Escuela eliminada correctamente.");
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Hubo un problema al eliminar la escuela.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error al eliminar la escuela:", error);
      }
    },
    num_pages() {
      return Math.ceil(this.schools.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>