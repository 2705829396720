<template>
  <div class="error-wrapper">
    <div class="container">
      <div class="svg-wrraper">
        <errorPage1 />
      </div>
      <div class="col-md-8 offset-md-2">
        <h3>Página no encontrada</h3>
        <p class="sub-content">
            La página a la que intenta acceder no está disponible actualmente. Esto
            puede ser porque la página no existe o ha sido movida.
        </p>
      </div>
      <router-link class="btn btn-primary" to="/inicio"> Regresar al Inicio </router-link>
    </div>
  </div>
</template>

<script>
    import errorPage1 from "@/svg/errorPage1.vue";
    export default {
    components: {
        errorPage1,
    },
    };
</script>