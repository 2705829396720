import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// Import the Firebase SDK

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD_zL0KrXZczeaDlz25YIfQ9d34SACM1Ec",
    authDomain: "ca-plataforma-residencias.firebaseapp.com",
    databaseURL: "https://ca-plataforma-residencias-default-rtdb.firebaseio.com",
    projectId: "ca-plataforma-residencias",
    storageBucket: "ca-plataforma-residencias.appspot.com",
    messagingSenderId: "529992975301",
    appId: "1:529992975301:web:728f95873222b77d0e41ac"
};  

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

// Export the authentication instance
export {auth, database, storage};