<template>
  <div class="d-flex justify-content-center">
    <div class="col-xl-7 col-lg-7">
      <form class="card" @submit.prevent="submitForm" novalidate>
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">Datos Generales</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Nombre del usuario</h6>
                <Select2
                  v-model="formData.idUser"
                  :options="users"
                  placeholder="Selecciona un usuario"
                  label="text"
                  track-by="id"
                  :class="{ 'is-invalid': formSubmitted && !formData.idUser }"
                  required
                  :noResult="'No se encontraron opciones disponibles'"
                />
                <div class="invalid-feedback" v-if="formSubmitted && !formData.idUser">
                  Por favor, introduce el nombre del usuario.
                </div>
              </div>
            </div>
            <div class="col-md-10">
              <div class="mb-3">
                <h6 class="form-label f-w-500">Documento</h6>
                <input
                  class="form-control"
                  id="document-upload"
                  type="file"
                  accept="application/pdf"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-start">
            <button class="btn btn-secondary"
                @click="$router.push('/bateria-pruebas/informes')"
            >Regresar</button>
          </div>
          <div class="float-end">
             <button
                class="btn btn-primary"
                type="submit"
                @click.prevent="validateAndSubmitForm"
            >
                Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { database, storage } from "@/firebase/config";
import { ref,update,get} from "firebase/database";
import { ref as refStorage, uploadBytes, getDownloadURL} from "firebase/storage";
import { format } from 'date-fns';
import {mapGetters} from 'vuex';
import es from 'date-fns/locale/es';
import Swal from "sweetalert2";
//import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      formData: {
        idReportTest: "",
        idUser: "",
        nameUser: "",
        department: "",
        document: {
          idDocument: "",
          name: "",
          url: "",
          date:"",
          time: "",
        },
      },
      users:[],
      formSubmitted: false,
    };
  },
  watch:{
    getSelectedReport: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
        console.log("Reporte seleccionado:", newValue);
          this.formData = {
            idReportTest: newValue.idReportTest,
            idUser: newValue.idUser,
            nameUser: newValue.nameUser,
            department: newValue.department,
            document: {
              idDocument: newValue.document.idDocument,
              name: newValue.document.name,
              url: newValue.document.url,
              date: newValue.document.date,
              time: newValue.document.time,
            },
          };
        }
      },
    },
  },
  computed: {
    ...mapGetters(['getSelectedReport']),
  },
  methods:{
    validateForm() {
      // Validando campos necesarios del formulario
        if (!this.formData.idUser) {
        return false;
      }
      return true; // Si todos los campos están llenos retorna true
    },

     validateAndSubmitForm() {
      // Marca el formulario como enviado
      this.formSubmitted = true;
      // Si el formulario es válido, lo envía
      if (this.validateForm()) {
        this.updateReportTest();
      }
    },
    addDateTime(){
        // Formatear la fecha en el formato dd/MM/yy y la hora en el horario de México
        const formattedDate = format(new Date(), 'dd/MM/yy', { locale: es });
        const formattedTime = format(new Date(), 'HH:mm:ss', { locale: es, timeZone: 'America/Mexico_City' });
        this.formData.document.date = formattedDate;
        this.formData.document.time = formattedTime;    
    },
    async updateReportTest() {
        try {
            // 1. Obtener una referencia a la colección 'reportTests'
            const reportTestRef = ref(database, `testBattery/reportTests/${this.formData.idReportTest}`);
            this.addDateTime();

            const selectedUser = this.users.find(user => user.id === this.formData.idUser);
            this.formData.id = selectedUser.id;
            this.formData.nameUser = selectedUser.text;
            this.formData.department = selectedUser.department;

            // Subir el archivo al almacenamiento
            const fileInput = document.getElementById('document-upload');
            const file = fileInput.files[0];
            if(file){
              console.log("Archivo seleccionado:", file);
              const storageRef = refStorage(storage, `reportTests/${this.formData.nameUser}/${file.name}`);
              console.log("Referencia de almacenamiento:", storageRef);
              const uploadTask = uploadBytes(storageRef, file);

              // Esperar a que se complete la carga del archivo
              const snapshot = await uploadTask;

              // Obtener la URL de descarga del archivo subido
              const url = await getDownloadURL(snapshot.ref);
              console.log("URL de descarga:", url);

              // Establecer la URL del archivo en los datos del documento
              this.formData.document.url = url;
              this.formData.document.name = file.name;
            }

            // Establecer los datos del documento en la base de datos
            // const documentRef = ref(database, `testBattery/${this.formData.idTest}/appliedTest/${this.formData.idAppliedTest}/document`);
            // const newDocumentRef = push(documentRef);
            await update(reportTestRef, this.formData);

            // Mostrar un mensaje de éxito
            await Swal.fire({
            icon: "success",
            title: "El reporte se ha actualizado correctamente",
            });
            console.log("Datos actualizados correctamente.");
            this.$router.push('/bateria-pruebas/informes')
        } catch (error) {
            console.error("Error al guardar los datos:", error);
            Swal.fire({
            icon: "error",
            title: "Error al actualizar el reporte",
            //text: error.message, // Muestra el mensaje de error retornado por Firebase
            });
        }
    },

    loadUsers(){
      // Consulta el nodo "users" en la base de datos
      const usersRef = ref(database, "users");
      console.log(usersRef);
      get(usersRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            // Itera sobre los datos de los usuarios
            snapshot.forEach((childSnapshot) => {
              const usersData = childSnapshot.val();
              const id = childSnapshot.key;
              const userName = usersData.name;
              // Agrega el nombre del usuario y su ID al array
              this.users.push({ 
                id: id.toString(), 
                text: userName, 
                department: usersData.department
              });
            });
            console.log(this.schools);
          } else {
            console.log("No existen datos de los usuarios");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos de Firebase: ", error);
        });
    },
  },

  mounted(){
    this.loadUsers();
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.img-logo {
  max-width: 100%;
  max-height: 100%;
}

.img-container {
  background-color: #eee; /* Color gris */
  border: 1px solid #ccc; /* Borde gris */
  padding: 10px; /* Espacio interior */
  text-align: center; /* Centrar contenido */
  border-radius: 5px;
  margin-bottom: 5px;
  height:  70px;
  margin-left: auto; /* Centrar horizontalmente */
  margin-right: auto; /* Centrar horizontalmente */
  overflow: hidden; /* Evita que la imagen se salga del contenedor */
}
</style>